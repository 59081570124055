/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { ChangeEvent, Component, ReactNode } from 'react';
import { uniqueId } from 'lodash';
import { FormControl, FormHelperText, InputAdornment, IconButton, Input, InputLabel } from '@material-ui/core';

import ErrorMessage from './ErrorMessage';
import { KeyedObject } from '../../types/general';
import { FormValidatorError, getErrorsForField, hasAnyErrors } from '../../utils/validations';

interface OwnProps {
    id?: string;
    name: string;
    errors: KeyedObject | null;
    label?: string;
    value?: string;
    startAdornment?: any;
    showStartButton?: Function;
    onStartAdornmentClick?: Function;
    startIcon?: any;
    icon?: any;
    iconToggled?: any;
    showButton?: boolean;
    required?: boolean;
    disabled?: boolean;
    blockAutoComplete?: boolean;
    onChange?: Function;
    placeholder?: string;
    testId?: string;
}

interface OwnState {
    readOnly: boolean;
    showPassword: boolean;
}

const initialState: OwnState = {
    readOnly: true,
    showPassword: false,
};

class FormPasswordField extends Component<OwnProps, OwnState> {
    state = initialState;

    onFocus = (): void => {
        this.setState({
            readOnly: false,
        });
    };

    onStartButtonClick = (): void => {
        const { onStartAdornmentClick } = this.props;
        if (onStartAdornmentClick) onStartAdornmentClick();
    };

    onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(event.currentTarget.name, event.currentTarget.value);
        }
    };

    handleClickShowPassword = (): void => {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    };

    renderErrors(fieldErrors: FormValidatorError[]): ReactNode {
        const { name } = this.props;

        return <ErrorMessage errors={fieldErrors} field={name} />;
    }

    renderStartAdornment(): ReactNode {
        const { startAdornment, showStartButton, startIcon } = this.props;

        if (showStartButton) {
            return (
                <InputAdornment position="start">
                    <IconButton onClick={this.onStartButtonClick}>{startIcon}</IconButton>
                </InputAdornment>
            );
        }

        if (startAdornment) {
            return <InputAdornment position="start">{startAdornment}</InputAdornment>;
        }

        return null;
    }

    renderEndAdornment(): ReactNode {
        const { icon, iconToggled, showButton } = this.props;
        const { showPassword } = this.state;

        if (showButton) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        data-testid="password-toggle"
                    >
                        {showPassword ? icon : iconToggled}
                    </IconButton>
                </InputAdornment>
            );
        }

        return icon;
    }

    render() {
        const {
            id,
            name,
            errors,
            label,
            value,
            required,
            blockAutoComplete,
            disabled,
            placeholder,
            testId,
        } = this.props;
        const { readOnly, showPassword } = this.state;

        const fieldId = id || uniqueId();
        const reallyReadOnly = blockAutoComplete ? readOnly : false;
        const fieldErrors = getErrorsForField(name, errors);
        const hasErrors = hasAnyErrors(fieldErrors);

        return (
            <FormControl
                fullWidth
                className="input-container"
                error={hasErrors}
                required={required}
                disabled={disabled}
            >
                {label && <InputLabel htmlFor={fieldId}>{label}</InputLabel>}
                <Input
                    readOnly={reallyReadOnly}
                    id={fieldId}
                    name={name}
                    placeholder={placeholder}
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    autoComplete={value === null ? '' : String(value)}
                    startAdornment={this.renderStartAdornment()}
                    endAdornment={this.renderEndAdornment()}
                    data-testid={testId}
                    disableUnderline
                />
                <FormHelperText>{this.renderErrors(fieldErrors)}</FormHelperText>
            </FormControl>
        );
    }
}

export default FormPasswordField;
