/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';

export const getDateFromDayAndTime = (day: string, time: string): Date => moment.utc(`${day}T${time}`).local().toDate();

export const calendarMultiDayRepresentation = (t: (key: string, params?: Record<string, unknown>) => string, days: moment.Moment[]): string => {
    if (days.length === 0) {
        return '';
    }

    if (days.length === 1) {
        return days[0].format('DD/MM/yyyy');
    }

    // this only works if calendar retrieves all days sorted
    if (days.length - 1 === Math.ceil(days[days.length - 1].diff(days[0], 'hours') / 24)) {
        return `${days[0].format('DD/MM/yyyy')} - ${days[days.length - 1].format('DD/MM/yyyy')} `;
    }

    return t('reservationModal.day.selected', { num: days.length });
};
