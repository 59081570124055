/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'reservations';

export const customerBookingsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/customers/${api}${params}`;
};

export const bookingsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const bookingURL = (bookingId: number): string => {
    return `${API_URL}/${api}/${bookingId}`;
};

export const checkinURL = (qrCodeId: string): string => {
    return `${API_URL}/${api}/${qrCodeId}/checkin`;
};

export const checkoutURL = (reservationId: string): string => {
    return `${API_URL}/${api}/${reservationId}/checkout`;
};

export const cancelURL = (reservationId: React.Key): string => {
    return `${API_URL}/${api}/${reservationId}/cancel`;
};
