/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { FAST_MP_API_URL, FAST_MP_WEB_URL } from '../settings';

export const fastMPServicesURL = (id: string): string => {
    return `${FAST_MP_API_URL}/stickers/${id}/allServices`;
};

export const fastMPWebRedirectURL = (id: string, storeId: string): string => {
    return `${FAST_MP_WEB_URL}/go?i=${id}&storeId=${storeId}`;
};
