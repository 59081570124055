/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import NoAccessIcon from '@material-ui/icons/Report';
import NoMatchIcon from '@material-ui/icons/NotListedLocation';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { withBookingsContext, BookingsContext } from '../controllers/BookingsContext';
import { displayError, displayNotification, NotificationType } from '../../utils/notifications';
import Transition from '../elements/Transition';
import { AppRoute } from '../../types/routes';
import { buildRoute, qrCodeIdFromPath } from '../../utils/misc';

interface OwnProps extends TranslationContext, RouteComponentProps, BookingsContext {}

interface OwnState {
    access: boolean;
    fetching: boolean;
    noMatch: boolean;
    qrCodeId: string;
}

const initialState: OwnState = {
    access: true,
    fetching: false,
    noMatch: false,
    qrCodeId: '',
};

class QrCodeScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    onDeclineAccess = () => {
        this.setState({ access: false });
    };

    handleScan = (data: string | null) => {
        const { history, submitCheckIn, t } = this.props;
        if (data) {
            this.setState({ fetching: true });
            let qrCodeId = '';
            try {
                qrCodeId = qrCodeIdFromPath((new URL(data)).pathname) || '';
            } catch (error) {
                this.setState({ fetching: false, noMatch: true });
                return;
            }
            submitCheckIn(qrCodeId).then(data => {
                if (data) {
                    history.push(buildRoute(AppRoute.BookingDetails, { bookingId: data.id }));
                    displayNotification({
                        message: t('qrCode.checkedIn'),
                        type: NotificationType.Success,
                    });
                } else {
                    this.setState({ fetching: false, noMatch: true, qrCodeId });
                }
            });
        }
    };

    reserve = async () => {
        const {
            getBusinessAndArea, setQrCodeForCheckIn, t, history,
        } = this.props;
        const { qrCodeId } = this.state;

        const res = await getBusinessAndArea(qrCodeId);
        if (!res) {
            displayError({ message: t('errors.wrong') });
            return;
        }
        
        setQrCodeForCheckIn({ ...res, qrCodeId });
        history.push(AppRoute.Map);
    }

    render() {
        const { access, fetching, noMatch } = this.state;
        const { t, history } = this.props;
        if (!access) {
            return (
                <div className="qrcode-screen">
                    <NoAccessIcon className="qrcode-screen__icon" />
                    <p>{t('qrCode.access')}</p>
                </div>
            );
        }

        return (
            <>
                <Dialog
                    open={noMatch}
                    onClose={() => this.setState({ noMatch: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="confirmation-dialog no-match-dialog"
                    TransitionComponent={Transition}
                >
                    <DialogContent>
                        <div className="confirmation-dialog__header">
                            <BackIcon onClick={() => this.setState({ noMatch: false })} data-testid="modal-close" />
                            {t('qrCode.noMatch')}
                        </div>
                        <div className="confirmation-dialog__content">
                            <NoMatchIcon className="qrcode-screen__icon" />
                            <span className="confirmation-dialog__title">{t('qrCode.notFound')}</span>
                            <span className="confirmation-dialog__text">{t('qrCode.notFoundDescription')}</span>
                            <Button
                                className="qrcode-screen__repeat-button"
                                variant="contained"
                                color="primary"
                                type="submit"
                                data-testid="submit-button"
                                onClick={() => this.setState({ noMatch: false })}
                            >
                                {t('qrCode.repeat')}
                            </Button>
                            <Button
                                className="qrcode-screen__reserve-button"
                                variant="contained"
                                color="primary"
                                type="submit"
                                data-testid="reserve-button"
                                onClick={() => this.reserve()}
                            >
                                {t('qrCode.reserve')}
                            </Button>
                            <Button
                                className="qrcode-screen__cancel-button"
                                variant="contained"
                                color="primary"
                                type="submit"
                                data-testid="cancel-button"
                                onClick={() => history.goBack()}
                            >
                                {t('qrCode.cancel')}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
                <div className="qrcode-screen">
                    <Backdrop open={fetching} style={{ zIndex: fetching ? 6 : -1 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <QrReader
                        delay={300}
                        onError={this.onDeclineAccess}
                        onScan={this.handleScan}
                        className="qrcode-screen__reader"
                    />
                </div>
            </>
        );
    }
}

export default withTranslationContext(withBookingsContext(withRouter(QrCodeScreen)));
