/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { GeoPoint } from '../types/general';
import boserIcon from '../assets/images/map_pin.svg';

interface MarkerLabel extends google.maps.MarkerLabel {
    className?: string;
}

export const customMarker = (
    mapRef: google.maps.Map,
    position: GeoPoint,
    icon?: string,
    labelString?: string,
    specialLabel?: string,
    clickable = false,
) => {
    const marker = new google.maps.Marker({
        position,
        map: mapRef,
        icon: icon || boserIcon,
        draggable: false,
        clickable,
        optimized: true,
    });

    if (specialLabel !== undefined && specialLabel !== '') {
        const label: MarkerLabel = {
            text: specialLabel,
            color: '#ffffff',
            fontSize: '13px',
            className: 'special-marker-label',
        };
        marker.setLabel(label);
    } else if (labelString !== undefined && labelString !== '') {
        const label: MarkerLabel = {
            text: labelString,
            color: '#ffffff',
            fontSize: '13px',
            className: 'custom-marker-label',
        };
        marker.setLabel(label);
    }

    return marker;
};

const defaultMapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    tilt: 0,
    rotateControl: false,
    gestureHandling: 'greedy',
};

export const googleMap = (center: GeoPoint, zoom: number, id: string) => {
    const map = new google.maps.Map(document.getElementById(id) as HTMLElement, {
        center,
        zoom,
        mapTypeId: google.maps.MapTypeId.HYBRID,
    });
    map.setOptions(defaultMapOptions);
    return map;
};

export const drawingManager = (ref: google.maps.Map) => {
    const manager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
            fillColor: '#ff4500',
            strokeColor: '#000000',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            draggable: true,
        },
    });
    manager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    manager.setMap(ref);
    return manager;
};
