/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import { AuthenticationContextProvider } from './AuthenticationContext';
import {
    LoginRequestPayload,
    ResetPassRequestPayload,
    ForgotPassRequestPayload,
} from '../../types/authentication';
import { AppState } from '../../reducers/types';
import {
    requestLogin,
    requestRegistration,
    requestForgotPass,
    requestResetPass,
    requestProfileChange,
    requestLogout,
} from '../../actions/authentication';
import { RegisterFormFields } from '../views/RegisterForm';
import { KeyedObject } from '../../types/general';
import { FormValidatorErrorType, validateForm } from '../../utils/validations';
import { LoginFormFields } from '../views/LoginForm';
import { RecoverFormFields } from '../views/RecoverForm';
import { ResetFormFields } from '../views/ResetForm';
import { ProfileModalFields } from '../views/ProfileModal';
import { ApiError } from '../../types/errors';
import { User } from '../../types/users';
import { validations } from '../../types/validations';
import { resendEmailURL, updateEmailURL } from '../../services/auth';
import { ProfileModalMode } from '../../types/profile';

interface StateProps {
    authenticatedUser: User | null;
    token: string | null;
    isAuthenticated: boolean;
    loginFetching: boolean;
    loginErrors: ApiError | null;
    registrationFetching: boolean;
    registrationErrors: ApiError | null;
    forgotPassFetching: boolean;
    forgotPassErrors: ApiError | null;
    resetPassFetching: boolean;
    resetPassErrors: ApiError | null;
    profileChangeFetching: boolean;
    profileChangeErrors: ApiError | null;
}

interface OwnProps {
    children: any;
}

type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;

export class AuthenticationController extends Component<Props> {
    // Login

    validateLogin = (fields: LoginFormFields): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.login);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitLogin = (payload: LoginRequestPayload, onSuccess: () => void, onFailure: () => void): void => {
        const { dispatchRequestLogin } = this.props;

        dispatchRequestLogin(payload, onSuccess, onFailure);
    };

    // Registration

    validateRegistration = (fields: RegisterFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, validations.registration);

        if (fields.password !== fields.repeatPassword) {
            if (errors === null) errors = {};
            if (errors.repeatPassword === null || errors.repeatPassword === undefined) {
                errors.repeatPassword = [
                    {
                        typeOfViolation: FormValidatorErrorType.PasswordsDontMatch,
                    },
                ];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitRegistration = (payload: FormData, onSuccess: () => void, onFailure: () => void): void => {
        const { dispatchRequestRegistration } = this.props;

        dispatchRequestRegistration(payload, onSuccess, onFailure);
    };

    // Request New Email

    requestNewEmail = async (email: string, onSuccess: () => void, onFailure: (errors: KeyedObject) => void): Promise<void> => {
        try {
            await axios.post(resendEmailURL(email));
            onSuccess();
        } catch (e) {
            const errors = axios.isAxiosError(e) ? e.response?.data : {};
            onFailure(errors);
        }
    };

    // Forgot Pass

    validateForgotPass = (fields: RecoverFormFields): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.recover);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitForgotPass = (
        payload: ForgotPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ): void => {
        const { dispatchRequestForgotPass } = this.props;

        dispatchRequestForgotPass(payload, onSuccess, onFailure);
    };

    submitEmailUpdate = async (newEmail: string, onSuccess: () => void, onFailure: (errors?: KeyedObject) => void): Promise<void> => {
        try {
            await axios.put(updateEmailURL(), { newEmail });
            onSuccess();
        } catch (e) {
            const data = axios.isAxiosError(e) ? e.response?.data : {};
            onFailure(data);
        }
    };

    // Reset Pass

    validateResetPass = (fields: ResetFormFields): KeyedObject | null => {
        let errors = validateForm(fields, validations.reset);

        if (fields.newPassword !== fields.repeatPassword) {
            if (errors === null) errors = {};
            if (errors.repeatPassword === null || errors.repeatPassword === undefined) {
                errors.repeatPassword = [
                    {
                        typeOfViolation: FormValidatorErrorType.PasswordsDontMatch,
                    },
                ];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitResetPass = (
        payload: ResetPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ): void => {
        const { dispatchRequestResetPass } = this.props;

        dispatchRequestResetPass(payload, onSuccess, onFailure);
    };

    // Profile change

    validateProfileChange = (fields: ProfileModalFields, mode: ProfileModalMode): KeyedObject | null => {
        let errors: KeyedObject | null;

        const hasPasswordChange = fields.oldPassword.length > 0
            || fields.newPassword.length > 0
            || fields.repeatPassword.length > 0
            || mode === ProfileModalMode.Password;

        errors = validateForm(fields, hasPasswordChange ? validations.profilePassword : validations.profile);

        if (hasPasswordChange && fields.newPassword !== fields.repeatPassword) {
            if (errors === null) errors = {};
            if (errors.repeatPassword === null || errors.repeatPassword === undefined) {
                errors.repeatPassword = [
                    {
                        typeOfViolation: FormValidatorErrorType.PasswordsDontMatch,
                    },
                ];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitProfileChange = (id: string, payload: FormData, onSuccess: () => void, onFailure: () => void): void => {
        const { dispatchRequestProfileChange } = this.props;

        dispatchRequestProfileChange(id, payload, onSuccess, onFailure);
    };

    // Activation

    validateActivateAccount = (token: string): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm({ token }, validations.activateAccount);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    // Logout

    submitLogout = (): void => {
        const { dispatchRequestLogout } = this.props;

        dispatchRequestLogout();
    };

    render() {
        const {
            children,
            isAuthenticated,
            loginFetching,
            loginErrors,
            registrationFetching,
            registrationErrors,
            forgotPassFetching,
            forgotPassErrors,
            resetPassFetching,
            resetPassErrors,
            profileChangeFetching,
            profileChangeErrors,
            authenticatedUser,
            token,
        } = this.props;

        return (
            <AuthenticationContextProvider
                value={{
                    authenticatedUser,
                    token,
                    isAuthenticated,
                    loginFetching,
                    loginErrors,
                    registrationFetching,
                    registrationErrors,
                    forgotPassFetching,
                    forgotPassErrors,
                    resetPassFetching,
                    resetPassErrors,
                    profileChangeFetching,
                    profileChangeErrors,
                    validateLogin: this.validateLogin,
                    validateRegistration: this.validateRegistration,
                    validateForgotPass: this.validateForgotPass,
                    validateResetPass: this.validateResetPass,
                    validateProfileChange: this.validateProfileChange,
                    submitLogin: this.submitLogin,
                    submitRegistration: this.submitRegistration,
                    requestNewEmail: this.requestNewEmail,
                    submitForgotPass: this.submitForgotPass,
                    submitResetPass: this.submitResetPass,
                    submitProfileChange: this.submitProfileChange,
                    validateActivateAccount: this.validateActivateAccount,
                    submitLogout: this.submitLogout,
                    submitEmailUpdate: this.submitEmailUpdate,
                }}
            >
                {children}
            </AuthenticationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        authenticatedUser: state.authentication.user,
        token: state.authentication.token,
        isAuthenticated: state.authentication.isAuthenticated,
        loginFetching: state.authentication.loginRequest.isFetching,
        loginErrors: state.authentication.loginRequest.errors,
        registrationFetching: state.authentication.registrationRequest.isFetching,
        registrationErrors: state.authentication.registrationRequest.errors,
        forgotPassFetching: state.authentication.forgotPassRequest.isFetching,
        forgotPassErrors: state.authentication.forgotPassRequest.errors,
        resetPassFetching: state.authentication.resetPassRequest.isFetching,
        resetPassErrors: state.authentication.resetPassRequest.errors,
        profileChangeFetching: state.authentication.profileChangeRequest.isFetching,
        profileChangeErrors: state.authentication.profileChangeRequest.errors,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    dispatchRequestLogin: (
        payload: LoginRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ) => dispatch(requestLogin(payload, onSuccess, onFailure)),
    dispatchRequestRegistration: (payload: FormData, onSuccess: () => void, onFailure: () => void) => dispatch(requestRegistration(payload, onSuccess, onFailure)),
    dispatchRequestForgotPass: (
        payload: ForgotPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ) => dispatch(requestForgotPass(payload, onSuccess, onFailure)),
    dispatchRequestResetPass: (
        payload: ResetPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ) => dispatch(requestResetPass(payload, onSuccess, onFailure)),
    dispatchRequestProfileChange: (
        id: string,
        payload: FormData,
        onSuccess: () => void,
        onFailure: () => void,
    ) => dispatch(requestProfileChange(id, payload, onSuccess, onFailure)),
    dispatchRequestLogout: () => dispatch(requestLogout()),
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
