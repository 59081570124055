/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import Hashids from 'hashids';

const SALT = 'fast-mp';
 
/**
  * Extract Sticker id and version from a signed sticker
  * @param {string} stickerId
  * @returns {string}
  */
export const encodeStickerId = (stickerId: string): string => {
    const hid = new Hashids(SALT);
    return hid.encodeHex(stickerId);
};

/**
 * Extract Sticker id and version from a sticker
 * @param {string} stickerId
 * @returns {string}
 */
export const decodeFastId = (stickerId: string): string => {
    const hid = new Hashids(SALT);
    return hid.decodeHex(stickerId);
};
