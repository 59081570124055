/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedPreferencesController } from './PreferencesController';
import { Language } from '../../types/preferences';

export interface PreferencesContext {
    language: Language;
    changeLanguage(language: Language): void;
}

export const preferencesContextDefaultValue = {
    language: Language.EN,
    changeLanguage: () => {},
};

const PreferencesContext = createContext<PreferencesContext | null>(preferencesContextDefaultValue);

export const PreferencesContextProvider = PreferencesContext.Provider;
export const PreferencesContextConsumer = PreferencesContext.Consumer;

export const withPreferencesContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof PreferencesContext>> => (props) => (
    <ConnectedPreferencesController>
        <PreferencesContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</PreferencesContextConsumer>
    </ConnectedPreferencesController>
);
