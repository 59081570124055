/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum AppRoute {
    Welcome = '/welcome',
    Landing = '/landing/:qrCodeId/:fastMPStickerId',
    Recover = '/recover',
    Map = '/map',
    Reserve = '/map/:businessId/:areaId',
    Reset = '/reset-password/:token',
    QrCode = '/qrcode',
    Bookings = '/bookings',
    BookingDetails = '/bookings/:bookingId',
    ConfirmAccount = '/confirm-account/:token',
    ConfirmEmail = '/confirm-email/:token',
    AcceptInvite = '/accept-invite/:token',
}
