/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { AppRoute } from '../../types/routes';

interface OwnProps extends RouteProps, AuthenticationContext {}

type Props = OwnProps;

const AuthenticatedRoute: React.FC<Props> = (props) => {
    if (!props.isAuthenticated) {
        const renderComponent = () => (
            <Redirect
                to={{
                    pathname: AppRoute.Welcome,
                    state: {
                        from: props.location,
                    },
                }}
            />
        );

        return <Route {...props} component={renderComponent} render={undefined} />;
    }

    return <Route {...props} />;
};

export default withAuthenticationContext(AuthenticatedRoute);
