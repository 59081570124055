/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';
import { ErrorCode } from '../types/errors';
import { displayNotification, NotificationType } from './notifications';

/**
 * @typedef {Object} FormValidator
 * @property {string[]} [validations]
 * @property {any} [regex]
 * @property {FormValidatorLength} [length]
 * @property {number} [min]
 * @property {number} [max]
 */
export interface FormValidator {
    validations?: string[];
    func?: Function;
    regex?: any;
    length?: FormValidatorLength;
    min?: number;
    max?: number;
    decimalPoints?: number;
}

/**
 * @typedef {Object} FormValidatorLength
 * @property {number} lowerLimit
 * @property {number} upperLimit
 */
export interface FormValidatorLength {
    lowerLimit: number;
    upperLimit: number;
}

/**
 * @typedef {Object} FormValidatorError
 * @property {number} errorCode
 */
export interface FormValidatorError {
    errorCode?: number;
    typeOfViolation?: string;
    size?: number;
    min?: number;
    max?: number;
    decimalPoints?: number;
}

/**
 * FormValidatorErrorType
 * @enum {string}
 */
export enum FormValidatorErrorType {
    NotBlank = 'NotBlank',
    NotEmpty = 'NotEmpty',
    SizeExact = 'SizeExact',
    Size = 'Size',
    Max = 'Max',
    Min = 'Min',
    Pattern = 'Pattern',
    Checked = 'Checked',
    PasswordsDontMatch = 'PasswordsDontMatch',
    NotNumber = 'NotNumber',
    DecimalPoints = 'DecimalPoints',
}

/**
 * ValidationType
 * @enum {string}
 */
export enum ValidationType {
    NotBlank = 'NOT_BLANK',
    NotEmpty = 'NOT_EMPTY',
    Length = 'LENGTH',
    Max = 'MAX',
    Min = 'MIN',
    Regex = 'REGEX',
    Checked = 'CHECKED',
    Function = 'FUNCTION',
    Number = 'NUMBER',
    Decimal = 'DECIMAL',
}

export const Regex = {
    Float: /^-?\d*\.?\d*$/,
    Decimal: /^-?[0-9]+[.]?[0-9]{0,2}$/,
    Email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    Coords: /^-?[0-9]+[.]?[0-9]{0,8}$/,
    PostalCode: /^[1-8]\d{3}-\d{3}$/,
    Swift: /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/,
};

const decimalRegex = (decimalPoints: number) => new RegExp(`^-?[0-9]+[.]?[0-9]{0,${decimalPoints}}$`);

/**
 * validates a field
 * @param {string} fieldName
 * @param {any} fieldValue
 * @param {FormValidator} validator
 */
export const validateField = (fieldName: string, fieldValue: any, validator: FormValidator) => {
    let errors: FormValidatorError[] | null = null;
    let isFilled = true;

    if (!validator) return errors;

    const {
        validations, length, regex, func, max, min, decimalPoints,
    } = validator;

    if (!validations) return errors;

    if (validations.includes(ValidationType.Checked)) {
        if (fieldValue === false) {
            errors = [{ typeOfViolation: FormValidatorErrorType.Checked }];
            isFilled = false;
        }
    } else if (validations.includes(ValidationType.NotBlank)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === '') {
            errors = [{ typeOfViolation: FormValidatorErrorType.NotBlank }];
            isFilled = false;
        }
    } else if (validations.includes(ValidationType.NotEmpty)) {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            errors = [{ typeOfViolation: FormValidatorErrorType.NotEmpty }];
            isFilled = false;
        }
    }

    if (isFilled && String(fieldValue).length > 0) {
        if (validations.includes(ValidationType.Length)) {
            if (length) {
                const { lowerLimit, upperLimit } = length;
                if (fieldValue) {
                    const parsedValue = String(fieldValue);
                    if (lowerLimit === upperLimit) {
                        const exactLimit = lowerLimit;
                        if (parsedValue.length !== exactLimit) {
                            errors = [
                                {
                                    typeOfViolation: FormValidatorErrorType.SizeExact,
                                    size: exactLimit,
                                },
                            ];
                        }
                    } else {
                        if (parsedValue.length < lowerLimit || parsedValue.length > upperLimit) {
                            errors = [
                                {
                                    typeOfViolation: FormValidatorErrorType.Size,
                                    min: lowerLimit,
                                    max: upperLimit,
                                },
                            ];
                        }
                    }
                }
            }
        }

        if (validations.includes(ValidationType.Max)) {
            if (max) {
                if (fieldValue && Number(fieldValue) > max) {
                    errors = [
                        {
                            typeOfViolation: FormValidatorErrorType.Max,
                            max,
                        },
                    ];
                }
            }
        }

        if (validations.includes(ValidationType.Min)) {
            if (min) {
                if (fieldValue && Number(fieldValue) < min) {
                    errors = [
                        {
                            typeOfViolation: FormValidatorErrorType.Min,
                            min,
                        },
                    ];
                }
            }
        }

        if (validations.includes(ValidationType.Regex)) {
            if (regex) {
                if (!regex.test(fieldValue)) {
                    errors = [{ typeOfViolation: FormValidatorErrorType.Pattern }];
                }
            }
        }

        if (validations.includes(ValidationType.Function)) {
            if (func) {
                if (!func(fieldValue)) {
                    errors = [{ typeOfViolation: FormValidatorErrorType.Pattern }];
                }
            }
        }

        if (validations.includes(ValidationType.Number)) {
            if (isNaN(fieldValue)) {
                errors = [{ typeOfViolation: FormValidatorErrorType.NotNumber }];
            }
        }

        if (validations.includes(ValidationType.Decimal)) {
            if (decimalPoints !== undefined) {
                if (!decimalRegex(decimalPoints).test(fieldValue)) {
                    errors = [{ typeOfViolation: FormValidatorErrorType.DecimalPoints, decimalPoints }];
                }
            }
        }
    }

    return errors;
};

/**
 * validates a form's fields
 * @param {KeyedObject} data
 * @param {KeyedObject} validations
 * @returns {KeyedObject | null}
 */
export const validateForm = (data: KeyedObject, validations: KeyedObject): KeyedObject | null => {
    let errors: KeyedObject | null = {};

    Object.keys(data).forEach(field => {
        const { [field]: fieldValue } = data;

        if (field in validations) {
            if (errors) {
                if (validations[field]) {
                    errors[field] = validateField(field, fieldValue, validations[field]);
                    if (!errors[field]) delete errors[field];
                }
            }
        }
    });

    if (Object.keys(errors).length === 0) errors = null;
    return errors;
};

/**
 * checks if any errors exist
 * @param {FormValidatorError[]} [errors]
 * @returns {boolean}
 */
export const hasAnyErrors = (errors: FormValidatorError[] | null | undefined): boolean => {
    return errors !== null && errors !== undefined && errors.length > 0;
};

/**
 * return the errors for a given field
 * @param {string} field
 * @param {any} errors
 * @returns {FormValidatorError[]}
 */
export const getErrorsForField = (field: string, errors: any): FormValidatorError[] => {
    if (errors !== null && errors !== undefined) {
        if (errors.fields !== null && errors.fields !== undefined) {
            if (Object.keys(errors.fields).length > 0) {
                if (hasAnyErrors(errors.fields[field])) {
                    return errors.fields[field];
                }
            }
        }
    }

    return [];
};

export const hasFieldErrors = (errors: KeyedObject | null): boolean => {
    return errors && errors.fields && Object.keys(errors.fields).length > 0;
};

export const hasGeneralErrors = (errors: KeyedObject | null): boolean => {
    return errors && errors.errors && Object.keys(errors.errors).length > 0;
};

export const handleFormSubmitFailure = (t: Function, createErrors: any, updateErrors?: any) => {
    const handleError = (error: any) => {
        displayNotification({
            message: t(`errors.${ErrorCode[error.errorCode]}`),
            type: NotificationType.Danger,
        });
    };

    if (createErrors && createErrors.fields) {
        return createErrors;
    } if (updateErrors && updateErrors.fields) {
        return updateErrors;
    } if (createErrors && createErrors.errors && createErrors.errors.length > 0) {
        createErrors.errors.forEach(handleError);
    } else if (updateErrors && updateErrors.errors && updateErrors.errors.length > 0) {
        updateErrors.errors.forEach(handleError);
    } else {
        displayNotification({
            message: t('errors.general'),
            type: NotificationType.Danger,
        });
    }
    return null;
};
