/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'invitations';

export const validateInviteTokenURL = (token: string): string => {
    return `${API_URL}/${api}/validate-token/${token}`;
};

export const acceptInviteURL = (token: string): string => {
    return `${API_URL}/${api}/accept-invite/${token}`;
};
