/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedBookingsController } from './BookingsController';
import { KeyedObject, ListResponse } from '../../types/general';
import { ApiError } from '../../types/errors';
import { Booking, BookingRequestPayload, DaySchedulesResponsePayload } from '../../types/bookings';
import { Business } from '../../types/businesses';
import { QRCodeBooking } from '../../reducers/types';
import { Bollard } from '../../types/areas';

export interface BookingsContext {
    qrCodeBooking: QRCodeBooking | null;
    bookingFetching: boolean;
    createBookingErrors: ApiError | null;
    getBooking(bookingId: number): Promise<Booking | null>;
    getBookings(filters?: KeyedObject): Promise<ListResponse<Booking> | null>;
    getSchedules(businessId: string, areaId: string, days: string[]): Promise<DaySchedulesResponsePayload[]>;
    validateNewBooking(fields: BookingRequestPayload): KeyedObject | null;
    submitNewBooking(payload: BookingRequestPayload, onSuccess: (newBookings: Booking[]) => void, onFailure: () => void): void;
    submitCheckIn(qrCodeId: string): Promise<Booking | null>;
    submitCheckOut(reservationId: string, onSuccess: () => void, onFailure: () => void): Promise<void>;
    getBusinessAndArea(qrCodeId: string): Promise<Partial<QRCodeBooking> | null>;
    getBollardByQrCodeId(qrCodeId: string): Promise<Bollard | null>;
    setQrCodeForCheckIn(payload: Partial<QRCodeBooking> | null): void;
    isBookingInPast(booking: Booking): boolean;
    cancelBookings(bookings: Booking[], onlyCancelIds?: number[]): Promise<Booking[]>;
    cancelBooking(booking: Booking): Promise<number>;
}

export const bookingsContextDefaultValue = {
    qrCodeBooking: null,
    bookingFetching: false,
    createBookingErrors: null,
    getBooking: async (): Promise<Booking | null> => null,
    getBookings: async (): Promise<null> => null,
    getSchedules: async (): Promise<DaySchedulesResponsePayload[]> => [],
    cancelBooking: async (): Promise<number> => -1,
    validateNewBooking: (): null => null,
    submitNewBooking: (): void => {},
    submitCheckIn: async (): Promise<null> => null,
    submitCheckOut: async (): Promise<void> => {},
    getBusinessAndArea: async (): Promise<{business: Business, areaId: string} | null> => null,
    getBollardByQrCodeId: async (): Promise<Bollard | null> => null,
    setQrCodeForCheckIn: (): void => {},
    isBookingInPast: (): boolean => false,
    cancelBookings: async (): Promise<Booking[]> => [],
};

const BookingsContext = createContext<BookingsContext | null>(bookingsContextDefaultValue);

export const BookingsContextProvider = BookingsContext.Provider;
export const BookingsContextConsumer = BookingsContext.Consumer;

export const withBookingsContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof BookingsContext>> => props => (
    <ConnectedBookingsController>
        <BookingsContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</BookingsContextConsumer>
    </ConnectedBookingsController>
    );
