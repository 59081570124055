/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessIcon from '@material-ui/icons/Work';
import SearchIcon from '@material-ui/icons/Search';
import { Business } from '../../types/businesses';
import { asyncThrottle } from '../../utils/misc';

const autocompleteService: { current: google.maps.places.AutocompleteService | null } = { current: null };
const geocoder: { current: google.maps.Geocoder | null } = { current: null };

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

interface Props {
    onBusinessSelected: Function;
    onPlaceSelected: Function;
    label: string;
    fetchExtraData: (input: string) => Promise<Business[]>;
}

interface Option {
    structured_formatting: {
        main_text: string;
        main_text_matched_substrings: any[];
        secondary_text: string;
    };
    city: string;
    business: boolean;
    description: string;
    lat: number;
    lng: number;
    place_id?: string;
}

export default function GoogleAutocomplete(props: Props): JSX.Element {
    const classes = useStyles();
    const [value, setValue] = React.useState<Option | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<Option[]>([]);

    const fetch = React.useMemo(
        () => asyncThrottle(async (request, callback) => {
            const newBusinesses = (await props.fetchExtraData(request.input)).map(business => ({
                structured_formatting: {
                    main_text: business.name,
                    main_text_matched_substrings: [],
                    secondary_text: `${business.numberOfAreas} Areas`,
                },
                city: business.address.city,
                business: true,
                description: business.name,
                lat: business.address.lat,
                lng: business.address.lng,
            }));

            if (autocompleteService.current) {
                autocompleteService.current.getPlacePredictions(request, result => {
                    if (result) {
                        callback([...result.splice(0, 2), ...newBusinesses]);
                    } else {
                        callback(newBusinesses);
                    }
                });
            }
        }, 200),
        [props],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (!geocoder.current && window.google) {
            geocoder.current = new window.google.maps.Geocoder();
        }
        if (!geocoder.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, types: ['(cities)'] }, (results: Option[]) => {
            if (active) {
                let newOptions: Option[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const dismissMobileKeyboard = () => {
        const field = document.createElement('input');
        field.setAttribute('type', 'text');
        document.body.appendChild(field);

        setTimeout(() => {
            field.focus();
            setTimeout(() => {
                field.setAttribute('style', 'display:none;');
            }, 50);
        }, 50);
    };

    const onPlaceSelected = (option: Option) => {
        dismissMobileKeyboard();
        if (option.business) {
            props.onBusinessSelected({ lat: option.lat, lng: option.lng });
            return;
        }
        if (geocoder.current) {
            geocoder.current.geocode({ placeId: option.place_id }, result => {
                if (result && result.length > 0) {
                    props.onPlaceSelected(result[0].geometry.bounds);
                }
            });
        }
    };

    return (
        <Autocomplete
            className="material-autocomplete"
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(_, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={props.label}
                    margin="normal"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={option => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map(match => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center" onClick={() => onPlaceSelected(option)}>
                        <Grid item>
                            {option.business ? (
                                <BusinessIcon className={classes.icon} />
                            ) : (
                                <LocationOnIcon className={classes.icon} />
                            )}
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
