/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconUserAvatar: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0Z" fill="none" />
            <path d="M4 22a8 8 0 1 1 16 0Zm8-9a6 6 0 1 1 6-6 6 6 0 0 1-6 6Z" />
        </svg>
    );
};

export default IconUserAvatar;
