/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const API_URL = process.env.REACT_APP_API_URL;
export const FAST_MP_API_URL = process.env.REACT_APP_FAST_MP_API_URL;
export const FAST_MP_WEB_URL = process.env.REACT_APP_FAST_MP_WEB_URL;
export const FAST_LEROY_STORE_ID = process.env.REACT_APP_FAST_LEROY_STORE_ID;
export const BOSER_BUSINESS_URL = process.env.REACT_APP_BOSER_BUSINESS_URL || '';
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_ANAlYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANAlYTICS_TRACKING_ID;
