/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface KeyedObject {
    [key: string]: any;
}

export interface Header {
    label: string;
    key: string;
}

export type SelectOption = {
    value: any;
    label: any;
};

export enum Status {
    Active = 'Ativo',
    Inactive = 'Desativo',
    Pending = 'Pendente',
}

export enum Order {
    Ascending = 'asc',
    Descending = 'desc',
}

export enum OrderQuery {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export interface QueryParams {
    _limit: number;
    _order?: OrderQuery;
    _page: number;
    _sort?: string;
}

export interface Country {
    code: string;
    countryName: string;
    phoneCode: string;
}

export interface GeoPoint {
    lat: number;
    lng: number;
}

export enum WeekDays {
    Sunday = 'SUNDAY',
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
}

export enum OverlayType {
    CIRCLE = 'circle',
    MARKER = 'marker',
    POLYGON = 'polygon',
    POLYLINE = 'polyline',
    RECTANGLE = 'rectangle',
}

export enum PaymentMethods {
    MB_WAY = 'MB_WAY',
    ATM_REFERENCE = 'ATM_REFERENCE',
    CREDIT_CARD = 'CREDIT_CARD',
    NONE = 'NONE'
}

export enum StepReservation {
    CHOOSE_LOCAL,
    CHOOSE_PAYMENT,
    ATM_REFERENCE_INFO,
    MBWAY_INFO,
    MBWAY_TIMER,
    CREDIT_CARD_INFO,
    SUCCESS,
    ERROR,
    LOGIN,
    AREA_DESCRIPTION,
}

export interface Time {
    hour: number;
    minutes: number;
}

export interface ListResponse<T> {
    data: T[];
    total: number;
}
