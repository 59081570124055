/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// Login

import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { KeyedObject } from '../types/general';
import { BookingsAction, BookingsActionTypes } from './bookings_types';
import { bookingsURL } from '../services/bookings';
import { Booking, BookingRequestPayload } from '../types/bookings';
import { QRCodeBooking } from '../reducers/types';

// Create Booking

export const bookingRequestActionCreator = (): BookingsActionTypes => {
    return {
        type: BookingsAction.BOOKING_REQUEST,
    };
};

export const bookingFinishedRequestActionCreator = (): BookingsActionTypes => {
    return {
        type: BookingsAction.BOOKING_REQUEST_FINISH,
    };
};

export const newBookingSuccessActionCreator = (): BookingsActionTypes => {
    return {
        type: BookingsAction.CREATE_BOOKING_SUCCESS,
    };
};

export const newBookingFailureActionCreator = (error: KeyedObject | null): BookingsActionTypes => {
    return {
        type: BookingsAction.CREATE_BOOKING_FAILURE,
        payload: error,
    };
};

export const requestCreateBooking = (payload: BookingRequestPayload, onSuccess: (newBookings: Booking[]) => void, onFailure: () => void) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(bookingRequestActionCreator());
        try {
            const { data } = await axios.post(bookingsURL(), payload);

            dispatch(newBookingSuccessActionCreator());

            onSuccess(data);
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(newBookingFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// set qrcode business for check-in process

export const setQRCodeBooking = (payload: Partial<QRCodeBooking> | null): BookingsActionTypes => {
    return {
        type: BookingsAction.SET_BUSINESS_BOOKING,
        payload,
    };
};
