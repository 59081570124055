/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { InView } from 'react-intersection-observer';
import { groupBy } from 'lodash';
import moment from 'moment';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { withBookingsContext, BookingsContext } from '../controllers/BookingsContext';
import BookingListEntry from '../elements/BookingListEntry';
import { Booking } from '../../types/bookings';
import BookingDetailsModal from '../views/BookingDetailsModal';
import { GroupReservationsModal } from '../elements/ModalGroupReservations';
import { calendarMultiDayRepresentation } from '../../utils/dates';
import { DATE_FORMAT } from '../../utils/constants';
import { displaySuccess } from '../../utils/notifications';
import { OrderQuery } from '../../types/general';

interface MatchParams {
    bookingId?: string;
}

interface BookingWithPastInfo {
    booking: Booking;
    past: boolean;
}

interface OwnProps extends BookingsContext, TranslationContext, RouteComponentProps<MatchParams> {}

interface OwnState {
    bookings: Record<string, BookingWithPastInfo[]>;
    tab: number;
    bookingId: number | undefined;
    currentPage: number;
    hasMoreBookings: boolean;
    multipleGroupedBookings: BookingWithPastInfo[];
    multipleReservationId: string | null,
}

const initialState: OwnState = {
    bookings: {},
    tab: 0,
    bookingId: undefined,
    currentPage: 0,
    hasMoreBookings: true,
    multipleGroupedBookings: [],
    multipleReservationId: null,
};

class BookingsScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly containerRef = React.createRef<HTMLDivElement>()

    componentDidMount() {
        const {
            match: {
                params: { bookingId },
            },
        } = this.props;
        this.setState({ bookingId: Number(bookingId) });
        this.fetchBookings();
    }

    onCancelBookings = async (booking: Booking) => {
        const { cancelBookings, t, isBookingInPast } = this.props;
        const { bookings } = this.state;

        const successfullyCancelledBookings = await cancelBookings(
            bookings[String(booking.multipleReservationId)].map(auxBooking => auxBooking.booking),
            booking.multipleReservationId ? undefined : [booking.id],
        );

        const newBookingsWithPastInfoState = { ...bookings };
        newBookingsWithPastInfoState[String(booking.multipleReservationId)] = successfullyCancelledBookings.map(auxBooking => ({
            past: isBookingInPast(auxBooking),
            booking: auxBooking,
        }));

        this.setState({
            bookings: newBookingsWithPastInfoState,
        }, () => displaySuccess({
            message: booking.multipleReservationId ? t('bookingModal.successfullyMultipleCancelled')
                : t('bookingModal.successfullyCancelled'),
        }));
    }

    onCancelBooking = async (booking: Booking) => {
        const { cancelBooking, t } = this.props;
        const { bookings, multipleReservationId, multipleGroupedBookings } = this.state;

        if (!multipleReservationId) return;

        const id = await cancelBooking(booking);

        if (id) {
            displaySuccess({ message: t('bookingModal.successfullyCancelled') });
            this.setState({
                bookings: {
                    ...bookings,
                    [multipleReservationId]: bookings[multipleReservationId]
                        .filter(b => b.booking.id !== id),
                },
                multipleGroupedBookings: multipleGroupedBookings.filter(b => b.booking.id !== id),
            });
        }
    }

    handleTabChange = (_: unknown, tab: number) => {
        this.setState({ tab }, () => this.containerRef.current?.scroll({ top: 0 }));
    };

    handleListRefresh = () => {
        this.setState({
            currentPage: initialState.currentPage,
            bookings: initialState.bookings,
            hasMoreBookings: initialState.hasMoreBookings,
        }, () => this.fetchBookings());
    }

    closeReservationGroup = () => {
        this.setState({
            multipleGroupedBookings: [],
            multipleReservationId: null,
        });
    }

    showBookingDetails = (multipleReservationId: string | null, booking: Booking) => {
        const { bookings, tab } = this.state;

        if (!multipleReservationId) {
            this.setState({ bookingId: booking.id }, this.closeReservationGroup);
            return;
        }

        const bookingFilterByTab = bookings[multipleReservationId].filter(bookingAux => bookingAux.past === Boolean(tab));

        if (bookingFilterByTab.length === 1) {
            this.setState({ bookingId: booking.id });
            return;
        }

        this.setState({
            multipleReservationId,
            multipleGroupedBookings: bookingFilterByTab,
        });
    };

    fetchBookings = () => {
        const { getBookings, isBookingInPast } = this.props;
        const { currentPage, bookings, hasMoreBookings } = this.state;
        if (!hasMoreBookings) return;

        const limitPerPage = 50;

        getBookings({
            _limit: limitPerPage,
            _page: currentPage,
            _sort: 'startDateTime',
            _order: OrderQuery.Descending,
        }).then(newBookings => {
            if (newBookings !== null) {
                const previousBookings = Object.values(bookings).flat();
                const newBookingsParsed = newBookings.data.map(booking => ({ booking, past: isBookingInPast(booking) }));

                this.setState({
                    bookings: groupBy([...previousBookings, ...newBookingsParsed], 'booking.multipleReservationId'),
                    currentPage: currentPage + 1,
                    hasMoreBookings: newBookings.data.length >= limitPerPage,
                });
            }
        });
    };

    getEntryBookings = (): Booking[] => {
        const { t } = this.props;
        const { bookings, tab } = this.state;

        const books: BookingWithPastInfo[] = [];

        Object.keys(bookings).forEach(key => {
            const bookingFilterByTab = bookings[key].filter(booking => booking.past === Boolean(tab));

            if (bookingFilterByTab.length !== 0) {
                if (key !== 'null') {
                    books.push({
                        ...bookingFilterByTab[0],
                        booking: {
                            ...bookingFilterByTab[0].booking,
                            price: bookingFilterByTab.reduce((acc, booking) => acc + booking.booking.price, 0),
                            day: calendarMultiDayRepresentation(t, bookingFilterByTab.map(booking => moment.utc(booking.booking.day, DATE_FORMAT))
                                .sort((moment1, moment2) => (moment1.isBefore(moment2) ? -1 : 1))),
                        },
                    });
                } else {
                    books.push(...(bookingFilterByTab.map(booking => (
                        {
                            ...booking,
                            booking: {
                                ...booking.booking,
                                day: moment(booking.booking.day, DATE_FORMAT).format('DD/MM/yyyy'),
                            },
                        }
                    ))));
                }
            }
        });

        if (tab === 0) {
            return books.map(booking => booking.booking)
                .sort((b1, b2) => moment(b1.startDateTime).diff(moment(b2.startDateTime)));
        }
        
        return books.map(booking => booking.booking);
    };

    render() {
        const { t, bookingFetching } = this.props;
        const {
            tab, bookingId, multipleGroupedBookings, multipleReservationId,
        } = this.state;

        const selectedSortedBookings = this.getEntryBookings();

        return (
            <div className="bookings-screen">
                <Backdrop className="loading" open={bookingFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label={t('bookingsScreen.next')} />
                    <Tab label={t('bookingsScreen.past')} />
                </Tabs>
                <div className="bookings-screen__list-container" ref={this.containerRef}>
                    {selectedSortedBookings.length > 0
                        ? selectedSortedBookings.map((booking, index) => (
                            <div
                                className="bookings-screen__list-container__entry-container clickable"
                                key={booking.id}
                                onClick={() => this.showBookingDetails(booking.multipleReservationId, booking)}
                            >
                                {
                                    index === (selectedSortedBookings.length - 3 < 0 ? 0 : selectedSortedBookings.length - 3) ? (
                                        <InView as="div" onChange={inView => (inView ? this.fetchBookings() : ({}))}>
                                            <BookingListEntry
                                                booking={booking}
                                                fetchBookings={this.handleListRefresh}
                                                squareIcons
                                                cancelBookingCallback={() => this.onCancelBookings(booking)}
                                            />
                                        </InView>
                                    ) : (
                                        <BookingListEntry
                                            booking={booking}
                                            fetchBookings={this.handleListRefresh}
                                            squareIcons
                                            cancelBookingCallback={() => this.onCancelBookings(booking)}
                                        />
                                    )
                                }
                            </div>
                        )) : (
                            <div
                                className="bookings-screen__list-container__entry-container
                                bookings-screen__list-container__entry-container--no-reservations"
                            >
                                <span className="bookings-screen__list-container__entry-container--no-reservations__title">
                                    {t('bookingsScreen.noReservations.title')}
                                </span>
                                <span className="bookings-screen__list-container__entry-container--no-reservations__message">
                                    {t('bookingsScreen.noReservations.message')}
                                </span>
                            </div>
                        )
                    }
                </div>
                <BookingDetailsModal
                    bookingId={bookingId}
                    close={() => this.setState({ bookingId: undefined })}
                />
                <GroupReservationsModal
                    open={!!multipleReservationId}
                    bookings={
                        multipleGroupedBookings.map(groupBookings => groupBookings.booking)
                            .sort((booking1, booking2) => (booking1.startDateTime < booking2.startDateTime ? -1 : 1))
                    }
                    fetchBookings={this.fetchBookings}
                    openBookingDetails={booking => this.showBookingDetails(null, booking)}
                    close={this.closeReservationGroup}
                    cancelBooking={this.onCancelBooking}
                />
            </div>
        );
    }
}

export default withBookingsContext(withTranslationContext(withRouter(BookingsScreen)));
