/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';

import { AuthenticationAction, AuthenticationActionTypes } from './authentication_types';
import {
    LoginRequestPayload,
    LoginResponsePayload,
    RegisterResponsePayload,
    ForgotPassRequestPayload,
    ResetPassRequestPayload,
    ProfileResponsePayload,
} from '../types/authentication';
import {
    loginURL, forgotPassURL, resetPassURL, logoutURL,
} from '../services/auth';
import { registerURL, editProfileURL } from '../services/customers';
import { KeyedObject } from '../types/general';

// Login

export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

export const loginSuccessActionCreator = (payload: LoginResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
        payload: error,
    };
};

export const requestLogin = (
    payload: LoginRequestPayload,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        try {
            const { data, headers } = await axios.post(loginURL(), payload);

            const authorizationToken = headers.authorization;

            dispatch(
                loginSuccessActionCreator({
                    user: data,
                    token: authorizationToken,
                }),
            );

            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(loginFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Registration

export const registrationRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_REQUEST,
    };
};

export const registrationSuccessActionCreator = (payload: RegisterResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_SUCCESS,
        payload,
    };
};

export const registrationFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_FAILURE,
        payload: error,
    };
};

export const requestRegistration = (payload: FormData, onSuccess: () => void, onFailure: () => void) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(registrationRequestActionCreator());
        try {
            await axios.post(registerURL(), payload);

            dispatch(registrationSuccessActionCreator({}));
            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(registrationFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Forgot Pass

export const forgotPassRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_REQUEST,
    };
};

export const forgotPassSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_SUCCESS,
    };
};

export const forgotPassFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_FAILURE,
        payload: error,
    };
};

export const requestForgotPass = (
    payload: ForgotPassRequestPayload,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(forgotPassRequestActionCreator());
        try {
            await axios.post(forgotPassURL(), payload);

            dispatch(forgotPassSuccessActionCreator());
            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(forgotPassFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Reset Pass

export const resetPassRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_REQUEST,
    };
};

export const resetPassSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_SUCCESS,
    };
};

export const resetPassFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_FAILURE,
        payload: error,
    };
};

export const requestResetPass = (
    payload: ResetPassRequestPayload,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resetPassRequestActionCreator());
        try {
            await axios.post(resetPassURL(), payload);

            dispatch(resetPassSuccessActionCreator());
            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(resetPassFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Profile

export const profileChangeRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_REQUEST,
    };
};

export const profileChangeSuccessActionCreator = (payload: ProfileResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_SUCCESS,
        payload,
    };
};

export const profileChangeFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_FAILURE,
        payload: error,
    };
};

export const requestProfileChange = (
    id: string,
    payload: FormData,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(profileChangeRequestActionCreator());
        try {
            const { data } = await axios.put(editProfileURL(id), payload);

            dispatch(profileChangeSuccessActionCreator(data));
            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (axios.isAxiosError(error)) {
                formErrors = error.response?.data || {};
            }
            dispatch(profileChangeFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Logout

export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

export const requestLogout = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch(logoutRequestActionCreator());
            await axios.post(logoutURL());
        } catch {}
    };
};
