/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'bollards';

export const qrCode = (qrCodeId: string): string => {
    return `${API_URL}/${api}/${qrCodeId}`;
};
