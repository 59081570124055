/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const IconEye: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path data-name="Rectangle 58" fill="none" d="M0 0h24v24H0z" />
            <g data-name="Vrstva 108" fill="#00000033" transform="translate(.855 5)">
                <path
                    data-name="Path 118"
                    d="M22.02 6.48v-.06l-.14-.21c-.13-.18-.31-.43-.54-.73a16.42 16.42 0 00-2.07-2.2A12.22 12.22 0 0011.17 0a12.22 12.22 0 00-8 3.25 16.42 16.42 0 00-2.07 2.2c-.23.3-.41.55-.54.73l-.14.21v.06L0 7l.32.54v.06l.14.21c.13.18.31.43.54.73a16.42 16.42 0 002.17 2.21 12.22 12.22 0 008 3.25 12.22 12.22 0 008.05-3.25 16.42 16.42 0 002.07-2.2c.23-.3.41-.55.54-.73l.14-.21v-.06l.32-.52zm-2.31.84a14.36 14.36 0 01-1.81 1.93A10.2 10.2 0 0111.17 12a10.2 10.2 0 01-6.73-2.75 15.23 15.23 0 01-1.82-1.93L2.38 7l.24-.32a15.23 15.23 0 011.82-1.93A10.2 10.2 0 0111.17 2a10.2 10.2 0 016.73 2.75 14.36 14.36 0 011.81 1.93c.1.12.18.22.25.32s-.15.2-.25.32z"
                />
                <circle data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(8.17 4)" />
            </g>
        </svg>
    );
};

export default IconEye;
