/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from './general';

export interface ApiError {
    fields?: any;
    errors?: any;
}

export const ErrorCode: KeyedObject = {
    0: 'wrong',
    1: 'forbidden',
    2: 'bad',
    3: 'notSortable',
    4: 'doesNotExist',
    5: 'notActive',
    6: 'emailInUse',
    7: 'invalidCredentials',
    8: 'inactive',
    9: 'password',
    10: 'token',
    11: 'fileNotfound',
    12: 'invalidImage',
    13: 'invalidPhone',
    14: 'emailAlreadySent',
    15: 'phoneInUse',
    16: 'alreadyActive',
    17: 'unsupportedFile',
    18: 'invalidPostal',
    19: 'badGateway',
    20: 'businessNotFound',
    22: 'unreachableBollards',
    23: 'beforeEndTime',
    24: 'periodsOverlap',
    25: 'areaNotFound',
    26: 'bollardNotFound',
    27: 'pointOutsidePolygon',
    28: 'intersectsEdges',
    29: 'intersectsBollards',
    30: 'bollardIntersetcsBollards',
    31: 'bollardIntersectsPolygon',
    32: 'bollardOutsidePolygon',
    33: 'periodNotFound',
    34: 'bollardAlreadyLocalized',
    35: 'userIdTaken',
    36: 'reservationNotFound',
    37: 'invalidDayofWeek',
    38: 'alreadyReserved',
    39: 'pastPeriod',
    40: 'bollardReserved',
    41: 'missingDay',
    42: 'dayOverride',
    43: 'businessReserved',
    49: 'missingMandatoryClientPhoneNumber',
    60: 'exceededEmailConfirmationAttempts',
    65: 'invalidInvitation',
};
