/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Button } from '@material-ui/core';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Transition from './Transition';

interface OwnProps extends TranslationContext {
    open: boolean;
    icon: string;
    title: string;
    text?: string;
    header?: string;
    onAccept: () => void;
    actionLabel: string;
    close: () => void;
}

const ConfirmationDialog: FC<OwnProps> = (props: OwnProps) => {
    const {
        t, close, open, icon, title, text, actionLabel, header, onAccept,
    } = props;
    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="confirmation-dialog"
            TransitionComponent={Transition}
            onClick={(evt: React.MouseEvent<HTMLElement>) => evt.stopPropagation()}
        >
            <DialogContent>
                <div className="confirmation-dialog__header">
                    <BackIcon onClick={close} data-testid="modal-close" />
                    {header ? t(header) : t('confirmation.title')}
                </div>
                <div className="confirmation-dialog__content">
                    <div className="confirmation-dialog__image-container">
                        <img src={icon} alt="icon" />
                    </div>
                    <span className="confirmation-dialog__title">{t(title)}</span>
                    <span className="confirmation-dialog__text">{text ? t(text) : <br />}</span>
                    <Button
                        className="confirmation-dialog__action-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={onAccept}
                        data-testid="confirmation-action-button"
                    >
                        {t(actionLabel)}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default withTranslationContext(ConfirmationDialog);
