/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import {
    Link, matchPath, RouteComponentProps, withRouter,
} from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { AppRoute } from '../../types/routes';
import MapIcon from '../../assets/images/map.svg';
import BookingsIcon from '../../assets/images/booking.svg';
import QrCodeIcon from '../../assets/images/qrcode.svg';

interface OwnProps extends AuthenticationContext, RouteComponentProps {}

interface OwnState {
    tab: number;
}

const initialState: OwnState = {
    tab: 0,
};

class NavBar extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { history } = props;
        const path = history.location.pathname;
        const tab = path === AppRoute.Bookings ? 1 : path === AppRoute.QrCode ? 2 : 0;
        this.state = {
            ...initialState,
            tab,
        };
    }

    componentDidUpdate() {
        const { tab } = this.state;
        const { history } = this.props;
        const path = history.location.pathname;
        const currentTab = path === AppRoute.Bookings ? 1 : path === AppRoute.QrCode ? 2 : 0;
        if (tab !== currentTab) {
            this.setState({
                tab: currentTab,
            });
        }
    }

    onTabChange = (stuff: any, tab: number): void => {
        this.setState({
            tab,
        });
    };

    renderTabIcon = React.forwardRef((props: any, ref: any) => {
        return (
            <Link {...props} ref={ref}>
                <img src={props.image} alt={props.label} />
                {props.children}
            </Link>
        );
    });

    render() {
        const { isAuthenticated, location } = this.props;
        const { tab } = this.state;

        return (
            <>
                {isAuthenticated && !matchPath(location.pathname, AppRoute.AcceptInvite) ? (
                    <>
                        <div className="bottom-bar">
                            <Tabs
                                value={tab}
                                onChange={this.onTabChange}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="navigation"
                            >
                                <Tab
                                    to={AppRoute.Map}
                                    image={MapIcon}
                                    component={this.renderTabIcon}
                                    className="tab-icon-container"
                                    data-testid="map-navigation"
                                />
                                <Tab
                                    to={AppRoute.Bookings}
                                    image={BookingsIcon}
                                    component={this.renderTabIcon}
                                    className="tab-icon-container"
                                    data-testid="bookings-navigation"
                                />{' '}
                                <Tab
                                    to={AppRoute.QrCode}
                                    image={QrCodeIcon}
                                    component={this.renderTabIcon}
                                    className="tab-icon-container"
                                    data-testid="qrcode-navigation"
                                />
                            </Tabs>
                        </div>
                    </>
                ) : (
                    <React.Fragment />
                )}
            </>
        );
    }
}

export default withAuthenticationContext(withRouter(NavBar));
