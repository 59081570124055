/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum ProfileModalMode {
    Info = 'INFO',
    Password = 'PASSWORD',
}
