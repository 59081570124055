/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const IconEyeClosed: FunctionComponent<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path data-name="Rectangle 35" fill="none" d="M0 0h24v24H0z" />
            <g data-name="Vrstva 109">
                <path
                    data-name="Path 83"
                    d="M22.385 11.127l-.84-1.45a3.92 3.92 0 00.26-.34l.13-.18v-.05l-.34-.22-.44-.34.47.31.34.22a1.004 1.004 0 00-1.68-1.1l-.1.13c-.09.13-.22.3-.4.52a13 13 0 01-1.63 1.63 9.53 9.53 0 01-6 2.27 9.53 9.53 0 01-6-2.27 13 13 0 01-1.64-1.59 3.874 3.874 0 01-.36-.52l-.1-.13a1.004 1.004 0 00-1.68 1.1l.78-.51-.78.51v.05l.13.18.1.14-1 1.65a1 1 0 101.73 1l.63-1.09c.26.25.54.51.86.77a13.3 13.3 0 001.51 1.06l-.68 1.25a1 1 0 101.73 1l.77-1.37a10.65 10.65 0 003 .72v1a1 1 0 002 0v-1a10.9 10.9 0 002.77-.64l.74 1.29a1 1 0 101.73-1l-.66-1.15a13.07 13.07 0 001.71-1.17c.25-.2.47-.41.69-.6l.53.92a1 1 0 101.73-1z"
                    fill="#00000033"
                />
            </g>
        </svg>
    );
};

export default IconEyeClosed;
