/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent, ReactNode } from 'react';
import {
    Backdrop, Button, CircularProgress, FormGroup, TextField,
} from '@material-ui/core';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconLock from '@material-ui/icons/LockOutlined';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import FormCheckbox from '../elements/FormCheckbox';
import FormSelectField from '../elements/FormSelectField';
import { KeyedObject, SelectOption, Country } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { countriesURL } from '../../services/general';
import { getErrorsForField, FormValidatorError, handleFormSubmitFailure } from '../../utils/validations';
import ErrorMessage from '../elements/ErrorMessage';
import IconEyeClosed from '../assets/IconEyeClosed';
import IconEye from '../assets/IconEye';

enum RegisterFormField {
    Email = 'email',
    FirstName = 'firstName',
    LastName = 'lastName',
    Password = 'password',
    RepeatPassword = 'repeatPassword',
    CountryCode = 'phoneCountry',
    PhoneNumber = 'phoneNumber',
    Terms = 'terms',
    Country = 'country',
    NIF = 'fiscalCode',
}

export interface RegisterFormFields {
    [RegisterFormField.Email]: string;
    [RegisterFormField.FirstName]: string;
    [RegisterFormField.LastName]: string;
    [RegisterFormField.Password]: string;
    [RegisterFormField.RepeatPassword]: string;
    [RegisterFormField.CountryCode]: string;
    [RegisterFormField.PhoneNumber]: string;
    [RegisterFormField.Country]: SelectOption | null | undefined;
    [RegisterFormField.NIF]: string;
    [RegisterFormField.Terms]: boolean;
}

interface OwnProps extends TranslationContext, AuthenticationContext {
    setEmail(email: string): void;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: RegisterFormFields;
    errors: KeyedObject | null;
    countries: SelectOption[];
    phoneCountries: SelectOption[];
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [RegisterFormField.Email]: '',
        [RegisterFormField.FirstName]: '',
        [RegisterFormField.LastName]: '',
        [RegisterFormField.Password]: '',
        [RegisterFormField.RepeatPassword]: '',
        [RegisterFormField.CountryCode]: 'PT',
        [RegisterFormField.PhoneNumber]: '',
        [RegisterFormField.Country]: null,
        [RegisterFormField.NIF]: '',
        [RegisterFormField.Terms]: false,
    },
    phoneCountries: [{ label: 'PT +351', value: 'PT' }],
    countries: [{ label: 'Portugal', value: 'PT' }],
    errors: null,
};

class RegisterForm extends Component<OwnProps, OwnState> {
    state = initialState;

    fetchCountries = () => {
        const { t } = this.props;
        const countries: SelectOption[] = [];
        const phoneCountries: SelectOption[] = [];
        axios.get(countriesURL()).then((countriesResponse: any) => {
            countriesResponse.data.forEach((country: Country) => {
                phoneCountries.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
                countries.push({
                    label: t(`countries.${country.code}`),
                    value: country.code,
                });
            });
            countries.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ countries, phoneCountries });
        });
    };

    componentDidMount() {
        this.fetchCountries();
    }

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onCheckChange = (name: string, checked: boolean): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: checked,
            },
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { submitRegistration, validateRegistration } = this.props;
        const { fields } = this.state;

        const errors: KeyedObject | null = validateRegistration(fields);

        const phoneNumber = fields[RegisterFormField.PhoneNumber].trim();

        const convertedFields = {
            ...fields,
            [RegisterFormField.PhoneNumber]: phoneNumber || null,
            [RegisterFormField.Country]: fields[RegisterFormField.Country]?.value,
            [RegisterFormField.NIF]: String(fields[RegisterFormField.NIF]).trim() ? fields[RegisterFormField.NIF] : null,
        };

        const formData = new FormData();

        formData.append(
            'customer',
            new Blob([JSON.stringify(convertedFields)], {
                type: 'application/json',
            }),
        );

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    submitRegistration(formData, this.onFormSubmitSuccess, this.onFormSubmitFailure);
                }
            },
        );
    };

    onFormSubmitSuccess = () => {
        const { setEmail, t } = this.props;
        const { fields } = this.state;

        displayNotification({
            message: t('registerForm.success'),
            type: NotificationType.Success,
        });

        setEmail(fields[RegisterFormField.Email]);

        const container = document.getElementById('scrollable-container');
        if (container && container.scrollTo) {
            container.scrollTo(0, container.scrollHeight);
        }
    };

    onFormSubmitFailure = () => {
        const { t, registrationErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, registrationErrors) });
    };

    onAutocompleteChange = (event: any, newValue: SelectOption | null) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [RegisterFormField.Country]: newValue,
            },
        });
    };

    shouldAutocomplete = (evt: any) => {
        const { countries } = this.state;
        const found = countries.find(country => country.label === evt.target.value);
        if (found !== undefined) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [RegisterFormField.Country]: found,
                },
            });
        }
    };

    renderErrors(name: string, fieldErrors: FormValidatorError[]): ReactNode {
        return <ErrorMessage errors={fieldErrors} field={name} />;
    }

    render() {
        const { t, registrationFetching } = this.props;
        const {
            fields, errors, countries, phoneCountries,
        } = this.state;

        return (
            <form onSubmit={this.onFormSubmit} className="wide-form">
                <Backdrop open={registrationFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="wide-form__grid-container">
                    <FormTextField
                        name={RegisterFormField.FirstName}
                        value={fields[RegisterFormField.FirstName]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.firstNameLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <FormTextField
                        name={RegisterFormField.LastName}
                        value={fields[RegisterFormField.LastName]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.lastNameLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <div className="wide-form__grid-container__double-space-mobile">
                        <FormTextField
                            name={RegisterFormField.Email}
                            value={fields[RegisterFormField.Email]}
                            onChange={this.onInputChange}
                            placeholder={t('registerForm.emailLabel')}
                            errors={errors}
                            disabled={registrationFetching}
                            testId="register-email"
                        />
                    </div>
                    <div className="wide-form__grid-container__double-input wide-form__grid-container__double-space-mobile">
                        <FormSelectField
                            name={RegisterFormField.CountryCode}
                            errors={errors}
                            disabled={registrationFetching}
                            onChange={this.onInputChange}
                            options={phoneCountries}
                            value={fields[RegisterFormField.CountryCode]}
                        />
                        <div className="wide-form__grid-container__double-input__divider">
                            <div />
                        </div>
                        <FormTextField
                            name={RegisterFormField.PhoneNumber}
                            value={fields[RegisterFormField.PhoneNumber]}
                            onChange={this.onInputChange}
                            placeholder={t('registerForm.phoneLabel')}
                            errors={errors}
                            disabled={registrationFetching}
                        />
                    </div>
                    <div className="wide-form__grid-container__double-space-mobile">
                        <Autocomplete
                            className="material-autocomplete"
                            options={countries}
                            getOptionLabel={(option: SelectOption) => option.label}
                            value={fields[RegisterFormField.Country]}
                            onChange={this.onAutocompleteChange}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    placeholder={t('registerForm.countryLabel')}
                                    error={getErrorsForField(RegisterFormField.Country, errors).length > 0}
                                    helperText={this.renderErrors(
                                        RegisterFormField.Country,
                                        getErrorsForField(RegisterFormField.Country, errors),
                                    )}
                                    onChange={this.shouldAutocomplete}
                                    margin="normal"
                                />
                            )}
                            disabled={registrationFetching}
                        />
                    </div>
                    <div className="wide-form__grid-container__double-space-mobile">
                        <FormTextField
                            name={RegisterFormField.NIF}
                            value={fields[RegisterFormField.NIF]}
                            onChange={this.onInputChange}
                            placeholder={t('registerForm.nifLabel')}
                            errors={errors}
                            disabled={registrationFetching}
                        />
                    </div>
                    <FormPasswordField
                        name={RegisterFormField.Password}
                        value={fields[RegisterFormField.Password]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.passwordLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                        showButton
                        startAdornment={<IconLock />}
                        icon={<IconEye />}
                        iconToggled={<IconEyeClosed />}
                        testId="register-password"
                    />
                    <FormPasswordField
                        name={RegisterFormField.RepeatPassword}
                        value={fields[RegisterFormField.RepeatPassword]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.repeatPasswordLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                        showButton
                        startAdornment={<IconLock />}
                        icon={<IconEye />}
                        iconToggled={<IconEyeClosed />}
                    />
                </div>
                <FormGroup row className="register-bottom">
                    <Button
                        className="white-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={registrationFetching}
                    >
                        {t('registerForm.submitButton')}
                    </Button>
                </FormGroup>
                <div className="wide-form__grid-container__terms-container">
                    <FormCheckbox
                        name={RegisterFormField.Terms}
                        checked={fields[RegisterFormField.Terms]}
                        onChange={this.onCheckChange}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <div>
                        <span>{t('registerForm.read')}</span>
                        <a href="https://boser.pt/?modal=terms" className="wide-form__grid-container__terms-container__terms-link">
                            &nbsp;{t('registerForm.terms')}&nbsp;
                        </a>
                        <span>{t('registerForm.and')}</span>
                        <a href="https://boser.pt/?modal=privacy" className="wide-form__grid-container__terms-container__terms-link">
                            &nbsp;{t('registerForm.policy')}
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(RegisterForm));
