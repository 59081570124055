/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { BookingsState } from './types';
import { GeneralAction } from '../actions/general_types';
import { BookingsAction } from '../actions/bookings_types';

export const bookingsInitialState: BookingsState = {
    createBookingRequest: {
        isFetching: false,
        errors: null,
    },
    qrCodeBooking: null,
};

export default function (state = bookingsInitialState, action: any): BookingsState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.bookings) {
                return {
                    ...action.payload.bookings,
                    createBookingRequest: bookingsInitialState.createBookingRequest,
                };
            }
            return state;
        case BookingsAction.BOOKING_REQUEST:
            return {
                ...state,
                createBookingRequest: {
                    ...state.createBookingRequest,
                    isFetching: true,
                },
            };
        case BookingsAction.BOOKING_REQUEST_FINISH:
            return {
                ...state,
                createBookingRequest: {
                    ...state.createBookingRequest,
                    isFetching: false,
                },
            };
        case BookingsAction.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                createBookingRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case BookingsAction.CREATE_BOOKING_FAILURE:
            return {
                ...state,
                createBookingRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case BookingsAction.SET_BUSINESS_BOOKING:
            return {
                ...state,
                qrCodeBooking: action.payload ? { ...action.payload } : null,
            };
        case GeneralAction.STORE_RESET:
            return bookingsInitialState;
        default:
            return state;
    }
}
