/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController } from './AuthenticationController';
import { KeyedObject } from '../../types/general';
import { LoginFormFields } from '../views/LoginForm';
import { RegisterFormFields } from '../views/RegisterForm';
import { LoginRequestPayload, ForgotPassRequestPayload, ResetPassRequestPayload } from '../../types/authentication';
import { RecoverFormFields } from '../views/RecoverForm';
import { ResetFormFields } from '../views/ResetForm';
import { ProfileModalFields } from '../views/ProfileModal';
import { ApiError } from '../../types/errors';
import { User } from '../../types/users';
import { ProfileModalMode } from '../../types/profile';

export interface AuthenticationContext {
    authenticatedUser: User | null;
    token: string | null;
    isAuthenticated: boolean;
    loginFetching: boolean;
    loginErrors: ApiError | null;
    registrationFetching: boolean;
    registrationErrors: ApiError | null;
    forgotPassFetching: boolean;
    forgotPassErrors: ApiError | null;
    resetPassFetching: boolean;
    resetPassErrors: ApiError | null;
    profileChangeFetching: boolean;
    profileChangeErrors: ApiError | null;
    validateLogin(fields: LoginFormFields): KeyedObject | null;
    validateRegistration(fields: RegisterFormFields): KeyedObject | null;
    validateForgotPass(fields: RecoverFormFields): KeyedObject | null;
    validateResetPass(fields: ResetFormFields): KeyedObject | null;
    validateProfileChange(fields: ProfileModalFields, mode: ProfileModalMode): KeyedObject | null;
    submitLogin(payload: LoginRequestPayload, onSuccess: () => void, onFailure: () => void): void;
    submitRegistration(payload: FormData, onSuccess: () => void, onFailure: () => void): void;
    requestNewEmail(email: string, onSuccess: () => void, onFailure: (responseErrors: KeyedObject) => void): Promise<void>;
    submitForgotPass(
        payload: ForgotPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ): void;
    submitResetPass(
        payload: ResetPassRequestPayload,
        onSuccess: () => void,
        onFailure: () => void,
    ): void;
    submitProfileChange(id: string, payload: FormData, onSuccess: () => void, onFailure: () => void): void;
    validateActivateAccount(token: string): KeyedObject | null;
    submitLogout(): void;
    submitEmailUpdate(newEmail: string, onSuccess: () => void, onFailure: (errors?: KeyedObject) => void): Promise<void>;
}

export const authenticationContextDefaultValue = {
    authenticatedUser: null,
    token: null,
    isAuthenticated: false,
    loginFetching: false,
    loginErrors: null,
    registrationFetching: false,
    registrationErrors: null,
    forgotPassFetching: false,
    forgotPassErrors: null,
    resetPassFetching: false,
    resetPassErrors: null,
    profileChangeFetching: false,
    profileChangeErrors: null,
    validateLogin: (): null => null,
    validateRegistration: (): null => null,
    validateForgotPass: (): null => null,
    validateResetPass: (): null => null,
    validateProfileChange: (): null => null,
    submitLogin: (): void => {},
    submitRegistration: (): void => {},
    requestNewEmail: async (): Promise<void> => {},
    submitForgotPass: (): void => {},
    submitResetPass: (): void => {},
    submitProfileChange: (): void => {},
    validateActivateAccount: (): null => null,
    submitLogout: (): void => {},
    submitEmailUpdate: async (): Promise<void> => {},
};

const AuthenticationContext = createContext<AuthenticationContext | null>(authenticationContextDefaultValue);

export const AuthenticationContextProvider = AuthenticationContext.Provider;
export const AuthenticationContextConsumer = AuthenticationContext.Consumer;

export const withAuthenticationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthenticationContext>> => props => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {ctx => <Component {...(props as P)} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
    );
