/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum Language {
    EN = 'en',
    PT = 'pt',
}
