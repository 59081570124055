/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import moment from 'moment';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { withBookingsContext, BookingsContext } from '../controllers/BookingsContext';
import noBooking from '../../assets/images/no-booking.svg';
import BookingListEntry from '../elements/BookingListEntry';
import { Booking, BookingStatus } from '../../types/bookings';
import { getDateFromDayAndTime } from '../../utils/dates';
import BookingDetailsModal from './BookingDetailsModal';
import { OrderQuery } from '../../types/general';

interface OwnProps extends TranslationContext, BookingsContext {
    refresh: boolean;
}

interface OwnState {
    nextBooking: Booking | null;
    loading: boolean;
    bookingId: number | undefined;
}

const initialState: OwnState = {
    nextBooking: null,
    loading: true,
    bookingId: undefined,
};

class BookingModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        this.fetchBookings();
    }

    handleCancelBooking = async (booking: Booking) => {
        const { cancelBooking } = this.props;

        const id = await cancelBooking(booking);
        if (id > 0) this.fetchBookings();
    }

    canShowDetails(booking: Booking) {
        return booking.status === BookingStatus.CheckedIn || booking.status === BookingStatus.ATMReferenceCreated || booking.status === BookingStatus.CreditCardPaymentCreated || booking.status === BookingStatus.MBWayRequestCreated;
    }

    showBookingDetails = (booking: Booking) => {
        if (this.canShowDetails(booking)) {
            this.setState({ bookingId: booking.id });
        }
    };

    fetchBookings = async (page = 0, auxBookings: Booking[] = []): Promise<void> => {
        const { getBookings, isBookingInPast } = this.props;
        const limitPerPage = 5;

        const res = await getBookings({
            _limit: limitPerPage, _sort: 'startDateTime', _page: page, _order: OrderQuery.Descending,
        });

        if (!res) return;
        const { data: bookings } = res;

        const passedBookings = bookings.filter((booking: Booking) => moment.utc().isAfter(
            getDateFromDayAndTime(booking.day, booking.startTime), 'day', // moment is not timezone aware on isAfter,isSame,isBefore methods
        ));

        const todayBookings = bookings.filter(
            booking => !isBookingInPast(booking) && moment().isSame(getDateFromDayAndTime(booking.day, booking.startTime), 'date'),
        );

        const allTodaysBookings = [...todayBookings, ...auxBookings];

        // check if already passed todays day
        if (passedBookings.length < limitPerPage && bookings.length === limitPerPage) {
            this.fetchBookings(page + 1, allTodaysBookings);
            return;
        }

        const sortedBookings = [...allTodaysBookings].sort(
            (a, b) => (getDateFromDayAndTime(a.day, a.startTime) > getDateFromDayAndTime(b.day, b.startTime) ? 1 : -1),
        );

        this.setState({
            nextBooking: sortedBookings.length > 0 ? sortedBookings[0] : null,
            loading: false,
        });
    };

    componentDidUpdate(prevProps: OwnProps) {
        if (prevProps.refresh !== this.props.refresh) {
            this.setState(initialState);
            this.fetchBookings();
        }
    }

    render() {
        const { t } = this.props;
        const { loading, bookingId, nextBooking } = this.state;

        if (loading) {
            return <React.Fragment />;
        }

        return (
            <div className="booking-modal">
                {!nextBooking ? (
                    <div className="booking-container booking-container--no-booking">
                        <img src={noBooking} alt="booking" className="booking-container--no-booking__image" />
                        <div className="booking-container--no-booking__content">
                            <span className="booking-container--no-booking__content__title">
                                {t('bookingModal.noReservation.title')}
                            </span>
                            <span className="booking-container--no-booking__content__text">
                                {t('bookingModal.noReservation.text')}
                            </span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            onClick={() => this.showBookingDetails(nextBooking)}
                            className={this.canShowDetails(nextBooking) ? 'clickable' : ''}
                        >
                            <BookingListEntry
                                booking={nextBooking}
                                fetchBookings={this.fetchBookings}
                                showPrice
                                cancelBookingCallback={() => this.handleCancelBooking(nextBooking)}
                            />
                        </div>
                        <BookingDetailsModal
                            bookingId={bookingId}
                            close={() => {
                                this.setState({ bookingId: undefined });
                                this.fetchBookings();
                            }}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default withTranslationContext(withBookingsContext(BookingModal));
