/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum GeneralAction {
    STORE_RESET = 'STORE_RESET',
}

export interface StoreResetActionType {
    type: typeof GeneralAction.STORE_RESET;
}

export type GeneralActionTypes = StoreResetActionType;
