/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum InvitationsAction {
    SET_INVITE_TOKEN = 'SET_INVITE_TOKEN',
}

export interface SetInviteTokenActionType {
    type: typeof InvitationsAction.SET_INVITE_TOKEN;
    payload: string | null;
}

export type InvitationsActionTypes = SetInviteTokenActionType;
