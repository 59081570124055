/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { FastMPContextProvider } from './FastMPContext';
import {
    fastMPServicesURL,
} from '../../services/fastMP';
import { FastMPStore } from '../../types/fastMP';
import { decodeFastId, encodeStickerId } from '../../utils/fastSticker';

interface OwnProps {
    children: any;
}

type Props = OwnProps;

export class FastMPController extends Component<Props> {
    // Requests
    getFastMPServices = async (fastMPStickerId: string, decode = false): Promise<FastMPStore[]> => {
        try {
            if ((!decode && !encodeStickerId(fastMPStickerId)) || (decode && !decodeFastId(fastMPStickerId))) return [];

            const requestUrl = fastMPServicesURL(decode ? decodeFastId(fastMPStickerId) : fastMPStickerId);

            const { data }: { data: FastMPStore[] } = await axios.get(requestUrl);
            return data;
        } catch {
            return [];
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <FastMPContextProvider
                value={{
                    getFastMPServices: this.getFastMPServices,
                }}
            >
                {children}
            </FastMPContextProvider>
        );
    }
}

export const ConnectedFastMPController = connect()(FastMPController);
