/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Dialog, DialogTitle, DialogContent, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ForwardIcon from '@material-ui/icons/KeyboardArrowRight';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Transition from '../elements/Transition';
import logo from '../../assets/images/logo-mixed.svg';
import products from '../../assets/images/products.png';
import { AppRoute } from '../../types/routes';
import { fastMPWebRedirectURL } from '../../services/fastMP';
import { FastMPStore } from '../../types/fastMP';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { FastMPContext, withFastMPContext } from '../controllers/FastMPContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import LanguageSelect from '../elements/LanguageSelect';
import { FAST_LEROY_STORE_ID } from '../../settings';

interface MatchParams {
    qrCodeId?: string;
    fastMPStickerId?: string;
}

interface OwnProps extends AuthenticationContext, FastMPContext, BookingsContext, TranslationContext, RouteComponentProps<MatchParams> { }

type Props = OwnProps;

const LandingScreen: FunctionComponent<Props> = (props: Props) => {
    const {
        t,
        match: {
            params: { qrCodeId, fastMPStickerId },
        },
        history,
        getFastMPServices,
        setQrCodeForCheckIn,
        getBollardByQrCodeId,
    } = props;

    const [services, setServices] = React.useState<FastMPStore[]>([]);

    const close = React.useCallback(() => {
        history.push(AppRoute.Map);
    }, [history]);

    const reserve = async () => {
        if (!qrCodeId) return;
        
        const bollard = await getBollardByQrCodeId(qrCodeId);
        if (bollard) {
            setQrCodeForCheckIn({ qrCodeId, areaId: bollard.businessAreaId, bollardId: bollard.id });
        }
        close();
    };

    React.useEffect(() => {
        if (fastMPStickerId) {
            getFastMPServices(fastMPStickerId, true).then(result => {
                if (result.length === 0) {
                    close();
                }
                
                const leroyStore = result.find(store => store.id === FAST_LEROY_STORE_ID);
                if (leroyStore) {
                    window.location.href = fastMPWebRedirectURL(fastMPStickerId, leroyStore.id);
                    return;
                }
                setServices(result);
            });
        }
    }, [fastMPStickerId, close, getFastMPServices]);

    const openService = (service: FastMPStore) => {
        if (fastMPStickerId) {
            window.open(fastMPWebRedirectURL(fastMPStickerId, service.id));
        }
    };

    return (
        <div className="auth-screen" id="scrollable-container">
            <Dialog
                open
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="fullscreen-modal landing-modal"
                TransitionComponent={Transition}
            >
                <DialogTitle className="fullscreen-modal__header">
                    <img src={logo} alt="Boser" />
                    <div className="landing-modal__header-box">
                        <LanguageSelect mobile alwaysShow />
                        <IconButton onClick={close} data-testid="landing-modal-close"><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <img src={products} alt="products" className="landing-modal__banner" />
                    <div className="landing-modal__content">
                        <h1 className="landing-modal__title">{t('landingModal.greeting')}</h1>
                        <h2 className="landing-modal__services">{t('landingModal.services', { total: services.length })}</h2>
                        <p className="landing-modal__message">{t('landingModal.order')}</p>
                        <ul className="landing-modal__list">
                            <li className="landing-modal__list-item" onClick={reserve}>
                                <img src={products} alt="reserve" className="landing-modal__tooltip" />
                                <div className="landing-modal__item-right">
                                    <div className="landing-modal__item-info">
                                        <p>{t('landingModal.reserve')}</p>
                                        <p>{t('landingModal.reserve2')}</p>
                                    </div>
                                </div>
                                <ForwardIcon />
                            </li>
                            {services.map(service => (
                                <li key={service.id} className="landing-modal__list-item" onClick={() => openService(service)}>
                                    <img src={service.logo} alt="reserve" className="landing-modal__tooltip" />
                                    <div className="landing-modal__item-right">
                                        <div className="landing-modal__item-info">
                                            <p>{service.name}</p>
                                        </div>
                                        <ForwardIcon />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default withAuthenticationContext(withFastMPContext(withBookingsContext(withRouter(withTranslationContext(LandingScreen)))));
