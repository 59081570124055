/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import {
    Backdrop, Button, CircularProgress, FormGroup,
} from '@material-ui/core';
import IconPerson from '@material-ui/icons/AccountCircleOutlined';
import IconLock from '@material-ui/icons/LockOutlined';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import { KeyedObject } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { AppRoute } from '../../types/routes';
import { hasFieldErrors } from '../../utils/validations';
import { ErrorCode } from '../../types/errors';
import IconEyeClosed from '../assets/IconEyeClosed';
import IconEye from '../assets/IconEye';

enum LoginFormField {
    Username = 'email',
    Password = 'password',
}

export interface LoginFormFields {
    [LoginFormField.Username]: string;
    [LoginFormField.Password]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps {
    setEmail?(email: string): void;
    isWelcomeScreen?: boolean;
    onLoginSuccess?: Function;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: LoginFormFields;
    errors: KeyedObject | null;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [LoginFormField.Username]: '',
        [LoginFormField.Password]: '',
    },
    errors: null,
};

class LoginForm extends Component<OwnProps, OwnState> {
    static defaultProps = {
        isWelcomeScreen: true,
        setEmail: () => {},
        onLoginSuccess: () => {},
    };

    state = initialState;

    onInputChange = (name: string, value: string): void => {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: value,
            },
        });
    };

    setCredentials = (email: string, password: string) => {
        this.setState({
            fields: {
                [LoginFormField.Username]: email,
                [LoginFormField.Password]: password,
            },
        });
    };

    onFormSubmit = (e: any): void => {
        e.preventDefault();

        const { submitLogin, validateLogin } = this.props;
        const { fields } = this.state;

        const errors = validateLogin(fields);

        this.setState({ errors });

        if (!errors) {
            submitLogin(fields, this.onFormSubmitSuccess, this.onFormSubmitFailure);
        }
    };

    onFormSubmitSuccess = () => {
        const { history, isWelcomeScreen, onLoginSuccess } = this.props;

        if (isWelcomeScreen) {
            history.push(AppRoute.Map);
        } else if (onLoginSuccess) onLoginSuccess();
    };

    onFormSubmitFailure = () => {
        const { t, loginErrors, setEmail } = this.props;
        const { fields } = this.state;

        if (loginErrors && loginErrors.errors && loginErrors.errors.length > 0) {
            if (loginErrors.errors.find((error: any) => error.errorCode === 8) !== undefined) {
                const container = document.getElementById('scrollable-container');
                if (container && container.scrollTo) {
                    container.scrollTo(0, container.scrollHeight);
                }
                if (setEmail) setEmail(fields[LoginFormField.Username]);
                displayNotification({
                    message: t('loginForm.activate'),
                    type: NotificationType.Info,
                });
            } else {
                loginErrors.errors.forEach((error: any) => {
                    displayNotification({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                        type: NotificationType.Danger,
                    });
                });
            }
        } else {
            let message = t('errors.general');
            if (hasFieldErrors(loginErrors)) message = t('errors.fields');

            displayNotification({
                message,
                type: NotificationType.Danger,
            });
        }
    };

    onActivationSuccess = () => {
        this.onFormSubmit(
            {
                preventDefault: () => { },
            },
        );
    };

    render() {
        const { t, loginFetching } = this.props;
        const { fields, errors } = this.state;

        return (
            <form onSubmit={this.onFormSubmit} className="narrow-form">
                <Backdrop open={loginFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <FormGroup row className="login-inputs">
                    <FormTextField
                        name={LoginFormField.Username}
                        value={fields[LoginFormField.Username]}
                        onChange={this.onInputChange}
                        placeholder={t('loginForm.usernameLabel')}
                        errors={errors}
                        disabled={loginFetching}
                        startAdornment={<IconPerson />}
                        testId="login-email"
                    />
                    <FormPasswordField
                        name={LoginFormField.Password}
                        value={fields[LoginFormField.Password]}
                        onChange={this.onInputChange}
                        placeholder={t('loginForm.passwordLabel')}
                        errors={errors}
                        disabled={loginFetching}
                        showButton
                        startAdornment={<IconLock />}
                        icon={<IconEye />}
                        iconToggled={<IconEyeClosed />}
                        testId="login-password"
                    />
                </FormGroup>
                <div className="login-bottom">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loginFetching}
                        className="white-button"
                        data-testid="login-submitButton"
                    >
                        {t('loginForm.submitButton')}
                    </Button>
                    <br />
                    <br />
                    <Link to={AppRoute.Recover}>{t('loginForm.recoverPassword')}</Link>
                </div>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(LoginForm)));
