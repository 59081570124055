/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { AuthenticationState } from './types';
import { AuthenticationAction } from '../actions/authentication_types';
import { GeneralAction } from '../actions/general_types';

export const authenticationInitialState: AuthenticationState = {
    token: null,
    user: null,
    isAuthenticated: false,
    loginRequest: {
        isFetching: false,
        errors: null,
    },
    registrationRequest: {
        isFetching: false,
        errors: null,
    },
    forgotPassRequest: {
        isFetching: false,
        errors: null,
    },
    resetPassRequest: {
        isFetching: false,
        errors: null,
    },
    profileChangeRequest: {
        isFetching: false,
        errors: null,
    },
};

export default function (state = authenticationInitialState, action: any): AuthenticationState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.authentication) {
                return {
                    ...action.payload.authentication,
                    loginRequest: authenticationInitialState.loginRequest,
                    registrationRequest: authenticationInitialState.registrationRequest,
                    forgotPassRequest: authenticationInitialState.forgotPassRequest,
                    resetPassRequest: authenticationInitialState.resetPassRequest,
                    profileChangeRequest: authenticationInitialState.profileChangeRequest,
                };
            }
            return state;
        case AuthenticationAction.LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                loginRequest: {
                    ...state.loginRequest,
                    isFetching: true,
                },
            };
        case AuthenticationAction.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                user: { ...action.payload.user, lastUpdate: new Date() },
                isAuthenticated: true,
                loginRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AuthenticationAction.LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                loginRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AuthenticationAction.REGISTRATION_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                registrationRequest: {
                    ...state.registrationRequest,
                    isFetching: true,
                },
            };
        case AuthenticationAction.REGISTRATION_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                registrationRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AuthenticationAction.REGISTRATION_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                registrationRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AuthenticationAction.FORGOT_PASS_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                forgotPassRequest: {
                    isFetching: true,
                    errors: null,
                },
            };
        case AuthenticationAction.FORGOT_PASS_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                forgotPassRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AuthenticationAction.FORGOT_PASS_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                forgotPassRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AuthenticationAction.RESET_PASS_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                resetPassRequest: {
                    isFetching: true,
                    errors: null,
                },
            };
        case AuthenticationAction.RESET_PASS_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                resetPassRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AuthenticationAction.RESET_PASS_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                resetPassRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AuthenticationAction.PROFILE_CHANGE_REQUEST:
            return {
                ...state,
                profileChangeRequest: {
                    ...state.profileChangeRequest,
                    isFetching: true,
                },
            };
        case AuthenticationAction.PROFILE_CHANGE_SUCCESS:
            return {
                ...state,
                user: { ...action.payload, lastUpdate: new Date() },
                profileChangeRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AuthenticationAction.PROFILE_CHANGE_FAILURE:
            return {
                ...state,
                profileChangeRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AuthenticationAction.LOGOUT_REQUEST:
        case GeneralAction.STORE_RESET:
            return authenticationInitialState;
        default:
            return state;
    }
}
