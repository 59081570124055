/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
    Backdrop, Button, CircularProgress, FormGroup,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import { KeyedObject, SelectOption, Country } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure, hasFieldErrors } from '../../utils/validations';
import FormSelectField from '../elements/FormSelectField';
import { countriesURL } from '../../services/general';
import { ProfileRequestPayload } from '../../types/authentication';
import { ProfileModalMode } from '../../types/profile';
import camera from '../../assets/images/camera.svg';
import Transition from '../elements/Transition';
import { ErrorCode } from '../../types/errors';

enum ProfileModalField {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    NewEmail = 'newEmail',
    PhoneNumber = 'phoneNumber',
    Code = 'code',
    OldPassword = 'oldPassword',
    Password = 'newPassword',
    RepeatPassword = 'repeatPassword',
    Country = 'country',
    NIF = 'fiscalCode',
}

export interface ProfileModalFields {
    [ProfileModalField.FirstName]: string;
    [ProfileModalField.LastName]: string;
    [ProfileModalField.Email]: string;
    [ProfileModalField.NewEmail]: string;
    [ProfileModalField.PhoneNumber]: string;
    [ProfileModalField.Code]: string;
    [ProfileModalField.OldPassword]: string;
    [ProfileModalField.Password]: string;
    [ProfileModalField.RepeatPassword]: string;
    [ProfileModalField.Country]: string;
    [ProfileModalField.NIF]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext {
    open: boolean;
    mode: ProfileModalMode;
    close: () => void;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: ProfileModalFields;
    errors: KeyedObject | null;
    phoneCountries: SelectOption[];
    avatar: File | null;
    avatarUrl: string;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [ProfileModalField.FirstName]: '',
        [ProfileModalField.LastName]: '',
        [ProfileModalField.Email]: '',
        [ProfileModalField.NewEmail]: '',
        [ProfileModalField.PhoneNumber]: '',
        [ProfileModalField.Code]: 'PT',
        [ProfileModalField.OldPassword]: '',
        [ProfileModalField.Password]: '',
        [ProfileModalField.RepeatPassword]: '',
        [ProfileModalField.Country]: '',
        [ProfileModalField.NIF]: '',
    },
    phoneCountries: [{ label: 'PT +351', value: 'PT' }],
    errors: null,
    avatar: null,
    avatarUrl: '',
};

class ProfileModal extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { authenticatedUser } = props;
        this.state = {
            ...initialState,
            fields: {
                ...initialState.fields,
                [ProfileModalField.FirstName]: authenticatedUser?.firstName || '',
                [ProfileModalField.LastName]: authenticatedUser?.lastName || '',
                [ProfileModalField.Email]: authenticatedUser?.email || '',
                [ProfileModalField.NewEmail]: '',
                [ProfileModalField.PhoneNumber]: String(authenticatedUser?.phoneNumber) || '',
                [ProfileModalField.Code]: authenticatedUser?.phoneCountry || 'PT',
                [ProfileModalField.Country]: authenticatedUser?.country || 'PT',
                [ProfileModalField.NIF]: authenticatedUser?.fiscalCode || '',
            },
        };
    }

    fetchCountries = () => {
        const phoneCountries: SelectOption[] = [];
        axios.get(countriesURL()).then((countriesResponse: any) => {
            countriesResponse.data.forEach((country: Country) => {
                phoneCountries.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
            });
            this.setState({ phoneCountries });
        });
    };

    componentDidMount() {
        this.fetchCountries();
    }

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFileChange = (evt: any) => {
        this.setState({
            avatar: evt.target.files[0],
            avatarUrl: URL.createObjectURL(evt.target.files[0]),
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        this.handleEmailUpdate();
        this.handleProfileUpdate();
    }

    handleEmailUpdateSuccess = () => {
        const { t } = this.props;
        displayNotification({
            message: t('profileForm.emailChanged'),
            type: NotificationType.Success,
        });
    }

    handleEmailUpdateFailure = (responseErrors?: KeyedObject) => {
        const { t } = this.props;

        let message = t('profileForm.emailChangeFailed');

        if (responseErrors) {
            if (responseErrors.errors[0]?.errorCode) {
                message = t(`errors.${ErrorCode[responseErrors.errors[0]?.errorCode]}`);
            } else if (hasFieldErrors(responseErrors)) {
                message = t('errors.EmailConstraint');
            }
        }

        displayNotification({
            message,
            type: NotificationType.Danger,
        });
    }

    handleEmailUpdate = async () => {
        const { fields } = this.state;
        const { token, submitEmailUpdate } = this.props;

        const newEmail = fields[ProfileModalField.NewEmail];

        if (!newEmail || !token) return;

        submitEmailUpdate(newEmail, this.handleEmailUpdateSuccess, this.handleEmailUpdateFailure);
    };

    handleProfileUpdate = (): void => {
        const { fields, avatar } = this.state;
        const {
            authenticatedUser, submitProfileChange, validateProfileChange, mode,
        } = this.props;

        const errors = validateProfileChange(fields, mode);

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    const payload: ProfileRequestPayload = {
                        firstName: fields[ProfileModalField.FirstName],
                        lastName: fields[ProfileModalField.LastName],
                        country: fields[ProfileModalField.Country],
                        fiscalCode: fields[ProfileModalField.NIF].length === 0 ? null : fields[ProfileModalField.NIF],
                    };
                    if (fields[ProfileModalField.OldPassword].length > 0) {
                        payload.oldPassword = fields[ProfileModalField.OldPassword];
                        payload.newPassword = fields[ProfileModalField.Password];
                    }

                    const formData = new FormData();

                    formData.append(
                        'customer',
                        new Blob([JSON.stringify(payload)], {
                            type: 'application/json',
                        }),
                    );

                    if (avatar !== null) {
                        formData.append('avatar', avatar);
                    }

                    submitProfileChange(
                        String(authenticatedUser?.id),
                        formData,
                        this.onFormSubmitSuccess,
                        this.onFormSubmitFailure,
                    );
                }
            },
        );
    };

    onFormSubmitSuccess = () => {
        const { t, mode } = this.props;

        displayNotification({
            message:
                mode === ProfileModalMode.Info ? t('profileForm.profileChanged') : t('profileForm.passwordChanged'),
            type: NotificationType.Success,
        });

        this.resetFormAndClose();
    };

    onFormSubmitFailure = () => {
        const { t, profileChangeErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, profileChangeErrors) });
    };

    resetFormAndClose = () => {
        const { authenticatedUser, close } = this.props;
        this.setState(
            {
                fields: {
                    ...initialState.fields,
                    [ProfileModalField.FirstName]: authenticatedUser?.firstName || '',
                    [ProfileModalField.LastName]: authenticatedUser?.lastName || '',
                    [ProfileModalField.Email]: authenticatedUser?.email || '',
                    [ProfileModalField.PhoneNumber]: String(authenticatedUser?.phoneNumber) || '',
                    [ProfileModalField.Code]: authenticatedUser?.phoneCountry || 'PT',
                    [ProfileModalField.Country]: authenticatedUser?.country || 'PT',
                    [ProfileModalField.NIF]: authenticatedUser?.fiscalCode || '',
                },
            },
            close,
        );
    };

    render() {
        const {
            t, authenticatedUser, profileChangeFetching, open, mode,
        } = this.props;
        const {
            fields, errors, phoneCountries, avatarUrl,
        } = this.state;
        return (
            <Dialog
                open={open}
                onClose={this.resetFormAndClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                className="profile-dialog"
            >
                <DialogContent>
                    <div className="modal-header">
                        <BackIcon onClick={this.resetFormAndClose} data-testid="modal-close" />
                        {mode === ProfileModalMode.Info ? (
                            <span>{t('profileForm.profileTitle')}</span>
                        ) : (
                            <span>{t('profileForm.passwordTitle')}</span>
                        )}
                    </div>

                    <form onSubmit={this.onFormSubmit} className="wide-form">
                        <Backdrop open={profileChangeFetching}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        {mode === ProfileModalMode.Info && (
                            <div className="wide-form__picture-container">
                                <div className="file-input-wrapper">
                                    <label htmlFor="file">
                                        <div className="file-input-wrapper__picture-container">
                                            <img
                                                className="profile-picture"
                                                src={
                                                    avatarUrl !== ''
                                                        ? avatarUrl
                                                        : `${authenticatedUser?.avatar}?${authenticatedUser?.lastUpdate}`
                                                }
                                                alt="avatar"
                                            />
                                            <div className="file-input-wrapper__picture-container__picture-overlay">
                                                <img src={camera} alt="overlay" />
                                            </div>
                                        </div>
                                    </label>
                                    <input
                                        className="file-input-wrapper__input"
                                        accept=".jpg,.png"
                                        type="file"
                                        id="file"
                                        onChange={this.onFileChange}
                                        data-testid="file-input"
                                        disabled={profileChangeFetching}
                                    />
                                </div>
                                <p>
                                    {t('general.format')} <br /> {t('general.image')}
                                </p>
                            </div>
                        )}

                        <div className="wide-form__grid-container">
                            {mode === ProfileModalMode.Info ? (
                                <>
                                    <FormTextField
                                        name={ProfileModalField.FirstName}
                                        value={fields[ProfileModalField.FirstName]}
                                        onChange={this.onInputChange}
                                        placeholder={t('profileForm.firstNameLabel')}
                                        errors={errors}
                                        disabled={profileChangeFetching}
                                    />
                                    <FormTextField
                                        name={ProfileModalField.LastName}
                                        value={fields[ProfileModalField.LastName]}
                                        onChange={this.onInputChange}
                                        placeholder={t('profileForm.lastNameLabel')}
                                        errors={errors}
                                        disabled={profileChangeFetching}
                                    />
                                    <div className="wide-form__grid-container__double-space-mobile">
                                        <FormTextField
                                            name={ProfileModalField.NIF}
                                            value={fields[ProfileModalField.NIF]}
                                            onChange={this.onInputChange}
                                            placeholder={t('registerForm.nifLabel')}
                                            errors={errors}
                                            disabled={profileChangeFetching}
                                        />
                                    </div>
                                    <div className="wide-form__grid-container__double-input wide-form__grid-container__double-space-mobile">
                                        <FormSelectField
                                            name={ProfileModalField.Code}
                                            errors={errors}
                                            disabled
                                            onChange={this.onInputChange}
                                            options={phoneCountries}
                                            value={fields[ProfileModalField.Code]}
                                            testId="phone-code-select"
                                        />
                                        <div className="wide-form__grid-container__double-input__divider">
                                            <div />
                                        </div>
                                        <FormTextField
                                            name={ProfileModalField.PhoneNumber}
                                            value={fields[ProfileModalField.PhoneNumber]}
                                            onChange={this.onInputChange}
                                            placeholder={t('profileForm.phoneLabel')}
                                            errors={errors}
                                            disabled
                                        />
                                    </div>
                                    <div className="wide-form__grid-container__double-space-mobile">
                                        <FormTextField
                                            name={ProfileModalField.Email}
                                            value={fields[ProfileModalField.Email]}
                                            onChange={this.onInputChange}
                                            placeholder={t('profileForm.emailLabel')}
                                            errors={errors}
                                            disabled
                                        />
                                    </div>
                                    <div className="wide-form__grid-container__double-space-mobile">
                                        <FormTextField
                                            name={ProfileModalField.NewEmail}
                                            value={fields[ProfileModalField.NewEmail]}
                                            onChange={this.onInputChange}
                                            placeholder={t('profileForm.changeEmailLabel')}
                                            errors={errors}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="wide-form__grid-container__double-space-mobile">
                                        <FormPasswordField
                                            name={ProfileModalField.OldPassword}
                                            value={fields[ProfileModalField.OldPassword]}
                                            onChange={this.onInputChange}
                                            placeholder={t('profileForm.oldPasswordLabel')}
                                            errors={errors}
                                            disabled={profileChangeFetching}
                                        />
                                    </div>
                                    <div className="mobile-hidden" />
                                    <div className="wide-form__grid-container__double-space-mobile">
                                        <FormPasswordField
                                            name={ProfileModalField.Password}
                                            value={fields[ProfileModalField.Password]}
                                            onChange={this.onInputChange}
                                            placeholder={t('profileForm.passwordLabel')}
                                            errors={errors}
                                            disabled={profileChangeFetching}
                                        />
                                    </div>
                                    <div className="wide-form__grid-container__row">
                                        <div className="wide-form__grid-container__double-space-mobile">
                                            <FormPasswordField
                                                name={ProfileModalField.RepeatPassword}
                                                value={fields[ProfileModalField.RepeatPassword]}
                                                onChange={this.onInputChange}
                                                placeholder={t('profileForm.repeatPasswordLabel')}
                                                errors={errors}
                                                disabled={profileChangeFetching}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <FormGroup row>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={profileChangeFetching}
                                className="modal-button"
                                data-testid="profile-submit-button"
                            >
                                {mode === ProfileModalMode.Info
                                    ? t('profileForm.submitButton')
                                    : t('profileForm.submitButtonPassword')}
                            </Button>
                        </FormGroup>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(ProfileModal));
