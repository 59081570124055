/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from './FormTextField';
import greenIcon from '../../assets/images/next-green-icon.svg';
import { KeyedObject } from '../../types/general';

interface OwnProps {
    onSave: (mbwayNumber: string) => void;
    errors: KeyedObject | null;
}

type Props = OwnProps & TranslationContext;

export const MBWayField:React.FC<OwnProps> = withTranslationContext((props: Props) => {
    const { t, onSave, errors } = props;
    const [mbway, setMbway] = React.useState('');
    
    return (
        <div className="payment-methods">
            <div className="payment-methods__mbway">
                <span>{t('reservationModal.payment.mbwayHelper')}</span>
                <FormTextField
                    name="clientPhoneNumber"
                    value={mbway}
                    onChange={(_: string, value: string) => setMbway(value)}
                    errors={errors}
                    testId="mbwayNumber"
                    showButton
                    onClick={() => onSave(mbway)}
                    icon={<img className="payment-methods__mbway__icon" src={greenIcon} alt="next step" />}
                />
            </div>
        </div>
    );
});
