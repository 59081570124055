/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button, CircularProgress, Dialog, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import { BOSER_BUSINESS_URL } from '../../settings';

interface OwnProps extends TranslationContext, InvitationsContext {}

const AcceptInviteModal: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, setInviteToken, acceptInvite } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const onClose = () => {
        setInviteToken(null);
    };

    const handleAcceptSuccess = () => {
        setShowSuccess(true);
        setShowError(false);
        setIsFetching(false);
    };

    const handleAcceptFailure = () => {
        setShowError(true);
        setShowSuccess(false);
        setIsFetching(false);
    };

    const onAcceptInvite = () => {
        setIsFetching(true);
        acceptInvite(handleAcceptSuccess, handleAcceptFailure);
    };

    const renderMsg = () => {
        if (showError) {
            return <p className="accept-invite-modal__error-msg">{t('acceptInvite.errorMsg')}</p>;
        }

        if (showSuccess) {
            return (
                <p data-testid="success-msg">
                    {t('acceptInvite.canLoginNowSuccessMsg')}&nbsp;
                    <a href={BOSER_BUSINESS_URL}>{BOSER_BUSINESS_URL.split('https://').pop()}</a>
                </p>
            );
        }

        return <p>{t('acceptInvite.infoMessage')}</p>;
    };

    return (
        <Dialog open>
            <div className="dialog accept-invite-modal">
                <div className="dialog__header">
                    <h3>{t('acceptInvite.title')}</h3>
                    <IconButton onClick={onClose} size="small" data-testid="close-btn">
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="dialog__inner">
                    {renderMsg()}
                </div>
                <div className="dialog__buttons">
                    {(showSuccess || showError) ? (
                        <Button onClick={onClose} variant="contained" color="primary" className="modal-button">
                            {t('acceptInvite.closeBtn')}
                        </Button>
                    ) : (
                        <Button
                            onClick={onAcceptInvite}
                            variant="contained"
                            color="primary"
                            className="modal-button"
                            disabled={isFetching}
                            data-testid="accept-btn"
                        >
                            {t('acceptInvite.acceptBtn')}
                            {isFetching && <CircularProgress />}
                        </Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default withTranslationContext(withInvitationsContext(AcceptInviteModal));
