/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    ChangeEvent, Component, KeyboardEvent, ReactNode,
} from 'react';
import { uniqueId } from 'lodash';
import {
    InputAdornment, IconButton, FormControl, FormHelperText, InputLabel, Input,
} from '@material-ui/core';

import ErrorMessage from './ErrorMessage';
import { KeyedObject } from '../../types/general';
import { FormValidatorError, getErrorsForField, hasAnyErrors } from '../../utils/validations';

interface OwnProps {
    id?: string;
    name: string;
    label?: string;
    value: string;
    errors: KeyedObject | null;
    icon?: any;
    multiline?: boolean;
    rows?: number;
    startAdornment?: any;
    endAdornment?: any;
    showButton?: boolean;
    required?: boolean;
    disabled?: boolean;
    blockAutoComplete?: boolean;
    onChange?: Function;
    onClick?: Function;
    onInputClick?: () => void;
    showStartButton?: Function;
    startIcon?: any;
    keyDownEnabled?: boolean;
    fullWidth?: boolean;
    onStartAdornmentClick?: Function;
    placeholder?: string;
    isReadOnly?: boolean;
    hint?: string;
    testId?: string;
    errorMessageOverride?: { typeOfViolation: string, message: string }
    inputProps?: any;
    className?: string;
}

interface OwnState {
    readOnly: boolean;
}

const initialState: OwnState = {
    readOnly: true,
};

class FormTextField extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly inputRef = React.createRef<HTMLInputElement>();

    onFocus = (): void => {
        this.setState({
            readOnly: false,
        });
    };

    onClick = (): void => {
        const { onClick } = this.props;
        if (onClick) onClick();
    };

    onStartButtonClick = (): void => {
        const { onStartAdornmentClick } = this.props;
        if (onStartAdornmentClick) onStartAdornmentClick();
    };

    onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        const { keyDownEnabled } = this.props;

        if (keyDownEnabled && event.key === 'Enter') {
            event.preventDefault();
        }
    };

    onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(event.currentTarget.name, event.currentTarget.value);
        }
    };

    renderStartAdornment(): ReactNode {
        const { startAdornment, showStartButton, startIcon } = this.props;

        if (showStartButton) {
            return (
                <InputAdornment position="start">
                    <IconButton onClick={this.onStartButtonClick}>{startIcon}</IconButton>
                </InputAdornment>
            );
        }

        if (startAdornment) {
            return <InputAdornment position="start">{startAdornment}</InputAdornment>;
        }

        return null;
    }

    renderEndAdornment(): ReactNode {
        const { icon, showButton, endAdornment } = this.props;

        if (showButton) {
            return (
                <InputAdornment position="end">
                    <IconButton onClick={this.onClick}>{icon}</IconButton>
                </InputAdornment>
            );
        }

        if (endAdornment) {
            return <InputAdornment position="end">{endAdornment}</InputAdornment>;
        }

        return icon;
    }

    renderErrors(fieldErrors: FormValidatorError[]): ReactNode {
        const { name, errorMessageOverride } = this.props;

        return <ErrorMessage errors={fieldErrors} field={name} errorMessageOverride={errorMessageOverride} />;
    }

    render() {
        const {
            id,
            name,
            errors,
            label,
            value,
            required,
            disabled,
            multiline,
            rows,
            blockAutoComplete,
            className,
            placeholder,
            isReadOnly,
            hint,
            testId,
            inputProps,
            onInputClick,
        } = this.props;
        const { readOnly } = this.state;

        const fieldId = id || uniqueId();
        const reallyReadOnly = isReadOnly || (blockAutoComplete ? readOnly : false);
        const fieldErrors = getErrorsForField(name, errors);
        const hasErrors = hasAnyErrors(fieldErrors);

        return (
            <FormControl
                fullWidth
                className={`input-container ${isReadOnly ? 'readonly' : ''}`}
                error={hasErrors}
                required={required}
                disabled={disabled}
            >
                {label && <InputLabel htmlFor={fieldId}>{label}</InputLabel>}
                <Input
                    name={name}
                    className={className}
                    readOnly={reallyReadOnly}
                    id={fieldId}
                    value={value}
                    placeholder={placeholder}
                    multiline={multiline}
                    rows={rows}
                    maxRows={rows}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    autoComplete={value === null ? '' : String(value)}
                    startAdornment={this.renderStartAdornment()}
                    endAdornment={this.renderEndAdornment()}
                    onKeyDown={this.onKeyDown}
                    disableUnderline
                    data-testid={testId}
                    inputRef={this.inputRef}
                    inputProps={inputProps}
                    onClick={() => {
                        if (onInputClick) onInputClick();
                        if (onInputClick && this.inputRef.current) this.inputRef.current.blur();
                    }}
                />
                <FormHelperText>{hint}</FormHelperText>
                <FormHelperText>{this.renderErrors(fieldErrors)}</FormHelperText>
            </FormControl>
        );
    }
}

export default FormTextField;
