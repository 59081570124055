/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { InvitationsAction, InvitationsActionTypes } from './invitations_types';

export const setInviteTokenActionCreator = (token: string | null): InvitationsActionTypes => {
    return {
        type: InvitationsAction.SET_INVITE_TOKEN,
        payload: token,
    };
};
