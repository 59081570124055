/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import LanguageSelect from '../elements/LanguageSelect';
import Loader from '../elements/Loader';
import { BOSER_BUSINESS_URL } from '../../settings';
import { InvitationUserStatus } from '../../types/invitations';
import { AppRoute } from '../../types/routes';

import logo from '../../assets/images/logo.svg';
import { ErrorCode } from '../../types/errors';
import { BUSINESS_REGISTER_ROUTE } from '../../utils/constants';

interface OwnProps extends TranslationContext, AuthenticationContext, InvitationsContext {}

const AcceptInviteScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, validateInviteToken, isAuthenticated, setInviteToken,
    } = props;

    const { token } = useParams<{ token: string }>();
    const history = useHistory();
    const [isFetching, setIsFetching] = useState(true);
    const [invitationStatus, setInvitationStatus] = useState<InvitationUserStatus | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);

    useEffect(() => {
        const prepare = async () => {
            await validateInviteToken(token, onSuccess, onFailure);
        };

        prepare();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const onSuccess = (userInvitationStatus: InvitationUserStatus) => {
        setInvitationStatus(userInvitationStatus);
        setIsFetching(false);

        if (isAuthenticated) {
            setInviteToken(token);
            history.push(AppRoute.Map);
        }
    };

    const onFailure = (code: number) => {
        setIsFetching(false);
        setErrorCode(code);
    };

    const redirectToBusinessApp = () => {
        window.location.replace(`${BOSER_BUSINESS_URL}/${BUSINESS_REGISTER_ROUTE}/${token}`);
    };

    const goToLoginPage = () => {
        setInviteToken(token);
        history.push(AppRoute.Welcome);
    };

    const renderMsg = () => {
        if (errorCode !== null) {
            return (
                <div className="accept-invite-screen__inner__error-msg">
                    <h4>{t(`errors.${ErrorCode[errorCode]}`)}</h4>
                </div>
            );
        }

        if (invitationStatus === InvitationUserStatus.NoAccount) {
            return (
                <div className="accept-invite-screen__inner__msg">
                    <p>{t('acceptInvite.registerInfoMessage')}</p>
                    <Button variant="contained" className="white-button" onClick={redirectToBusinessApp}>
                        {t('acceptInvite.createAccountBtn')}
                    </Button>
                </div>
            );
        }

        return (
            <div className="accept-invite-screen__inner__msg">
                <p>{t('acceptInvite.shouldLoginMessage')}</p>
                <Button variant="contained" className="white-button" onClick={goToLoginPage}>
                    {t('acceptInvite.goToLogin')}
                </Button>
            </div>
        );
    };

    return (
        <div className="accept-invite-screen">
            <LanguageSelect overlay alwaysShow />
            <div className="accept-invite-screen__inner">
                <img src={logo} alt="Boser" className="accept-invite-screen__inner__logo" />
                <h2>{t('acceptInvite.title')}</h2>
                <h3>{t('acceptInvite.infoMessage')}</h3>
                {isFetching ? <Loader /> : renderMsg()}
            </div>
        </div>
    );
};

export default withTranslationContext(withAuthenticationContext(withInvitationsContext(AcceptInviteScreen)));
