/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import logo from '../../assets/images/logo.svg';
import ResetForm from '../views/ResetForm';
import LanguageSelect from '../elements/LanguageSelect';

type OwnProps = TranslationContext

interface OwnState {}

const initialState: OwnState = {};

class RecoverScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const { t } = this.props;
        return (
            <div className="auth-screen">
                <LanguageSelect mobile />
                <div className="auth-screen__recover-container">
                    <img src={logo} alt="Boser" className="auth-screen__logo" />
                    <div className="auth-screen__greeting">
                        <span className="auth-screen__greeting__title">{t('resetScreen.title')}</span>
                        <span className="auth-screen__greeting__text">{t('resetScreen.description')}</span>
                    </div>
                    <ResetForm />
                    <span className="auth-screen__recover-container__bottom">{t('recoverScreen.mustFill')}</span>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(RecoverScreen);
