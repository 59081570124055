/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import {
    Backdrop, Button, CircularProgress, DialogActions, DialogTitle, Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import PinIcon from '@material-ui/icons/PlaceOutlined';
import PeopleIcon from '@material-ui/icons/Group';
import { Create } from '@material-ui/icons';

import CalendarIcon from '@material-ui/icons/CalendarToday';
import TimeIcon from '@material-ui/icons/AccessTime';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { uniqBy } from 'lodash';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { Business } from '../../types/businesses';
import { getArea, getAreas } from '../../services/businesses';
import { Area as IArea, ReservationPeriod } from '../../types/areas';
import {
    KeyedObject, PaymentMethods, SelectOption, StepReservation, WeekDays,
} from '../../types/general';
import FormTextField from '../elements/FormTextField';
import FormSelectField from '../elements/FormSelectField';
import Map from '../elements/Map';
import {
    BollardSchedule, Booking, BookingStatus, ScheduleEntry,
} from '../../types/bookings';
import { customMarker } from '../../utils/maps';
import taken from '../../assets/images/pin-taken.svg';
import free from '../../assets/images/pin-free.svg';
import picked from '../../assets/images/pin-picked.svg';
import phone from '../../assets/images/phone-handset-line.svg';
import { handleFormSubmitFailure, validateForm } from '../../utils/validations';
import { displayNotification, NotificationType } from '../../utils/notifications';
import Transition from '../elements/Transition';
import { PaymentMethodsPicker } from '../elements/PaymentMethodsPicker';
import { MBWayField } from '../elements/MBWayField';
import picnic from '../../assets/images/picnic.png';
import mbwayLogo from '../../assets/images/mbway-logo.png';
import arrowLeft from '../../assets/images/arrow-left.svg';
import creditCardLogo from '../../assets/images/credit-card.svg';
import atmReferenceLogo from '../../assets/images/reference-logo.png';
import nextIcon from '../../assets/images/next-green-icon.svg';
import 'moment/locale/pt';
import { AppRoute } from '../../types/routes';
import {
    buildRoute, formatHourTime, numberToCurrency, timer,
} from '../../utils/misc';
import { MultipleDatePicker } from '../elements/MultiDatePicker';
import { DATE_FORMAT } from '../../utils/constants';
import { calendarMultiDayRepresentation } from '../../utils/dates';
import { validations } from '../../types/validations';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import LoginForm from './LoginForm';

enum ReservationModalField {
    Area = 'areaId',
    Bollard = 'bollardId',
    Period = 'reservationPeriodId',
    Days = 'days',
    Pax = 'numberOfPeople',
    Observations = 'observations',
    ClientPhoneNumber = 'clientPhoneNumber',
    CardNumber = 'cardNumber',
    Validity = 'validity',
    CCV = 'ccv',
}

export interface ReservationModalFields {
    [ReservationModalField.Area]: string;
    [ReservationModalField.Bollard]: number;
    [ReservationModalField.Period]: React.Key;
    [ReservationModalField.Days]: moment.Moment[];
    [ReservationModalField.Pax]: string;
    [ReservationModalField.Observations]: string;
    [ReservationModalField.ClientPhoneNumber]: string;
    [ReservationModalField.CardNumber]: string;
    [ReservationModalField.Validity]: string;
    [ReservationModalField.CCV]: string;
}

interface OwnProps extends TranslationContext, BookingsContext {
    business: Business | null;
    areaId: string | undefined;
    close: () => void;
}

interface OwnState {
    selectedArea: IArea | null;
    selectedPeriod: ReservationPeriod | null;
    fields: ReservationModalFields;
    areas: IArea[];
    errors: KeyedObject | null;
    reservationPeriods: SelectOption[];
    mapRef: google.maps.Map | null;
    areaPolygon: google.maps.Polygon | null;
    schedules: BollardSchedule[];
    bollardMarkers: google.maps.Marker[];
    stepReservation: number;
    clientPhoneNumber: string;
    reservationCreated: Booking | null;
    startTimeTimer: moment.Moment;
    mbWayTimer: string;
    showDatePicker: boolean;
}

const initialState: OwnState = {
    selectedArea: null,
    selectedPeriod: null,
    fields: {
        [ReservationModalField.Area]: '',
        [ReservationModalField.Bollard]: -1,
        [ReservationModalField.Period]: '',
        [ReservationModalField.Days]: [moment()],
        [ReservationModalField.Pax]: '1',
        [ReservationModalField.Observations]: '',
        [ReservationModalField.ClientPhoneNumber]: '',
        [ReservationModalField.CardNumber]: '',
        [ReservationModalField.Validity]: '',
        [ReservationModalField.CCV]: '',
    },
    areas: [],
    errors: null,
    reservationPeriods: [],
    mapRef: null,
    areaPolygon: null,
    schedules: [],
    bollardMarkers: [],
    stepReservation: StepReservation.CHOOSE_LOCAL,
    clientPhoneNumber: '',
    reservationCreated: null,
    startTimeTimer: moment(),
    mbWayTimer: '5:00',
    showDatePicker: false,
};

class ReservationModal extends Component<Props, OwnState> {
    state = initialState;

    private readonly timerAbortController = new AbortController();

    reservationPollingInterval: NodeJS.Timeout | null = null;

    componentDidMount() {
        const { language } = this.props;
        moment.locale(language);
    }

    componentDidUpdate(oldProps: OwnProps) {
        const { business, areaId } = this.props;

        if (oldProps.business === null || (business && oldProps.business.id !== business.id)) {
            if (business !== null) {
                getAreas({ businessId: business.id, _limit: 999 }).then(result => {
                    if (result !== null) {
                        this.setState({
                            areas: result.areas,
                        });

                        const selectedArea = result.areas.find((area: IArea) => String(area.id) === areaId);

                        if (selectedArea) {
                            this.selectArea(selectedArea);
                        } else if (result.areas.length > 0) {
                            this.selectArea(result.areas[0]);
                        }
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        this.cleanIntervals();
    }

    onMapMounted = (ref: any) => {
        const { business } = this.props;
        const { selectedArea } = this.state;

        if (business) {
            customMarker(ref, { lat: business.address.lat, lng: business.address.lng }, undefined, business.name);
        }

        this.setState({
            mapRef: ref,
        }, () => {
            if (selectedArea) {
                if (ref !== null) ref.fitBounds(new google.maps.LatLngBounds(selectedArea.boundSW, selectedArea.boundNE));
                this.refreshArea();
                this.fetchSchedules();
            }
        });
    };

    onMBWaySave = (phoneNumber: string) => {
        const { fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [ReservationModalField.ClientPhoneNumber]: phoneNumber,
            },
        }, () => this.onFormSubmit(PaymentMethods.MB_WAY));
    }

    onFormSubmit = (paymentType: PaymentMethods): void => {
        const {
            submitNewBooking, validateNewBooking, t,
        } = this.props;
        const { fields } = this.state;

        const payload = {
            ...fields,
            paymentType,
            numberOfPeople: fields[ReservationModalField.Pax],
            clientPhoneNumber: fields[ReservationModalField.ClientPhoneNumber] || null,
            cardNumber: fields[ReservationModalField.CardNumber] || null,
            validity: fields[ReservationModalField.Validity] || null,
            ccv: fields[ReservationModalField.CCV] || null,
            [ReservationModalField.Days]: fields[ReservationModalField.Days].map(day => day.format(DATE_FORMAT)),
        };

        const errors = validateNewBooking(payload);

        this.setState({
            errors,
        });

        if (fields[ReservationModalField.Bollard] === -1) {
            displayNotification({
                message: t('reservationModal.selectSpot'),
                type: NotificationType.Danger,
            });

            return;
        }

        if (errors) return;

        submitNewBooking(payload, this.onFormSubmitSuccess, this.onFormSubmitFailure);
    };

    onFormSubmitSuccess = (createdBookings: Booking[]): void => {
        // all reservations are grouped and status is equal on all of them
        const createdBooking = createdBookings.pop();

        switch (createdBooking?.status) {
            case BookingStatus.ATMReferenceCreated:
                this.setState({
                    stepReservation: StepReservation.ATM_REFERENCE_INFO,
                    reservationCreated: createdBooking,
                }, () => this.setReservationPollingInterval());
                break;
            case BookingStatus.MBWayRequestCreated:
                this.setState({
                    stepReservation: StepReservation.MBWAY_TIMER,
                    reservationCreated: createdBooking,
                }, () => {
                    this.setMbwayTimerInterval();
                    this.setReservationPollingInterval();
                });
                break;
            default:
                this.verifyAutoCheckinAndHandleSuccess();
        }
    };

    onFormSubmitFailure = () => {
        const { t, createBookingErrors, setQrCodeForCheckIn } = this.props;
        this.setState({ errors: handleFormSubmitFailure(t, createBookingErrors) });
        setQrCodeForCheckIn({});
    };

    onSelectDays = (date: moment.Moment[]) => {
        const { selectedArea, fields, errors } = this.state;
        if (!date) return;

        this.setState({
            fields: {
                ...fields,
                [ReservationModalField.Days]: [...date],
                [ReservationModalField.Period]: '',
                [ReservationModalField.Bollard]: -1,
            },
            errors: {
                ...errors,
                fields: {
                    ...errors?.fields,
                    [ReservationModalField.Days]: null,
                },
            },
            schedules: [],
            selectedPeriod: null,
        }, () => {
            this.setAvailableReservationPeriods(selectedArea?.reservationPeriods);
            this.fetchSchedules();
        });
    };

    onMarkerClick = (schedule: BollardSchedule) => {
        const { fields, selectedPeriod } = this.state;

        const entryFound = schedule.scheduleEntries.find(se => selectedPeriod?.endTime === se.endTime && selectedPeriod?.startTime === se.startTime);
        if (entryFound && !entryFound.reserved) {
            this.setState({
                fields: {
                    ...fields,
                    [ReservationModalField.Bollard]: schedule.bollard.id,
                },
            }, this.refreshMarkers);
        }
    };

    onPeriodChange = async (name: string, value: string, defaultBollardId?: number): Promise<void> => {
        const { selectedArea, fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [name]: value,
                [ReservationModalField.Bollard]: defaultBollardId || -1,
            },
        });

        if (selectedArea?.reservationPeriods !== undefined) {
            const selectedPeriod = selectedArea?.reservationPeriods.find(period => period.id === Number(value));

            if (selectedPeriod) {
                this.setState({ selectedPeriod }, this.refreshMarkers);
            }
        }
    };

    onFieldChange = (name: string, value: string): void => {
        const { fields, errors } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                fields: {
                    ...errors?.fields,
                    [name]: null,
                },
            },
        });
    };

    onBack = () => {
        const { close } = this.props;
        const { stepReservation, fields } = this.state;

        switch (stepReservation) {
            case StepReservation.CHOOSE_PAYMENT:
                this.setState({
                    stepReservation: StepReservation.CHOOSE_LOCAL,
                });
                break;
            case StepReservation.AREA_DESCRIPTION:
                this.setState({
                    stepReservation: StepReservation.CHOOSE_LOCAL,
                });
                break;
            case StepReservation.CREDIT_CARD_INFO:
                this.setState({
                    fields: {
                        ...fields,
                        [ReservationModalField.CardNumber]: '',
                        [ReservationModalField.Validity]: '',
                        [ReservationModalField.CCV]: '',
                    },
                    stepReservation: StepReservation.CHOOSE_PAYMENT,
                });
                break;
            case StepReservation.MBWAY_INFO:
                this.setState({
                    fields: {
                        ...fields,
                        [ReservationModalField.ClientPhoneNumber]: '',
                    },
                    stepReservation: StepReservation.CHOOSE_PAYMENT,
                });
                break;
            case StepReservation.LOGIN:
                this.setState({ stepReservation: StepReservation.CHOOSE_LOCAL });
                break;
            default:
                close();
                this.setState({ ...initialState });
        }
    };

    fetchSchedules = async () => {
        const { business, getSchedules } = this.props;
        const { selectedArea, fields } = this.state;

        if (!business || !selectedArea) return;

        const allSchedules = await getSchedules(
            String(business.id),
            String(selectedArea.id),
            fields[ReservationModalField.Days].map(day => day.format(DATE_FORMAT)),
        );

        if (allSchedules.length === 0) return;

        const commonBollardSchedules: BollardSchedule[] = [...allSchedules[0].schedules.map(
            s => ({ ...s, scheduleEntries: [...s.scheduleEntries] }),
        )];

        // makes sure all schedules have the same periods to merge them into only one list of schedules
        // by shrinking to all common periods between selected days
        for (let scheduleDayIndex = 1; scheduleDayIndex < allSchedules.length; scheduleDayIndex++) {
            const bollardSchedulesCurrent = allSchedules[scheduleDayIndex].schedules;

            if (scheduleDayIndex > 2 && commonBollardSchedules.length === 0) break;

            for (let bollardIndex = 0; bollardIndex < bollardSchedulesCurrent.length; bollardIndex++) {
                const scheduleEntriesPrevious = commonBollardSchedules[bollardIndex];
                const scheduleEntriesCurrent = bollardSchedulesCurrent[bollardIndex].scheduleEntries;

                const samePeriodFilter = (a: ScheduleEntry, b: ScheduleEntry) => (a?.endTime === b?.endTime && a?.startTime === b?.startTime);
                const filterCommon = (a: ScheduleEntry) => scheduleEntriesCurrent.some(b => samePeriodFilter(a, b));
                const commonSchedules = scheduleEntriesPrevious.scheduleEntries.filter(filterCommon);

                const newScheduleEntries: ScheduleEntry[] = commonSchedules.map(commonSchedule => {
                    const reserved = commonSchedule.reserved || scheduleEntriesCurrent
                        .find(se => samePeriodFilter(se, commonSchedule))?.reserved;

                    const price = Number(commonSchedule.price || 0) + Number(scheduleEntriesCurrent.find(
                        se => samePeriodFilter(se, commonSchedule),
                    )?.price || 0);

                    return {
                        ...commonSchedule,
                        reserved: !!reserved,
                        price: String(price),
                    };
                });

                commonBollardSchedules[bollardIndex] = {
                    ...commonBollardSchedules[bollardIndex],
                    scheduleEntries: newScheduleEntries,
                };
            }
        }

        if (!commonBollardSchedules.length) {
            this.setState({ schedules: [] }, this.refreshMarkers);
            return;
        }

        const sortScheduleEntriesBasedOnStartTime = (a: ScheduleEntry, b: ScheduleEntry) => a.startTime.localeCompare(b.startTime);

        // removes all periods past current time from today's day and sorts
        const schedulesWithDatesAndTimesSorted = commonBollardSchedules.map(
            s => ({
                ...s,
                scheduleEntries: s.scheduleEntries
                    .filter(schedule => !this.isPeriodFinished(fields[ReservationModalField.Days][0], schedule))
                    .sort(sortScheduleEntriesBasedOnStartTime),
            }),
        );

        // limit available periods
        this.setState({
            schedules: schedulesWithDatesAndTimesSorted,
        }, this.refreshMarkers);
    }

    cleanIntervals = () => {
        this.timerAbortController.abort();
        if (this.reservationPollingInterval) clearInterval(this.reservationPollingInterval);
    }

    setReservationPollingInterval = () => {
        this.reservationPollingInterval = setInterval(() => {
            this.pollReservationToVerifyPayment();
        }, 5000);
    }

    setMbwayTimerInterval = () => {
        this.setState({
            startTimeTimer: moment(),
        }, () => timer(1000, this.timerAbortController.signal, this.updateTimer));
    }

    updateTimer = (time: number) => {
        const { startTimeTimer } = this.state;

        // this animation has no compensation for putting page on background
        const timeElapsed = moment().diff(startTimeTimer, 'seconds');
        const fullTime = time + (timeElapsed - time);

        if (fullTime >= 5 * 60 - 1) {
            this.cleanIntervals();

            this.setState({
                stepReservation: StepReservation.ERROR,
            });
        }

        this.setState({
            mbWayTimer: `${4 - Math.floor(fullTime / 60)}:${59 - (fullTime % 60)}`,
        });
    }

    verifyAutoCheckinAndHandleSuccess = () => {
        const {
            t, qrCodeBooking, submitCheckIn, history, setQrCodeForCheckIn,
        } = this.props;
        const { selectedArea } = this.state;

        displayNotification({
            message: t('reservationModal.success.notification'),
            type: NotificationType.Success,
        });

        if (qrCodeBooking && qrCodeBooking.qrCodeId && selectedArea?.id === qrCodeBooking.areaId) { // auto check-in because already verified qrcode
            submitCheckIn(String(qrCodeBooking.qrCodeId)).then(data => {
                if (data) {
                    history.push(buildRoute(AppRoute.BookingDetails, { bookingId: data.id }));
                    displayNotification({
                        message: t('qrCode.checkedIn'),
                        type: NotificationType.Success,
                    });
                }
            });
        }
        setQrCodeForCheckIn({});
        this.setState({ stepReservation: StepReservation.SUCCESS });
    }

    getPeriodLabel(period: Booking | ScheduleEntry | ReservationPeriod) {
        const startLabel = formatHourTime(period.startTime);
        const endLabel = formatHourTime(period.endTime);
        return `${startLabel} - ${endLabel}`;
    }

    pollReservationToVerifyPayment = async () => {
        const { getBooking } = this.props;
        const { reservationCreated } = this.state;

        if (!reservationCreated) return;

        const data = await getBooking(reservationCreated.id);

        if (data && data.status === BookingStatus.Reserved) {
            this.cleanIntervals();
            this.setState({
                stepReservation: StepReservation.SUCCESS,
            });
        }
    }

    refreshArea = () => {
        const { mapRef, areaPolygon, selectedArea } = this.state;

        if (mapRef !== null && selectedArea !== null) {
            if (areaPolygon !== null) {
                areaPolygon.setMap(null);
            }
            const polygon = new google.maps.Polygon({
                paths: selectedArea.vertices,
                fillColor: '#f7cf78',
                strokeColor: '#000000',
                fillOpacity: 0.5,
                strokeWeight: 1,
                clickable: false,
                editable: false,
                draggable: false,
            });

            polygon.setMap(mapRef);
            this.setState({ areaPolygon: polygon });
        }
    };

    isPeriodFinished = (selectedDate: moment.Moment, period: ReservationPeriod | ScheduleEntry) => {
        const { selectedArea } = this.state;

        const localMomentWithTimeZoneApplied = selectedArea?.timeZone ? momentTz().tz(selectedArea.timeZone) : momentTz();

        const userDateSelectionToString = selectedDate.clone().format('YYYY-MM-DD');
        const localDateToString = localMomentWithTimeZoneApplied.clone().format('YYYY-MM-DD');

        const endTimeMomentTz = momentTz(period.endTime, 'HH:mm:ss').tz(selectedArea?.timeZone || '');

        return userDateSelectionToString === localDateToString && localMomentWithTimeZoneApplied.isAfter(endTimeMomentTz);
    }

    setAvailableReservationPeriods = (areaReservationPeriods?: ReservationPeriod[]) => {
        const { qrCodeBooking } = this.props;
        const { fields } = this.state;

        if (!areaReservationPeriods) return;

        const selectedDates = fields[ReservationModalField.Days];

        if (selectedDates.length === 0) {
            this.setState({
                selectedPeriod: null,
                reservationPeriods: [],
                schedules: [],
            }, this.refreshMarkers);
            return;
        }

        // duplicates all normal periods for each time they appear on selectedDates so they overlap how many times they appear on selectedDates
        const duplicateAllWeekDayOccurrences = () => {
            const allWeekDays: ReservationPeriod[] = [];

            selectedDates.forEach(date => {
                const periodWeekDay = areaReservationPeriods.filter(period => period.dayOfWeek === Object.values(WeekDays)[date.day()]);
                if (periodWeekDay) {
                    allWeekDays.push(...periodWeekDay);
                }
            });

            return allWeekDays;
        };

        const specialPeriods = areaReservationPeriods.filter((period: ReservationPeriod) => !!period.dayOfYear
            && selectedDates.some(selectedDate => moment(period.dayOfYear, DATE_FORMAT).isSame(selectedDate, 'date')));

        const allCommonPeriodsWithoutOverlapingSpecialDay = duplicateAllWeekDayOccurrences().filter(
            period => period.dayOfWeek && selectedDates.map(date => Object.values(WeekDays)[date.day()]).includes(period.dayOfWeek)
                && !specialPeriods.map(special => Object.values(WeekDays)[moment(special.dayOfYear).day()]).includes(period.dayOfWeek),
        );

        // this contains all periods between first date and last date
        // (if a special day between first and last date, the corresponding common period is not here)
        const allAvailablePeriods = [...specialPeriods, ...allCommonPeriodsWithoutOverlapingSpecialDay];

        const overlappingPeriodsBetweenAllSelectedDays = allAvailablePeriods.filter(period => allAvailablePeriods.filter(
            p2 => p2.startTime === period.startTime && p2.endTime === period.endTime,
        ).length >= selectedDates.length);

        // only index 0 because its protected by multidatepicker element to no pass current day
        const availableReservationPeriods = overlappingPeriodsBetweenAllSelectedDays
            .filter(period => !this.isPeriodFinished(selectedDates[0], period));

        this.setState({
            reservationPeriods: uniqBy(availableReservationPeriods.map((period: ReservationPeriod) => ({
                label: this.getPeriodLabel(period),
                value: String(period.id),
            })), 'label'),
        }, () => {
            if (availableReservationPeriods[0]) {
                this.onPeriodChange(
                    ReservationModalField.Period, String(availableReservationPeriods[0].id), Number(qrCodeBooking?.bollardId),
                );
            }
        });
    }

    selectArea = async (area: IArea) => {
        const { setQrCodeForCheckIn, qrCodeBooking } = this.props;
        const { fields, mapRef } = this.state;

        const areaRes = await getArea(String(area.id));

        if (!areaRes) {
            return;
        }

        this.setState({
            selectedArea: areaRes,
            selectedPeriod: null,
            fields: {
                ...fields,
                [ReservationModalField.Area]: String(area.id),
                [ReservationModalField.Period]: '',
                [ReservationModalField.Bollard]: -1,
            },
        }, () => {
            if (mapRef !== null) mapRef.fitBounds(new google.maps.LatLngBounds(area.boundSW, area.boundNE));

            this.setAvailableReservationPeriods(areaRes.reservationPeriods);

            this.refreshArea();
            this.fetchSchedules();

            if (qrCodeBooking) {
                // remove bollardid to not affect other features
                setQrCodeForCheckIn({ ...qrCodeBooking, bollardId: undefined });
            }
        });
    };

    getMarkerIcon = (schedule: BollardSchedule) => {
        const { fields, selectedPeriod } = this.state;

        if (fields[ReservationModalField.Bollard] === schedule.bollard.id) {
            return picked;
        }

        const entryFound = schedule.scheduleEntries.find(se => selectedPeriod?.endTime === se.endTime && selectedPeriod?.startTime === se.startTime);
        if (entryFound && !entryFound.reserved) {
            return free;
        }

        return taken;
    };

    totalPrice = () => {
        const { schedules, fields, selectedPeriod } = this.state;

        return schedules.find(sc => sc.bollard.id === fields[ReservationModalField.Bollard])?.scheduleEntries.find(
            se => selectedPeriod?.startTime === se.startTime && selectedPeriod?.endTime === se.endTime,
        )?.price || 0;
    }

    refreshMarkers = () => {
        const {
            mapRef, bollardMarkers, schedules, fields,
        } = this.state;

        if (mapRef !== null) {
            bollardMarkers.forEach(marker => marker.setMap(null));
            const markers: google.maps.Marker[] = [];

            schedules.forEach(schedule => {
                const marker = customMarker(
                    mapRef,
                    { lat: schedule.bollard.location.lat, lng: schedule.bollard.location.lng },
                    this.getMarkerIcon(schedule),
                    undefined,
                    fields[ReservationModalField.Bollard] === schedule.bollard.id ? undefined : schedule.bollard.designation,
                );
                marker.setClickable(true);
                marker.addListener('click', () => this.onMarkerClick(schedule));
                markers.push(marker);
            });
            this.setState({ bollardMarkers: markers });
        }
    };

    validateStep = (nextStep: () => void) => {
        const { stepReservation, fields } = this.state;

        switch (stepReservation) {
            case StepReservation.CHOOSE_LOCAL:
            case StepReservation.LOGIN:
                if (validateForm(fields, validations.bookingCreateChooseLocal) === null) {
                    nextStep();
                }
                break;
            default:
                break;
        }
    }

    reservationNextStepAfterAuthenticatedUser = () => {
        const { business } = this.props;
        const { selectedPeriod } = this.state;

        if (business?.euPagoIntegrated && selectedPeriod && selectedPeriod.price > 0) {
            this.validateStep(() => this.setState({ stepReservation: StepReservation.CHOOSE_PAYMENT }));
        } else {
            this.onFormSubmit(PaymentMethods.NONE);
        }
    };

    renderBigAreaDescription = () => {
        const { selectedArea } = this.state;

        return (
            <div className="reservation-dialog__big-area-description" data-testid="big-area-description">
                {selectedArea?.description}
            </div>
        );
    }

    renderSmallAreaDescription = () => {
        const { t } = this.props;
        const { selectedArea } = this.state;

        if (!selectedArea?.description) return;

        let { description } = selectedArea;
        let showShowMoreBtn = false;
        const descriptionParts = selectedArea.description.split('\n');
        const jointDescriptionParts = `${descriptionParts.slice(0, 4).join('\n')}...`;
        if (descriptionParts.length > 3 && jointDescriptionParts.length < 230) {
            showShowMoreBtn = true;
            description = jointDescriptionParts;
        } else if (selectedArea.description.length > 233) {
            showShowMoreBtn = true;
            description = `${selectedArea.description.substr(0, 230)}...`;
        }

        return (
            <div>
                <div className="reservation-dialog__spacing__area-description">
                    <p>{description}</p>
                    {showShowMoreBtn && (
                        <button type="button"
                                onClick={() => this.setState({ stepReservation: StepReservation.AREA_DESCRIPTION })}>
                            {t('reservationModal.showMore')}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    renderChooseLocal = () => {
        const { t, business, bookingFetching } = this.props;
        const {
            fields, selectedArea, areas, errors, reservationPeriods,
        } = this.state;

        return (
            <React.Fragment>
                <div className="reservation-dialog__spacing">
                    <div className="reservation-dialog__spacing__photos-grid-container">
                        {areas.map(area => (
                            <div
                                className={`reservation-dialog__spacing__photos-grid-container__photo-container ${area.id === selectedArea?.id
                                    ? 'reservation-dialog__spacing__photos-grid-container__photo-container--selected'
                                    : ''
                                }`}
                                key={area.id}
                                style={{ background: `url(${area.photo})` }}
                                onClick={() => this.selectArea(area)}
                                data-testid="area-image"
                            >
                                <div
                                    className="reservation-dialog__spacing__photos-grid-container__photo-container__text-container"
                                >
                                    <span>{area.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    {selectedArea && this.renderSmallAreaDescription()}
                    <div className="wide-form__grid-container">
                        <div className="wide-form__grid-container__row">
                            <div className="wide-form__grid-container__row__input-container">
                                <div className="wide-form__grid-container__row__input-container__label">
                                    <PeopleIcon />
                                    <span>{t('reservationModal.pax')}</span>
                                </div>
                                <FormTextField
                                    name={ReservationModalField.Pax}
                                    value={fields[ReservationModalField.Pax]}
                                    onChange={this.onFieldChange}
                                    errors={errors}
                                    disabled={bookingFetching || selectedArea === null}
                                    testId="pax-input"
                                />
                            </div>
                        </div>
                        <div className="wide-form__grid-container__row">
                            <div className="wide-form__grid-container__row__input-container">
                                <div className="wide-form__grid-container__row__input-container__label">
                                    <CalendarIcon />
                                    <span>{t('reservationModal.day.label')}</span>
                                </div>
                                <FormTextField
                                    name={ReservationModalField.Days}
                                    value={calendarMultiDayRepresentation(t, fields[ReservationModalField.Days])}
                                    onChange={this.onFieldChange}
                                    errors={errors}
                                    disabled={bookingFetching || selectedArea === null}
                                    icon={<CalendarIcon />}
                                    showButton
                                    onInputClick={() => this.setState({ showDatePicker: true })}
                                    onClick={() => this.setState({ showDatePicker: true })}
                                    testId="date-input"
                                    errorMessageOverride={{
                                        typeOfViolation: 'Size',
                                        message: 'errors.maxReservations',
                                    }}
                                />
                            </div>
                            <div className="wide-form__grid-container__row__input-container">
                                <div className="wide-form__grid-container__row__input-container__label">
                                    <TimeIcon />
                                    <span>{t('reservationModal.period')}</span>
                                </div>
                                <FormSelectField
                                    name={ReservationModalField.Period}
                                    onChange={this.onPeriodChange}
                                    options={reservationPeriods}
                                    value={fields[ReservationModalField.Period]}
                                    errors={errors}
                                    disabled={bookingFetching || selectedArea === null || reservationPeriods.length === 0}
                                    testId="period-select"
                                />
                            </div>
                        </div>
                        <div className="wide-form__grid-container__row__input-container--multiline">
                            <div className="wide-form__grid-container__row__input-container__label">
                                <Create />
                                <span>{t('reservationModal.observations')}</span>
                            </div>
                            <FormTextField
                                name={ReservationModalField.Observations}
                                value={fields[ReservationModalField.Observations]}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 600 }}
                                onChange={this.onFieldChange}
                                errors={errors}
                                disabled={bookingFetching || selectedArea === null}
                                testId="observations-input"
                            />
                        </div>
                    </div>
                    {reservationPeriods.length === 0
                        && <div className="no-periods-available">{t('reservationModal.noPeriods')}</div>}
                    {
                        reservationPeriods.length !== 0 && (
                            <div className="reservation-dialog__map-header">
                                <span className="reservation-dialog__map-header__title">
                                    {t('reservationModal.map')}
                                </span>
                            </div>
                        )
                    }
                </div>
                {
                    business !== null && (
                        <Map
                            zoom={20}
                            height="300px"
                            center={{ lat: business.address.lat, lng: business.address.lng }}
                            onMapMounted={this.onMapMounted}
                        />
                    )
                }
            </React.Fragment>
        );
    }

    renderTimeInfoMessage = (PaymentMethodType: PaymentMethods) => {
        const { t } = this.props;

        switch (PaymentMethodType) {
            case PaymentMethods.MB_WAY:
                return <span>{t('reservationModal.MBWayPaymentTimeInfo')}</span>;
            default:
                return <span>{t('reservationModal.MultibancoPaymentTimeInfo')}</span>;
        }
    }

    renderPriceInfo = (renderTimeInfo = true, renderButton = false, method: PaymentMethods = PaymentMethods.ATM_REFERENCE) => {
        const { t, language } = this.props;

        return (
            <div className="reservation-dialog__price-container">
                <div className="reservation-dialog__price-container__price">
                    <hr />
                    <div className="reservation-dialog__price-container__price__next-step">
                        <div>
                            <div className="reservation-dialog__payment-details__label">
                                {t('reservationModal.price')}
                            </div>
                            <span className="reservation-dialog__payment-details__value">
                                {numberToCurrency(this.totalPrice(), language)}
                            </span>
                        </div>
                        {
                            renderButton && (
                                <img
                                    src={nextIcon}
                                    alt="next step icon"
                                    className="clickable"
                                    onClick={() => this.onFormSubmit(PaymentMethods.CREDIT_CARD)}
                                />
                            )
                        }
                    </div>
                </div>
                {renderTimeInfo && (
                    <div className="reservation-dialog__price-container__message">
                        <div className="wide-form__grid-container__row__input-container__label
                            wide-form__grid-container__row__input-container__label--footer-description"
                        >
                            <TimeIcon />
                            {this.renderTimeInfoMessage(method)}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderATMReferenceInfo = () => {
        const { t } = this.props;
        const { reservationCreated } = this.state;

        return (
            <React.Fragment>
                <div className="payment-methods">
                    <div
                        className="reservation-dialog__payment-details__label reservation-dialog__payment-details__label--center-vertical"
                    >
                        {t('reservationModal.payment.entity')}
                    </div>
                    <div className="reservation-dialog__payment-details__value">
                        {reservationCreated ? reservationCreated.atmEntity : ''}
                    </div>
                    <div
                        className="reservation-dialog__payment-details__label reservation-dialog__payment-details__label--below-another"
                    >
                        {t('reservationModal.payment.reference')}
                    </div>
                    <div className="reservation-dialog__payment-details__value">
                        {reservationCreated ? reservationCreated.reference : ''}
                    </div>
                </div>
                {this.renderPriceInfo()}
            </React.Fragment>
        );
    }

    renderCreditCard = () => {
        const { t } = this.props;
        const { fields } = this.state;

        return (
            <React.Fragment>
                <div className="payment-methods">
                    <div className="payment-methods__credit-card">
                        <span>{t('reservationModal.payment.creditCard.number')}</span>
                        <FormTextField
                            name={ReservationModalField.CardNumber}
                            value={fields[ReservationModalField.CardNumber]}
                            onChange={this.onFieldChange}
                            errors={null}
                            testId="card-number"
                        />
                        <div className="payment-methods__credit-card__half-fields">
                            <div>
                                <span>{t('reservationModal.payment.creditCard.expiry')}</span>
                                <FormTextField
                                    name={ReservationModalField.Validity}
                                    value={fields[ReservationModalField.Validity]}
                                    onChange={this.onFieldChange}
                                    errors={null}
                                    testId="validity"
                                    placeholder="MM/AA"
                                />
                            </div>
                            <div>
                                <span>{t('reservationModal.payment.creditCard.code')}</span>
                                <FormTextField
                                    name={ReservationModalField.CCV}
                                    value={fields[ReservationModalField.CCV]}
                                    onChange={this.onFieldChange}
                                    errors={null}
                                    testId="ccv"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderPriceInfo(false, true)}
            </React.Fragment>
        );
    }

    renderMbwayInfo = () => {
        const { errors } = this.state;
        return (
            <div className="reservation-dialog__payment-details">
                <MBWayField onSave={phoneNumber => this.onMBWaySave(phoneNumber)} errors={errors} />
                {this.renderPriceInfo(true, false, PaymentMethods.MB_WAY)}
            </div>
        );
    }

    renderMbwayTimer = () => {
        const { t } = this.props;
        const { mbWayTimer } = this.state;

        return (
            <div className="reservation-dialog__mbway-timer">
                <TimeIcon />
                <span
                    className="reservation-dialog__mbway-timer__text"
                >{t('reservationModal.pleaseValidateMBWayPayment')}
                </span>
                <h2 className="reservation-dialog__mbway-timer__timer">{mbWayTimer}</h2>
                <span className="reservation-dialog__mbway-timer__text">{t('reservationModal.onYourMBwayApp')}</span>
            </div>
        );
    }

    renderSuccessReservation = () => {
        const { t } = this.props;
        return (
            <div className="reservation-dialog__success">
                <span className="reservation-dialog__success__title">
                    {t('reservationModal.success.title')}
                </span>
                <span className="reservation-dialog__success__message">
                    {t('reservationModal.success.message')}
                </span>
                <img className="reservation-dialog__success__image" src={picnic} alt="success reservation" />
            </div>
        );
    }

    renderErrorReservation = () => {
        const { t } = this.props;
        return (
            <div className="reservation-dialog__error">
                <span className="reservation-dialog__error__title">
                    {t('reservationModal.error.title')}
                </span>
                <span className="reservation-dialog__error__message">
                    {t('reservationModal.error.message')}
                </span>
                <img className="reservation-dialog__error__image" src={picnic} alt="error on reservation" />
            </div>
        );
    }

    renderPaymentPicker = () => {
        const getStepReservation = (paymentMethod: PaymentMethods): number => {
            switch (paymentMethod) {
                case PaymentMethods.CREDIT_CARD:
                    return StepReservation.CREDIT_CARD_INFO;
                case PaymentMethods.ATM_REFERENCE:
                    this.onFormSubmit(paymentMethod);
                    return StepReservation.ATM_REFERENCE_INFO;
                case PaymentMethods.MB_WAY:
                    return StepReservation.MBWAY_INFO;
                default:
                    return StepReservation.CHOOSE_LOCAL;
            }
        };

        return (
            <PaymentMethodsPicker
                onSelectPayment={paymentMethod => this.setState({ stepReservation: getStepReservation(paymentMethod) })}
            />
        );
    }

    renderLogin = () => {
        const { t, history, location } = this.props;

        return (
            <div className="reservation-dialog__spacing--login">
                <h2 className="reservation-dialog__business-container__business__top__name--yellow">
                    {t('reservationModal.LoginToReserveYourSpot')}
                </h2>
                <LoginForm isWelcomeScreen={false} onLoginSuccess={this.reservationNextStepAfterAuthenticatedUser} />
                <h3>{t('welcomeScreen.notRegistered')}</h3>
                <Button
                    onClick={() => history.push({
                        pathname: AppRoute.Welcome,
                        state: { from: location.pathname },
                    })}
                    data-testid="redirect-btn"
                >
                    {t('welcomeScreen.createAccount')}
                </Button>
            </div>
        );
    }

    renderSections = () => {
        const { stepReservation } = this.state;

        switch (stepReservation) {
            case StepReservation.CHOOSE_LOCAL:
                return this.renderChooseLocal();
            case StepReservation.AREA_DESCRIPTION:
                return this.renderBigAreaDescription();
            case StepReservation.CHOOSE_PAYMENT:
                return this.renderPaymentPicker();
            case StepReservation.ATM_REFERENCE_INFO:
                return this.renderATMReferenceInfo();
            case StepReservation.CREDIT_CARD_INFO:
                return this.renderCreditCard();
            case StepReservation.MBWAY_INFO:
                return this.renderMbwayInfo();
            case StepReservation.SUCCESS:
                return this.renderSuccessReservation();
            case StepReservation.ERROR:
                return this.renderErrorReservation();
            case StepReservation.MBWAY_TIMER:
                return this.renderMbwayTimer();
            case StepReservation.LOGIN:
                return this.renderLogin();
            default:
                return <React.Fragment />;
        }
    }

    renderLabelSections = () => {
        const { t } = this.props;
        const { stepReservation } = this.state;

        switch (stepReservation) {
            case StepReservation.CHOOSE_PAYMENT:
                return t('reservationModal.header.choosePayment');
            case StepReservation.ATM_REFERENCE_INFO:
                return t('reservationModal.header.atmReference');
            case StepReservation.CREDIT_CARD_INFO:
                return t('reservationModal.header.creditCard');
            case StepReservation.MBWAY_INFO:
                return t('reservationModal.header.mbway');
            default:
                return '';
        }
    }

    renderPaymentMethodBadge = (): React.ReactNode => {
        const { stepReservation } = this.state;
        const drawCircle = (imgSrc: string, customImage = false) => {
            return (
                <div className="reservation-dialog__business-container__header__payment-badger">
                    <img className={customImage ? 'custom-image' : ''} src={imgSrc} alt="payment badge" />
                </div>
            );
        };
        switch (stepReservation) {
            case StepReservation.CREDIT_CARD_INFO:
                return drawCircle(creditCardLogo, true);
            case StepReservation.MBWAY_INFO:
                return drawCircle(mbwayLogo);
            case StepReservation.ATM_REFERENCE_INFO:
                return drawCircle(atmReferenceLogo);
            default:
                return <React.Fragment />;
        }
    }

    renderBottomButton = () => {
        const {
            t, bookingFetching, language, authenticatedUser,
        } = this.props;
        const {
            stepReservation,
            selectedPeriod,
            reservationPeriods,
            fields,
        } = this.state;

        switch (stepReservation) {
            case StepReservation.CHOOSE_LOCAL: {
                if (fields[ReservationModalField.Bollard] < 0) {
                    return t('reservationModal.pleaseSelectASpot');
                }

                const disabled = bookingFetching || reservationPeriods.length === 0;

                return (
                    <Button
                        className={`reservation-dialog__footer__button ${disabled ? 'disabled' : ''}`}
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={disabled}
                        data-testid="submit-button"
                        onClick={authenticatedUser ? this.reservationNextStepAfterAuthenticatedUser : () => this.setState({ stepReservation: StepReservation.LOGIN })}
                    >
                        <span
                            className="reservation-dialog__footer__button__text reservation-dialog__footer__button__text--secondary"
                        >
                            {`${t('reservationModal.reserve')} ${selectedPeriod !== null ? t('reservationModal.for') : ''}`}
                        </span>
                        <span className="reservation-dialog__footer__button__text">
                                &nbsp;
                            {selectedPeriod === null ? '' : numberToCurrency(this.totalPrice(), language)}
                        </span>
                        <img src={arrowLeft} alt="next step icon" />
                    </Button>
                );
            }
            case StepReservation.ATM_REFERENCE_INFO:
            case StepReservation.SUCCESS:
                return (
                    <Button
                        className="reservation-dialog__footer__button"
                        variant="contained"
                        color="primary"
                        type="button"
                        data-testid="submit-button"
                        onClick={this.onBack}
                    >
                        <span className="reservation-dialog__footer__button__text">
                            {t('reservationModal.end')}
                        </span>
                        <img src={arrowLeft} alt="next step icon" />
                    </Button>
                );
            default:
                return <React.Fragment />;
        }
    }

    renderPriceValueOrFree = () => {
        const { t } = this.props;
        const finalValue = Number(this.totalPrice());
        if (finalValue >= 1) {
            return `${finalValue} ${(finalValue > 1)
                ? t('reservationModal.euros')
                : t('reservationModal.euro')}`;
        }
        return t('reservationModal.free');
    };

    renderBusinessInfoAndChooseLocalAndPeriodFields = () => {
        const { t, business } = this.props;
        const { stepReservation, fields, selectedPeriod } = this.state;

        return (
            <div className="reservation-dialog__business-container">
                <div className="reservation-dialog__business-container__business">
                    <div className="reservation-dialog__business-container__business__top">
                        <span className="reservation-dialog__business-container__business__top__name">
                            {business?.name}
                        </span>
                        {
                            stepReservation !== StepReservation.CHOOSE_LOCAL && (
                                <span
                                    className="reservation-dialog__business-container__business__footer__location"
                                >
                                    <PeopleAltOutlinedIcon />
                                    {t('reservationModal.totalPax', { total: fields[ReservationModalField.Pax] })}
                                </span>
                            )
                        }
                    </div>
                    <span className="reservation-dialog__business-container__business__location">
                        <PinIcon />
                        {business?.address.street}, {business?.address.city}
                    </span>
                    <span className="reservation-dialog__business-container__business__location">
                        <img src={phone} alt="phone number icon" />
                        {business?.phoneNumber}
                    </span>
                    {
                        stepReservation !== StepReservation.CHOOSE_LOCAL && (
                            <>
                                <div className="reservation-dialog__business-container__business__footer">
                                    <span
                                        className="reservation-dialog__business-container__business__footer__location"
                                    >
                                        <CalendarIcon />
                                        {moment.utc(fields[ReservationModalField.Days][0]).local().format('ddd, D MMM YYYY')} {` / ${this.renderPriceValueOrFree()} `}
                                    </span>
                                    <span
                                        className="reservation-dialog__business-container__business__footer__location"
                                    >
                                        <TimeIcon />
                                        {`${formatHourTime(selectedPeriod?.startTime || '')} - ${formatHourTime(selectedPeriod?.endTime || '')}`}
                                    </span>
                                </div>
                                <div className="wide-form__grid-container__row__input-container--multiline">
                                    <div className="wide-form__grid-container__row__input-container__label">
                                        <Create />
                                        <span>{t('reservationModal.observations')}</span>
                                    </div>
                                    <FormTextField
                                        name={ReservationModalField.Observations}
                                        className="black-text"
                                        value={fields[ReservationModalField.Observations]}
                                        multiline
                                        errors={[]}
                                        rows={4}
                                        disabled
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
                <div />
            </div>
        );
    };

    render() {
        const { t, business, bookingFetching } = this.props;
        const {
            stepReservation, fields, showDatePicker, selectedArea,
        } = this.state;

        return (
            <Dialog
                open={business !== null}
                onClose={this.onBack}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="reservation-dialog fullscreen-modal"
                TransitionComponent={Transition}
            >
                <DialogTitle className="fullscreen-modal__header" itemType="div" disableTypography>
                    <BackIcon
                        className="fullscreen-modal__header-action"
                        onClick={this.onBack}
                        data-testid="modal-close"
                    />
                    <Typography component="h2" variant="h6">
                        {selectedArea && stepReservation === StepReservation.AREA_DESCRIPTION ? selectedArea.name : t('reservationModal.title')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="reservation-dialog__spacing reservation-dialog__spacing--margin-top">
                        <Backdrop className="loading" open={bookingFetching}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {stepReservation !== StepReservation.AREA_DESCRIPTION && this.renderBusinessInfoAndChooseLocalAndPeriodFields()}
                    </div>
                    {
                        stepReservation !== StepReservation.CHOOSE_LOCAL
                        && stepReservation !== StepReservation.AREA_DESCRIPTION
                        && stepReservation !== StepReservation.LOGIN && (
                            <div
                                className={`reservation-dialog__business-container__header 
                                    ${stepReservation === StepReservation.ERROR
                                    ? 'reservation-dialog__business-container__header--error' : ''}
                                `}
                            >
                                {this.renderPaymentMethodBadge()}
                                <span>{this.renderLabelSections()}</span>
                            </div>
                        )
                    }
                    {this.renderSections()}
                    <MultipleDatePicker
                        onClose={() => this.setState({ showDatePicker: false })}
                        onSubmit={sortedSelectedDays => this.onSelectDays(sortedSelectedDays)}
                        open={showDatePicker}
                        preSelectedDates={fields[ReservationModalField.Days]}
                    />
                </DialogContent>
                <DialogActions className="reservation-dialog__footer">
                    {this.renderBottomButton()}
                </DialogActions>
            </Dialog>

        );
    }
}

type Props = OwnProps & RouteComponentProps & AuthenticationContext;

export default withTranslationContext(withAuthenticationContext(withBookingsContext(withRouter(ReservationModal))));
