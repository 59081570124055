/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { store, ReactNotificationOptions } from 'react-notifications-component';
import 'animate.css/animate.css';
import 'react-notifications-component/dist/theme.css';

import Notification from '../components/elements/Notification';
import { generateId } from './misc';

/**
 * NotificationType
 * @enum {string}
 */
export enum NotificationType {
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
}

/**
 * @typedef {Object} NotificationData
 * @property {string} [title]
 * @property {string} [message]
 * @property {NotificationType} [type]
 * @property {string} [date]
 * @property {number} [duration]
 * @property {JSX.Element} [content]
 */
export interface NotificationData {
    title?: string;
    message?: string;
    type?: NotificationType;
    date?: string;
    duration?: number;
    content?: JSX.Element;
}

/**
 * @typedef {Object} ErrorData
 * @property {string} [message]
 */
export interface ErrorData {
    message?: string;
}

/**
 * @typedef {Object} SuccessData
 * @property {string} [message]
 */
export interface SuccessData {
    message?: string;
}

/**
 * checks if the notification exists and changes a parent class accordingly
 */
const checkNotifications = () => {
    const container = document.querySelector('.notification-container-bottom-right');
    if (container) {
        setTimeout(() => {
            const count = container.children.length;
            const root = document.getElementById('root');
            if (root) {
                if (count > 0) root.classList.add('has-notifs');
                else if (count === 0) root.classList.remove('has-notifs');
            }
        }, 100);
    }
};

/**
 * removes a notification
 * @param {string} [id]
 */
export const removeNotification = (id: string) => {
    store.removeNotification(id);
};

/**
 * displays a custom notification
 * @param {NotificationData} data
 * @param {string} [id]
 */
export const displayNotification = (data: NotificationData, id?: string) => {
    const notificationId = id || generateId();
    const duration = data.duration !== undefined && data.duration >= 0 ? data.duration : 5000;
    const content = data.content ? data.content : <Notification data={data} id={notificationId} />;

    const notification: ReactNotificationOptions = {
        id: notificationId,
        content,
        container: 'bottom-right',
        animationIn: ['animated'],
        animationOut: ['animated'],
        slidingEnter: {
            duration: 10,
        },
        slidingExit: {
            duration: 10,
        },
        dismiss: {
            duration,
        },
        onRemoval: checkNotifications,
    };

    store.addNotification(notification);

    checkNotifications();
};

/**
 * displays an error notification
 * @param {ErrorData} data
 */
export const displayError = (data: ErrorData) => {
    const message = data && data.message ? data.message : 'Unknown Error';

    displayNotification({
        type: NotificationType.Danger,
        message,
    });
};

/**
 * displays a success notification
 * @param {SuccessData} data
 */
export const displaySuccess = (data: SuccessData) => {
    const message = data && data.message ? data.message : '';

    displayNotification({
        type: NotificationType.Success,
        message,
    });
};
