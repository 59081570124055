/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import IconUserAvatar from '../assets/IconUserAvatar';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AppRoute } from '../../types/routes';

interface OwnProps extends AuthenticationContext, TranslationContext {}

const UserMenu: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, isAuthenticated } = props;

    if (isAuthenticated) return null;

    return (
        <div className="user-menu">
            <NavLink className="user-menu__user-info" to={AppRoute.Welcome}>
                <div className="avatar-placeholder">
                    <IconUserAvatar />
                </div>
                <p data-testid="login-signup-link">
                    {t('userMenu.loginSignUp')}
                </p>
            </NavLink>
        </div>
    );
};

export default withAuthenticationContext(withTranslationContext(UserMenu));
