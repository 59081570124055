/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum InvitationUserStatus {
    AccountCreated = 'ACCOUNT_CREATED',
    NoAccount = 'NO_ACCOUNT',
}
