/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import logo from '../../assets/images/logo-mixed.svg';
import ProfileModal from './ProfileModal';
import { ProfileModalMode } from '../../types/profile';
import LanguageSelect from '../elements/LanguageSelect';
import { AppRoute } from '../../types/routes';

interface OwnProps extends RouteComponentProps, AuthenticationContext, TranslationContext {}

interface OwnState {
    mobileExpanded: boolean;
    profileModalOpen: boolean;
    profileModalMode: ProfileModalMode;
}

const initialState: OwnState = {
    mobileExpanded: false,
    profileModalOpen: false,
    profileModalMode: ProfileModalMode.Info,
};

class Drawer extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const {
            authenticatedUser, submitLogout, isAuthenticated, t, history,
        } = this.props;
        const { mobileExpanded, profileModalMode, profileModalOpen } = this.state;

        const year = moment().format('yyyy');

        let shouldRender = true;
        const exclusions = [AppRoute.Landing, AppRoute.AcceptInvite];
        exclusions.forEach(e => {
            if (matchPath(history.location.pathname, e)) {
                shouldRender = false;
            }
        });

        if (!shouldRender) {
            return <React.Fragment />;
        }

        return (
            <>
                {isAuthenticated ? (
                    <div data-testid="sideBar">
                        <ProfileModal
                            open={profileModalOpen}
                            mode={profileModalMode}
                            close={() => this.setState({ profileModalOpen: false })}
                        />
                        <MenuIcon
                            className="drawer__mobile-toggle"
                            onClick={() => this.setState({ mobileExpanded: !mobileExpanded })}
                            data-testid="menu-button"
                        />
                        <div className={`drawer ${mobileExpanded ? 'drawer--expanded' : ''}`}>
                            <div className="drawer__top-container">
                                <CloseIcon
                                    className="drawer__close-icon"
                                    onClick={() => this.setState({ mobileExpanded: !mobileExpanded })}
                                    data-testid="close-button"
                                />
                                <div className="user-info">
                                    <img
                                        src={`${authenticatedUser?.avatar}?${authenticatedUser?.lastUpdate}`}
                                        alt="avatar"
                                    />
                                    <div className="user-info__text-container">
                                        <span>{`${authenticatedUser?.firstName} ${authenticatedUser?.lastName}`}</span>
                                        <span>{authenticatedUser?.email}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="drawer__middle-container">
                                <button
                                    type="button"
                                    onClick={
                                        () => this.setState({
                                            mobileExpanded: false,
                                            profileModalMode: ProfileModalMode.Info,
                                            profileModalOpen: true,
                                        })
                                    }
                                    data-testid="edit-profile-btn"
                                >
                                    {t('drawer.profile')}
                                </button>
                                <button
                                    type="button"
                                    onClick={
                                        () => this.setState({
                                            mobileExpanded: false,
                                            profileModalMode: ProfileModalMode.Password,
                                            profileModalOpen: true,
                                        })
                                    }
                                    data-testid="change-password-btn"
                                >
                                    {t('drawer.password')}
                                </button>
                                <button
                                    type="button"
                                    onClick={
                                        () => {
                                            this.setState({ mobileExpanded: false });
                                            submitLogout();
                                        }
                                    }
                                    data-testid="logout-btn"
                                >
                                    {t('drawer.logout')}
                                </button>
                            </div>

                            <div className="drawer__bottom-container">
                                <LanguageSelect />
                                <a href="https://boser.pt/?modal=privacy" target="_blank" rel="noopener noreferrer">
                                    {t('drawer.privacyPolicy')}
                                </a>
                                <a href="https://boser.pt/?modal=terms" target="_blank" rel="noopener noreferrer">
                                    {t('drawer.termsConditions')}
                                </a>
                                <a href="https://boser.pt/#contact" target="_blank" rel="noopener noreferrer">
                                    {t('drawer.contactUs')}
                                </a>
                                <img src={logo} alt="boser" />
                                <span>{`© ${year} ${t('drawer.copyright')}`}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <LanguageSelect overlay />
                )}
            </>
        );
    }
}

export default withRouter(withAuthenticationContext(withTranslationContext(Drawer)));
