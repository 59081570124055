/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import IconLock from '@material-ui/icons/LockOutlined';

import FormPasswordField from '../elements/FormPasswordField';
import { KeyedObject } from '../../types/general';
import { AppRoute } from '../../types/routes';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure } from '../../utils/validations';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import IconEyeClosed from '../assets/IconEyeClosed';
import IconEye from '../assets/IconEye';

enum ResetFormField {
    Password = 'newPassword',
    RepeatPassword = 'repeatPassword',
}

export interface ResetFormFields {
    [ResetFormField.Password]: string;
    [ResetFormField.RepeatPassword]: string;
}

interface MatchParams {
    token?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps<MatchParams> {}

interface OwnState {
    fields: ResetFormFields;
    errors: KeyedObject | null;
}

const initialState: OwnState = {
    fields: {
        [ResetFormField.Password]: '',
        [ResetFormField.RepeatPassword]: '',
    },
    errors: null,
};

class RecoverScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const {
            match: {
                params: { token },
            },
            submitResetPass,
            validateResetPass,
        } = this.props;
        const { fields } = this.state;

        const errors = validateResetPass(fields);

        this.setState({
            errors,
        });

        if (!errors) {
            submitResetPass(
                {
                    newPassword: fields[ResetFormField.Password],
                    token: token || '',
                },
                this.onFormSubmitSuccess,
                this.onFormSubmitFailure,
            );
        }
    };

    onFormSubmitSuccess = () => {
        const { history, t } = this.props;

        displayNotification({
            message: t('resetScreen.success'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Welcome);
    };

    onFormSubmitFailure = () => {
        const { t, resetPassErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, resetPassErrors) });
    };

    render() {
        const { t } = this.props;
        const { fields, errors } = this.state;
        return (
            <form className="narrow-form" onSubmit={this.onFormSubmit}>
                <FormPasswordField
                    name={ResetFormField.Password}
                    value={fields[ResetFormField.Password]}
                    onChange={this.onInputChange}
                    placeholder={t('resetScreen.passwordLabel')}
                    errors={errors}
                    showButton
                    startAdornment={<IconLock />}
                    icon={<IconEye />}
                    iconToggled={<IconEyeClosed />}
                />
                <FormPasswordField
                    name={ResetFormField.RepeatPassword}
                    value={fields[ResetFormField.RepeatPassword]}
                    onChange={this.onInputChange}
                    placeholder={t('resetScreen.repeatPasswordLabel')}
                    errors={errors}
                    showButton
                    startAdornment={<IconLock />}
                    icon={<IconEye />}
                    iconToggled={<IconEyeClosed />}
                />
                <Button className="white-button recover-button" variant="contained" color="primary" type="submit">
                    {t('resetScreen.send')}
                </Button>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(RecoverScreen)));
