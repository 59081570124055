/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ValidationType } from '../utils/validations';

const bookingValidations = {
    reservationPeriodId: {
        validations: [ValidationType.NotBlank],
    },
    bollardId: {
        validations: [ValidationType.NotBlank],
    },
};

export const validations = {
    login: {
        email: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
    },
    registration: {
        email: {
            validations: [ValidationType.NotBlank],
        },
        firstName: {
            validations: [ValidationType.NotBlank],
        },
        lastName: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
        terms: {
            validations: [ValidationType.Checked],
        },
    },
    recover: {
        email: {
            validations: [ValidationType.NotBlank],
        },
    },
    reset: {
        newPassword: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
    },
    profile: {
        firstName: {
            validations: [ValidationType.NotBlank],
        },
        lastName: {
            validations: [ValidationType.NotBlank],
        },
    },
    profilePassword: {
        oldPassword: {
            validations: [ValidationType.NotBlank],
        },
        newPassword: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
    },
    bookingCreate: bookingValidations,
    bookingCreateChooseLocal: {
        days: {
            validations: [ValidationType.Max],
            max: 90,
        },
    },
    activateAccount: {
        token: {
            validations: [ValidationType.NotBlank],
        },
    },
};
