/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

import { PreferencesContextProvider } from './PreferencesContext';
import { Language } from '../../types/preferences';
import { AppState } from '../../reducers/types';
import { languageSetActionCreator } from '../../actions/preferences';

interface StateProps {
    language: Language;
}

interface DispatchProps {
    dispatchChangeLanguage: Function;
}

interface OwnProps extends StateProps {
    children: any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {}

type State = OwnState;

export class PreferencesController extends Component<Props, State> {
    changeLanguage = (language: Language): void => {
        const { dispatchChangeLanguage } = this.props;

        dispatchChangeLanguage(language);
    };

    render() {
        const { children, language } = this.props;

        return (
            <PreferencesContextProvider
                value={{
                    language: language,
                    changeLanguage: this.changeLanguage,
                }}
            >
                {children}
            </PreferencesContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchChangeLanguage: (language: Language) => dispatch(languageSetActionCreator(language)),
});

export const ConnectedPreferencesController = connect(mapStateToProps, mapDispatchToProps)(PreferencesController);
