/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

export default function Loader() {
    return (
        <div className="spinner" data-testid="loader">
            <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}
