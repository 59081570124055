/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import Slide, { SlideProps } from '@material-ui/core/Slide';

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default Transition;
