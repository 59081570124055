/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedTranslationController } from './TranslationController';
import { Language } from '../../types/preferences';

export interface TranslationContext {
    language: Language;
    languages: Array<Language>;
    loadLanguages(): Promise<any>;
    t(literal: string, params?: object): string;
}

export const translationContextDefaultValue = {
    language: Language.EN,
    languages: [Language.EN],
    loadLanguages: () => {
        return new Promise(() => {});
    },
    t: () => '',
};

const TranslationContext = createContext<TranslationContext | null>(translationContextDefaultValue);

export const TranslationContextProvider = TranslationContext.Provider;
export const TranslationContextConsumer = TranslationContext.Consumer;

export const withTranslationContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof TranslationContext>> => (props) => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</TranslationContextConsumer>
    </ConnectedTranslationController>
);
