/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';

import { Booking } from '../../types/bookings';
import ConfirmationDialog from '../elements/ConfirmationDialog';
import checkout from '../../assets/images/checkout_white.svg';

interface OwnProps {
    booking?: Booking;
    onAccept: () => void;
    close: () => void;
}

const CheckoutModal: FC<OwnProps> = (props: OwnProps) => {
    const { booking, onAccept, close } = props;

    return (
        <ConfirmationDialog
            open={!!booking}
            close={close}
            onAccept={onAccept}
            icon={checkout}
            title="checkout.title"
            text="checkout.text"
            actionLabel="checkout.checkout"
        />
    );
};

export default CheckoutModal;
