/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Language } from '../types/preferences';
import { PreferencesAction, PreferencesActionTypes } from './preferences_types';

export const languageSetActionCreator = (payload: Language): PreferencesActionTypes => {
    return {
        type: PreferencesAction.LANGUAGE_SET,
        payload: payload,
    };
};
