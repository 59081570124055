/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { PaymentMethods } from '../../types/general';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from './FormTextField';
import mbwayLogo from '../../assets/images/mbway-logo.png';
import referenceLogo from '../../assets/images/reference-logo.png';
import creditCardLogo from '../../assets/images/credit-card.svg';
import { SHOW_PAYMENT_NIF } from '../../utils/constants';

interface OwnProps {
    onSelectPayment: (payment: PaymentMethods, nif: string) => void;
}

type Props = OwnProps & TranslationContext;

export const PaymentMethodsPicker:React.FC<OwnProps> = withTranslationContext((props: Props) => {
    const { t, onSelectPayment } = props;
    const [nif, setNif] = React.useState('');

    const getPaymentImage = (method: PaymentMethods): string => {
        switch (method) {
            case PaymentMethods.MB_WAY: return mbwayLogo;
            case PaymentMethods.ATM_REFERENCE: return referenceLogo;
            case PaymentMethods.CREDIT_CARD: return creditCardLogo;
            default: return '';
        }
    };

    const customImage = (method: PaymentMethods): boolean => {
        return method === PaymentMethods.CREDIT_CARD;
    };
    
    return (
        <div className="payment-methods">
            {
                Object.values(PaymentMethods).filter(pay => pay !== PaymentMethods.NONE && pay !== PaymentMethods.CREDIT_CARD).map((method: PaymentMethods) => {
                    const paymentMethodLabel = t(`reservationModal.paymentMethods.${method}`);
                    return (
                        <div key={method} className="payment-methods__method" onClick={() => onSelectPayment(method, nif)}>
                            <img
                                className={`payment-methods__method__img ${customImage(method) ? 'payment-methods__method__img--custom-image' : ''}`}
                                src={getPaymentImage(method)}
                                alt={`${paymentMethodLabel} payment`}
                            />
                            <span>{ paymentMethodLabel }</span>
                        </div>
                    );
                })
            }
            {SHOW_PAYMENT_NIF && (
                <div className="payment-methods__nif">
                    <span>{t('reservationModal.paymentMethods.nif')}</span>
                    <FormTextField
                        name="nif"
                        value={nif}
                        onChange={(_: string, value: string) => setNif(value)}
                        errors={null}
                        testId="nif"
                    />
                </div>
            )}
        </div>
    );
});
