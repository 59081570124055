/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FormEvent, FunctionComponent, useState } from 'react';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { displayNotification, NotificationType } from '../../utils/notifications';
import scroll from '../../assets/images/scroll.svg';
import FormTextField from '../elements/FormTextField';
import { ErrorCode } from '../../types/errors';
import { KeyedObject } from '../../types/general';

interface OwnProps extends TranslationContext, AuthenticationContext {
    email: string;
}

type Props = OwnProps;

const ResendEmailForm: FunctionComponent<Props> = (props: Props) => {
    const { t, requestNewEmail, email } = props;

    const [fetching, setFetching] = useState(false);
    const [resendEmailForm, setResendEmailForm] = useState(false);
    const [activationEmail, setActivationEmail] = useState('');

    const openResendEmailForm = () => {
        setActivationEmail(email);
        setResendEmailForm(prevResendEmail => !prevResendEmail);
    };

    const onResendFailure = (responseErrors: KeyedObject) => {
        let msg = t('resendEmailForm.resendFailure');

        if (responseErrors && responseErrors[0]) {
            msg = t(`errors.${ErrorCode[responseErrors[0].errorCode]}`);
        }

        displayNotification({
            message: msg,
            type: NotificationType.Danger,
        });
        setFetching(false);
    };

    const onResendSuccess = () => {
        displayNotification({
            message: t('resendEmailForm.resendSuccess'),
            type: NotificationType.Success,
        });
        setFetching(false);
    };

    const resendEmail = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (activationEmail === '') {
            displayNotification({
                message: t('errors.NotBlank'),
                type: NotificationType.Danger,
            });
            return;
        }

        setFetching(true);

        requestNewEmail(activationEmail, onResendSuccess, onResendFailure);
    };

    return (
        <form onSubmit={resendEmail} className="narrow-form">
            <Backdrop open={fetching} className="loading">
                <CircularProgress color="inherit" />
            </Backdrop>
            {resendEmailForm && (
                <div>
                    <p>{t('resendEmailForm.message')}</p>
                    <FormTextField
                        name="activationEmail"
                        value={activationEmail}
                        onChange={(_: string, value: string) => setActivationEmail(value)}
                        placeholder={t('resendEmailForm.emailLabel')}
                        errors={null}
                        disabled={fetching}
                    />
                    <div className="activate-buttons">
                        <Button
                            className="white-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={fetching}
                        >
                            {t('resendEmailForm.submitButton')}
                        </Button>
                        <br />
                        <br />
                    </div>
                </div>
            )}
            <div className="activate-buttons">
                <br />
                <br />
                <button className="hidden-button" onClick={openResendEmailForm} type="button">
                    {t('resendEmailForm.resend')}
                </button>
                <br />
                <br />
                <img
                    src={scroll}
                    alt="scroll"
                    data-testid="scroll-to-top"
                    onClick={() => {
                        const container = document.getElementById('scrollable-container');
                        if (container && container.scrollTo) {
                            container.scrollTo(0, 0);
                        }
                    }}
                />
            </div>
        </form>
    );
};

export default withTranslationContext(withAuthenticationContext(ResendEmailForm));
