/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { LoginResponsePayload, RegisterResponsePayload, ProfileResponsePayload } from '../types/authentication';
import { KeyedObject } from '../types/general';

export enum AuthenticationAction {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',
    FORGOT_PASS_REQUEST = 'FORGOT_PASS_REQUEST',
    FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS',
    FORGOT_PASS_FAILURE = 'FORGOT_PASS_FAILURE',
    RESET_PASS_REQUEST = 'RESET_PASS_REQUEST',
    RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS',
    RESET_PASS_FAILURE = 'RESET_PASS_FAILURE',
    PROFILE_CHANGE_REQUEST = 'PROFILE_CHANGE_REQUEST',
    PROFILE_CHANGE_SUCCESS = 'PROFILE_CHANGE_SUCCESS',
    PROFILE_CHANGE_FAILURE = 'PROFILE_CHANGE_FAILURE',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}

// Login

export interface LoginRequestActionType {
    type: typeof AuthenticationAction.LOGIN_REQUEST;
}

export interface LoginSuccessActionType {
    type: typeof AuthenticationAction.LOGIN_SUCCESS;
    payload: LoginResponsePayload;
}

export interface LoginFailureActionType {
    type: typeof AuthenticationAction.LOGIN_FAILURE;
    payload: KeyedObject | null;
}

// Registration

export interface RegistrationRequestActionType {
    type: typeof AuthenticationAction.REGISTRATION_REQUEST;
}

export interface RegistrationSuccessActionType {
    type: typeof AuthenticationAction.REGISTRATION_SUCCESS;
    payload: RegisterResponsePayload;
}

export interface RegistrationFailureActionType {
    type: typeof AuthenticationAction.REGISTRATION_FAILURE;
    payload: KeyedObject | null;
}

// Forgot Password

export interface ForgotPassRequestActionType {
    type: typeof AuthenticationAction.FORGOT_PASS_REQUEST;
}

export interface ForgotPassSuccessActionType {
    type: typeof AuthenticationAction.FORGOT_PASS_SUCCESS;
}

export interface ForgotPassFailureActionType {
    type: typeof AuthenticationAction.FORGOT_PASS_FAILURE;
    payload: KeyedObject | null;
}

// Reset Password

export interface ResetPassRequestActionType {
    type: typeof AuthenticationAction.RESET_PASS_REQUEST;
}

export interface ResetPassSuccessActionType {
    type: typeof AuthenticationAction.RESET_PASS_SUCCESS;
}

export interface ResetPassFailureActionType {
    type: typeof AuthenticationAction.RESET_PASS_FAILURE;
    payload: KeyedObject | null;
}

// Profile Change

export interface ProfileChangeRequestActionType {
    type: typeof AuthenticationAction.PROFILE_CHANGE_REQUEST;
}

export interface ProfileChangeSuccessActionType {
    type: typeof AuthenticationAction.PROFILE_CHANGE_SUCCESS;
    payload: ProfileResponsePayload;
}

export interface ProfileChangeFailureActionType {
    type: typeof AuthenticationAction.PROFILE_CHANGE_FAILURE;
    payload: KeyedObject | null;
}

// Logout

export interface LogoutRequestActionType {
    type: typeof AuthenticationAction.LOGOUT_REQUEST;
}

export type AuthenticationActionTypes =
    | LoginRequestActionType
    | LoginSuccessActionType
    | LoginFailureActionType
    | RegistrationRequestActionType
    | RegistrationSuccessActionType
    | RegistrationFailureActionType
    | ForgotPassRequestActionType
    | ForgotPassSuccessActionType
    | ForgotPassFailureActionType
    | ResetPassRequestActionType
    | ResetPassSuccessActionType
    | ResetPassFailureActionType
    | ProfileChangeRequestActionType
    | ProfileChangeSuccessActionType
    | ProfileChangeFailureActionType
    | LogoutRequestActionType;
