/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

import { PreferencesContext, withPreferencesContext } from '../controllers/PreferencesContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { SelectOption } from '../../types/general';
import { Language } from '../../types/preferences';
import FormSelectField from './FormSelectField';

interface OwnProps extends PreferencesContext, TranslationContext {
    mobile?: boolean;
    overlay?: boolean;
    alwaysShow?: boolean;
}

type Props = OwnProps;

const LanguageSelect: FunctionComponent<Props> = (props: Props) => {
    const {
        language, t, overlay, mobile, alwaysShow,
    } = props;
    
    const onSelectLanguage = (_: string, value: string): void => {
        const { changeLanguage } = props;
        changeLanguage(value as Language);
    };

    const languages: SelectOption[] = mobile
        ? [
            { label: t(`drawer.mobile${Language.EN}`), value: Language.EN },
            { label: t(`drawer.mobile${Language.PT}`), value: Language.PT },
        ]
        : [
            { label: t(`drawer.${Language.EN}`), value: Language.EN },
            { label: t(`drawer.${Language.PT}`), value: Language.PT },
        ];

    const classNames = ['language-select'];
    if (overlay) {
        classNames.push('language-overlay');
        if (mobile) {
            classNames.push('language-overlay--mobile');
        }
    } else {
        if (mobile) {
            classNames.push('language-select--mobile');
        }
    }
    if (alwaysShow) {
        classNames.push('language-select--always');
    }

    return (
        <div className={classNames.join(' ')}>
            <FormSelectField
                name="language"
                onChange={onSelectLanguage}
                options={languages}
                value={language}
                errors={null}
                label={mobile || overlay ? '' : t('drawer.languageLabel')}
                testId="language-select"
            />
        </div>
    );
};

export default withTranslationContext(withPreferencesContext(LanguageSelect));
