/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { QRCodeBooking } from '../reducers/types';
import { KeyedObject } from '../types/general';

export enum BookingsAction {
    BOOKING_REQUEST = 'BOOKING_REQUEST',
    BOOKING_REQUEST_FINISH = 'BOOKING_REQUEST_FINISH',
    CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS',
    CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE',
    SET_BUSINESS_BOOKING = 'SET_BUSINESS_BOOKING',
}

// Create Booking

export interface BookingRequestActionType {
    type: typeof BookingsAction.BOOKING_REQUEST;
}

export interface BookingRequestFinishedActionType {
    type: typeof BookingsAction.BOOKING_REQUEST_FINISH;
}

export interface NewBookingSuccessActionType {
    type: typeof BookingsAction.CREATE_BOOKING_SUCCESS;
}

export interface NewBookingFailureActionType {
    type: typeof BookingsAction.CREATE_BOOKING_FAILURE;
    payload: KeyedObject | null;
}

export interface NewQRCodeBooking {
    type: typeof BookingsAction.SET_BUSINESS_BOOKING;
    payload: Partial<QRCodeBooking> | null;
}

export type BookingsActionTypes =
    | BookingRequestActionType
    | BookingRequestFinishedActionType
    | NewBookingSuccessActionType
    | NewBookingFailureActionType
    | NewQRCodeBooking;
