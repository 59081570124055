/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    useState, useEffect, useRef, useCallback,
} from 'react';
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { Business } from '../../types/businesses';

interface OwnProps {
    fetchBusinesses: () => Promise<Business[]>;
    initialBusinesses: Business[];
}

type Props = OwnProps;

export const ReservationMapCounter: React.FC<OwnProps> = (props: Props) => {
    const { fetchBusinesses, initialBusinesses } = props;

    const [checkedIn, setCheckedIn] = useState(0);
    const [reservedBollards, setReservedBollards] = useState(0);
    const [totalBollards, setTotalBollards] = useState(0);
    const timeoutCounter = useRef<NodeJS.Timeout | undefined>(undefined);

    const refreshCounters = (businesses: Business[]) => {
        let newReservedBollards = 0;
        let newCheckedIn = 0;
        let newTotalBollards = 0;
            
        businesses.forEach(business => {
            newCheckedIn += business.totalCheckedInClientsCount || 0;
            newReservedBollards += (business.totalCheckedInBollardsCount || 0) + (business.totalReservedBollardsCount || 0);
            newTotalBollards += business.totalBollardsCount || 0;
        });
            
        setCheckedIn(newCheckedIn);
        setReservedBollards(newReservedBollards);
        setTotalBollards(newTotalBollards);
    };
    
    const fetchNewBusinesses = useCallback(() => setTimeout(
        async () => {
            const newBusinesses = await fetchBusinesses();
            refreshCounters(newBusinesses);
            timeoutCounter.current = fetchNewBusinesses();
        }, 1000 * 60,
    ), [fetchBusinesses]);
    
    useEffect((): (() => void) => {
        refreshCounters(initialBusinesses);
        timeoutCounter.current = fetchNewBusinesses();
        return () => (timeoutCounter.current ? clearTimeout(timeoutCounter.current) : {});
    }, [initialBusinesses, fetchNewBusinesses]);

    return (
        <div className="reservation-map-counter">
            <div>
                <PeopleAltIcon className="reservation-map-counter__icon" />
                <span>{checkedIn}</span>
            </div>
            <div>
                <BeachAccessOutlinedIcon className="reservation-map-counter__icon" />
                <span>{`${reservedBollards}/${totalBollards}`}</span>
            </div>
        </div>
    );
};
