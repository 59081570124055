/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { activateURL, emailConfirmationURL } from '../../services/auth';
import { ApiError, ErrorCode } from '../../types/errors';
import { KeyedObject } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { hasFieldErrors } from '../../utils/validations';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AppRoute } from '../../types/routes';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';

interface MatchParams {
    token?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps<MatchParams> { }

interface OwnState {
    isFetching: boolean;
    errors: KeyedObject | null;
    token: string;
}

const initialState: OwnState = {
    isFetching: false,
    errors: null,
    token: '',
};

class ConfirmRegistrationScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const {
            history,
            submitLogout,
            isAuthenticated,
            match: {
                params: { token },
            },
        } = this.props;

        this.setState({
            isFetching: true,
        });

        if (isAuthenticated) {
            submitLogout();
        }

        const match = matchPath(history.location.pathname, {
            path: AppRoute.ConfirmAccount,
            exact: true,
            strict: false,
        });

        if (token && match) {
            this.activateAccount(token);
        }

        if (token && !match) {
            this.confirmEmailChange(token);
        }
    }

    activateAccount = (token: string) => {
        const { t } = this.props;
        const { history } = this.props;

        axios
            .post(activateURL(token))
            .then(() => {
                displayNotification({
                    message: t('loginForm.activated'),
                    type: NotificationType.Success,
                });
                history.push(AppRoute.Welcome);
            })
            .catch(error => {
                let errors: ApiError = {};
                if (axios.isAxiosError(error)) {
                    errors = error.response?.data;
                }
                if (errors && errors.errors && errors.errors.length > 0) {
                    errors.errors.forEach((err: any) => {
                        displayNotification({
                            message: t(`errors.${ErrorCode[err.errorCode]}`),
                            type: NotificationType.Danger,
                        });
                    });
                } else {
                    let message = t('errors.general');
                    if (hasFieldErrors(errors)) message = t('errors.fields');

                    displayNotification({
                        message,
                        type: NotificationType.Danger,
                    });
                }
                history.push(AppRoute.Welcome);
            });
    };

    confirmEmailChange = (token: string) => {
        const { t } = this.props;
        const { history } = this.props;

        axios
            .post(emailConfirmationURL(token))
            .then(() => {
                displayNotification({
                    message: t('profileForm.emailChangedSuccessfully'),
                    type: NotificationType.Success,
                });
                history.push(AppRoute.Welcome);
            })
            .catch(error => {
                let errors: ApiError = {};
                if (axios.isAxiosError(error)) {
                    errors = error.response?.data;
                }
                if (errors && errors.errors && errors.errors.length > 0) {
                    errors.errors.forEach((err: any) => {
                        displayNotification({
                            message: t(`errors.${ErrorCode[err.errorCode]}`),
                            type: NotificationType.Danger,
                        });
                    });
                } else {
                    let message = t('errors.general');
                    if (hasFieldErrors(errors)) message = t('errors.fields');

                    displayNotification({
                        message,
                        type: NotificationType.Danger,
                    });
                }
                history.push(AppRoute.Welcome);
            });
    };

    render() {
        const { isFetching } = this.state;
        return (
            <Backdrop open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(ConfirmRegistrationScreen));
