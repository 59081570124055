/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Map from '../elements/Map';
import { mapCenter } from '../../utils/constants';
import { Business } from '../../types/businesses';
import { customMarker } from '../../utils/maps';
import { getBusiness, getBusinesses } from '../../services/businesses';
import BookingModal from '../views/BookingModal';
import ReservationModal from '../views/ReservationModal';
import GoogleAutocomplete from '../elements/GoogleAutocomplete';
import { OrderQuery } from '../../types/general';
import { asyncThrottle } from '../../utils/misc';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { ReservationMapCounter } from '../elements/ReservationMapCounter';
import UserMenu from '../elements/UserMenu';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import AcceptInviteModal from '../elements/AcceptInviteModal';

interface MatchParams {
    businessId?: string;
    areaId?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps<MatchParams>, BookingsContext, InvitationsContext {}

interface OwnState {
    businesses: Business[];
    mapRef: google.maps.Map | null;
    selectedBusiness: Business | null;
    fetched: boolean;
    markersPosition: string[];
    markerCluster: MarkerClusterer | null;
    refreshBookingModal: boolean;
}

const initialState: OwnState = {
    businesses: [],
    mapRef: null,
    selectedBusiness: null,
    fetched: false,
    markersPosition: [],
    markerCluster: null,
    refreshBookingModal: false,
};

class MapScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const {
            qrCodeBooking: prevQrCodeBooking,
        } = prevProps;
        const {
            qrCodeBooking,
        } = this.props;

        if (qrCodeBooking && qrCodeBooking.qrCodeId !== prevQrCodeBooking?.qrCodeId) {
            this.prepare();
        }
    }

    onMarkerClick = (business: Business | null) => {
        this.setState({ selectedBusiness: business });
    };

    onPlaceSelected = (latLngBounds: google.maps.LatLngBounds) => {
        const { mapRef } = this.state;
        if (mapRef) {
            mapRef.fitBounds(latLngBounds);
        }
    };

    onBusinessSelected = (latLng: google.maps.LatLng) => {
        const { mapRef } = this.state;
        if (mapRef) {
            mapRef.panTo(latLng);
            mapRef.setZoom(20);
        }
    };

    onMapMounted = async (ref: google.maps.Map) => {
        const cluster = new MarkerClusterer(ref, [], {
            styles: [
                {
                    url: '/images/cluster.png',
                    height: 60,
                    width: 60,
                    textColor: '#000000',
                    textSize: 13,
                },
            ],
        });
        this.setState({
            mapRef: ref,
            markerCluster: cluster,
        }, this.prepare);
    };

    prepare = async () => {
        const {
            match: {
                params: { businessId },
            },
            qrCodeBooking,
            getBusinessAndArea,
        } = this.props;
        const { mapRef } = this.state;

        if (!mapRef) return;

        let selectedBusiness = null;
        if (businessId) {
            const business = await getBusiness(businessId);
            selectedBusiness = business;
        }

        if (qrCodeBooking?.qrCodeId) {
            if (qrCodeBooking?.business) {
                selectedBusiness = qrCodeBooking.business;
            } else {
                const res = await getBusinessAndArea(String(qrCodeBooking.qrCodeId));
                if (res && res.business) {
                    selectedBusiness = res.business;
                }
            }
        }

        if (selectedBusiness) {
            mapRef.panTo({ lat: selectedBusiness.address.lat, lng: selectedBusiness.address.lng });
            mapRef.setZoom(20);
        }

        this.setState({ selectedBusiness }, this.loadMarkers);
    }

    loadMarkers = async (firstTime = true): Promise<void> => {
        const {
            match: {
                params: { businessId },
            },
            qrCodeBooking,
        } = this.props;
        const { mapRef, markersPosition, markerCluster } = this.state;

        if (mapRef && markerCluster) {
            const businesses = await this.fetchBusinessesWithinBoundaries();

            const newMarkersPosition: string[] = [];
            const newBusinesses = businesses.filter(
                business => {
                    const latlong = JSON.stringify({ lat: business.address.lat, lng: business.address.lng });
                    return !markersPosition.includes(latlong);
                },
            );

            newBusinesses.forEach(business => {
                const marker = customMarker(
                    mapRef,
                    { lat: business.address.lat, lng: business.address.lng },
                    undefined,
                    business.name,
                    undefined,
                    true,
                );
                marker.addListener('click', () => this.onMarkerClick(business));
                newMarkersPosition.push(JSON.stringify({ lat: business.address.lat, lng: business.address.lng }));
                markerCluster.addMarker(marker);
            });

            if (firstTime && !businessId && !qrCodeBooking) {
                const latitudes = newBusinesses.map(business => business.address.lat);
                const longitudes = newBusinesses.map(business => business.address.lng);

                mapRef.fitBounds(
                    new google.maps.LatLngBounds(
                        { lat: Math.min(...latitudes), lng: Math.min(...longitudes) },
                        { lat: Math.max(...latitudes), lng: Math.max(...longitudes) },
                    ),
                );
                mapRef.addListener('dragend', asyncThrottle(() => this.loadMarkers(false), 1000));
                mapRef.addListener('zoom_changed', asyncThrottle(() => this.loadMarkers(false), 1000));
            }

            this.setState({
                markersPosition: [...markersPosition, ...newMarkersPosition],
                businesses,
            });
        }
    };

    fetchBusinessesWithinBoundaries = async (): Promise<Business[]> => {
        const { mapRef } = this.state;

        if (!mapRef) return [];
        const latLng = mapRef.getBounds()?.toJSON();
        return (await getBusinesses({
            boundNELat: latLng?.north,
            boundNELng: latLng?.east,
            boundSWLat: latLng?.south,
            boundSWLng: latLng?.west,
            _limit: 50,
            _sort: 'numberOfAreas',
            _order: OrderQuery.Descending,
            withReservationInfo: true,
        }))?.businesses?.filter(business => business.numberOfAreas > 0) || [];
    }

    fetchBusinesses = async (input: string): Promise<Business[]> => {
        const response = await getBusinesses({
            _limit: 3, _q: input, _sort: 'numberOfAreas', _order: OrderQuery.Descending,
        });
        if (response !== null) {
            return response.businesses.filter((business: Business) => business.numberOfAreas > 0);
        }
        return [];
    };

    onCloseReservationModal = () => {
        const { setQrCodeForCheckIn } = this.props;

        setQrCodeForCheckIn(null);
        this.onMarkerClick(null);

        this.setState(prevState => ({
            refreshBookingModal: !prevState.refreshBookingModal,
        }));
    }

    render() {
        const {
            match: {
                params: { areaId },
            },
            qrCodeBooking,
            t,
            isAuthenticated,
            inviteToken,
        } = this.props;
        const { selectedBusiness, businesses, refreshBookingModal } = this.state;

        return (
            <div className="map-container">
                {inviteToken && <AcceptInviteModal />}
                <GoogleAutocomplete
                    label={t('navbar.searchLabel')}
                    onPlaceSelected={this.onPlaceSelected}
                    onBusinessSelected={this.onBusinessSelected}
                    fetchExtraData={this.fetchBusinesses}
                />
                <ReservationMapCounter fetchBusinesses={this.fetchBusinessesWithinBoundaries}
                                       initialBusinesses={businesses} />
                <Map zoom={8} height="100%" center={mapCenter} onMapMounted={this.onMapMounted} />
                {isAuthenticated && <BookingModal refresh={refreshBookingModal} />}
                <ReservationModal
                    business={selectedBusiness}
                    areaId={areaId || String(qrCodeBooking?.areaId)}
                    close={this.onCloseReservationModal}
                />
                <UserMenu />
            </div>
        );
    }
}

export default withAuthenticationContext(
    withBookingsContext(
        withTranslationContext(
            withRouter(
                withInvitationsContext(
                    MapScreen,
                ),
            ),
        ),
    ),
);
