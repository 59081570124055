/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { TranslationContextProvider } from './TranslationContext';

import en from '../../locale/en.json';
import pt from '../../locale/pt.json';
import { Language } from '../../types/preferences';
import { KeyedObject } from '../../types/general';
import { AppState } from '../../reducers/types';

interface StateProps {
    language: Language;
    languages: Array<Language>;
    translations: object | null;
}

interface DispatchProps {}

interface OwnProps extends StateProps {
    children: any;
    defaultTranslations: KeyedObject;
}

interface OwnState {}

type Props = StateProps & OwnProps;
type State = OwnState;

export class TranslationController extends Component<Props, State> {
    static defaultProps = {
        defaultTranslations: { en, pt },
    };

    t = (literal: string, params?: KeyedObject): string => {
        const { defaultTranslations, translations, language } = this.props;
        const trans = translations || defaultTranslations;
        return this.getTranslation(trans, language, literal, params);
    };

    loadLanguages = async () => {};

    getTranslation = (translations: KeyedObject, language: Language, key: string, params?: KeyedObject): string => {
        let out = key;

        if (translations) {
            const languageStrings = translations[language];

            if (languageStrings) {
                const keyString = this.getString(languageStrings, key, null);

                if (keyString) {
                    out = this.interpolateString(keyString, params);
                }
            }
        }

        return out;
    };

    getString = (data: KeyedObject, key: string, defaultValue: any): any => {
        const keyParts = key.split('.');
        let currentPosition = data;

        for (let i = 0; i < keyParts.length; i++) {
            if (currentPosition) {
                currentPosition = currentPosition[keyParts[i]];
            }
        }

        return currentPosition ? currentPosition : defaultValue;
    };

    interpolateString = (text: string, params?: KeyedObject): any => {
        if (!params) {
            return text;
        }

        const children = text.split(/({[^}]+})/g).map((child) => {
            const match = /{(.+)}/g.exec(child);
            if (match) {
                const param = params[match[1]];
                return param ? param : String(param);
            }
            return child;
        });

        return children.some((child) => child && typeof child === 'object')
            ? React.createElement('span', null, ...children)
            : children.join('');
    };

    render() {
        const { children, language, languages } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    language: language,
                    languages: languages,
                    loadLanguages: this.loadLanguages,
                    t: this.t,
                }}
            >
                {children}
            </TranslationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
        languages: state.preferences.languages,
        translations: state.preferences.translations,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({});

export const ConnectedTranslationController = connect(mapStateToProps, mapDispatchToProps)(TranslationController);
