/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'customers';

export const registerURL = (): string => {
    return `${API_URL}/${api}`;
};

export const editProfileURL = (id: string): string => {
    return `${API_URL}/${api}/${id}`;
};
