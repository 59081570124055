/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { Button, Dialog } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Booking, BookingStatus } from '../../types/bookings';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import checkout from '../../assets/images/checkout.svg';
import checkoutWhite from '../../assets/images/checkout_white.svg';
import checkin from '../../assets/images/checkin.svg';
import repeat from '../../assets/images/repeat.svg';
import { AppRoute } from '../../types/routes';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import CheckoutModal from '../views/CheckoutModal';
import { buildRoute, formatHourTime, numberToCurrency } from '../../utils/misc';
import ConfirmationDialog from './ConfirmationDialog';

interface OwnProps {
    booking: Booking;
    fetchBookings(): void;
    squareIcons?: boolean;
    showPrice?: boolean;
    compactView?: boolean;
    cancelBookingCallback?: () => void;
}

type Props = TranslationContext & BookingsContext & OwnProps;

const renderButtonAccordingToStatus = (
    promptCheckout: (evt: React.MouseEvent<HTMLElement>) => void,
    promptCheckInOutOfReservationPeriod: (evt: React.MouseEvent<HTMLElement>) => void,
    t: (key: string) => string,
    booking: Booking,
    squareIcons = false,
) => {
    const insideReservationPeriod = moment().diff(moment.utc(booking.startDateTime)) > 0;

    switch (booking.status) {
        case BookingStatus.CheckedIn:
            return (
                <div
                    className="booking-container__top-container__right-side booking-container__top-container__right-side--checkout"
                    onClick={promptCheckout}
                >
                    {squareIcons ? (
                        <div className="booking-container__top-container__right-side--checkout__square">
                            <img src={checkoutWhite} alt="checkout" />
                        </div>
                    ) : (
                        <img src={checkout} alt="checkout" />
                    )}
                    <div>
                        <span>Check Out</span>
                    </div>
                </div>
            );
        case BookingStatus.Reserved:
            if (!insideReservationPeriod) {
                return (
                    <button
                        type="button"
                        data-testid="booking-checkin"
                        className="booking-container__top-container__right-side booking-container__top-container__right-side--checkin"
                        onClick={promptCheckInOutOfReservationPeriod}
                    >
                        <div
                            className={`booking-container__top-container__right-side--checkin__image-container 
                    booking-container__top-container__right-side--checkin__image-container--not-available ${
                                squareIcons
                                    ? 'booking-container__top-container__right-side--checkin__image-container--square'
                                    : ''
                            }`}
                        >
                            <img src={checkin} alt="checkout" />
                        </div>
                        <div>
                            <span>{t('status.RESERVED')}</span>
                        </div>
                    </button>
                );
            }
            return (
                <Link
                    to={AppRoute.QrCode}
                    className="booking-container__top-container__right-side booking-container__top-container__right-side--checkin"
                >
                    <div
                        className={`booking-container__top-container__right-side--checkin__image-container ${
                            squareIcons
                                ? 'booking-container__top-container__right-side--checkin__image-container--square'
                                : ''
                        }`}
                    >
                        <img src={checkin} alt="checkout" />
                    </div>
                    <div>
                        <span>Check In</span>
                    </div>
                </Link>
            );
        case BookingStatus.ATMReferenceCreated:
        case BookingStatus.CreditCardPaymentCreated:
        case BookingStatus.MBWayRequestCreated:
            return (
                <div
                    className="booking-container__top-container__right-side booking-container__top-container__right-side--pending-payment"
                >
                    {t('bookingModal.pendingPayment')}
                </div>
            );
        case BookingStatus.CheckedOut:
        case BookingStatus.Canceled:
        case BookingStatus.CanceledByCustomer:
        case BookingStatus.CanceledByMerchant:
            return (
                <Link
                    to={buildRoute(AppRoute.Reserve, {
                        businessId: booking.businessArea.business.id,
                        areaId: booking.businessArea.id,
                    })}
                    className="booking-container__top-container__right-side booking-container__top-container__right-side--repeat"
                >
                    <img src={repeat} alt="repeat" />
                </Link>
            );
        default:
            return null;
    }
};

const BookingListEntry: FC<OwnProps> = withTranslationContext(withBookingsContext((props: Props) => {
    const [checking, setChecking] = React.useState(false);
    const [alertCheckInInvalid, setAlertCheckInInvalid] = React.useState(false);
    const [confirmCancellation, setConfirmCancellation] = React.useState(false);
    const {
        submitCheckOut, t, fetchBookings, booking, squareIcons, language, showPrice, cancelBookingCallback,
        compactView,
    } = props;

    const promptCheckout = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setChecking(true);
    };

    const promptCheckInOutOfReservationPeriod = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setAlertCheckInInvalid(true);
    };

    const closeAlertCheckInInvalid = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setAlertCheckInInvalid(false);
    };

    const handleCheckoutSuccess = () => {
        displayNotification({
            message: t('checkout.success'),
            type: NotificationType.Success,
        });

        fetchBookings();
        setChecking(false);
    };

    const handleCheckoutFailure = () => {
        displayNotification({
            message: t('checkout.error'),
            type: NotificationType.Danger,
        });
    };

    const checkoutHandler = () => {
        submitCheckOut(String(booking.id), handleCheckoutSuccess, handleCheckoutFailure);
    };

    const openCancellationDialog = (e : React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setConfirmCancellation(true);
    };

    const renderInformation = () => {
        const cancelButton = (
            <button
                onClick={e => openCancellationDialog(e)}
                type="button"
                className="canceled transformed clickable"
            >
                {t('bookingModal.cancel')}
            </button>
        );

        const hasEuPagoInfo = !booking.atmEntity && !booking.mbWayAlias && !booking.reference;
        if (booking.status === BookingStatus.Reserved && (hasEuPagoInfo || !booking.price)) {
            return cancelButton;
        }

        switch (booking.status) {
            case BookingStatus.Canceled:
            case BookingStatus.CanceledByMerchant:
            case BookingStatus.CanceledByCustomer:
                return <span className="canceled transformed">{t('bookingModal.canceled')}</span>;
            case BookingStatus.MBWayRequestCreated:
            case BookingStatus.ATMReferenceCreated:
            case BookingStatus.CreditCardPaymentCreated:
                return (
                    <div>
                        <span>{numberToCurrency(booking.price, language)}</span>
                    </div>
                );
            case BookingStatus.Absence:
                return (
                    <span className="canceled transformed" data-testid="absence-label">
                        {`${t('bookingModal.absence')}${booking.price ? ` - ${numberToCurrency(booking.price, language)}` : ''}`}
                    </span>
                );
            case BookingStatus.NotPaid:
                return <span className="canceled transformed">{t('bookingModal.canceledNotPaid')}</span>;
            default:
                return <span>{numberToCurrency(booking.price, language)}</span>;
        }
    };

    return (
        <div className="booking-container">
            <CheckoutModal
                booking={checking ? booking : undefined}
                onAccept={checkoutHandler}
                close={() => setChecking(false)}
            />
            <Dialog open={alertCheckInInvalid} className="booking-container__alert-box">
                <span data-testid="alert-box" className="booking-container__alert-box__title">{t('bookingModal.alertCheckIn.title')}</span>
                <p className="booking-container__alert-box__description">
                    {
                        t(
                            'bookingModal.alertCheckIn.description', {
                                day: booking.day,
                                startTime: formatHourTime(booking.startTime),
                                endTime: formatHourTime(booking.endTime),
                            },
                        )
                    }
                </p>
                <Button onClick={closeAlertCheckInInvalid} className="booking-container__alert-box__submit">
                    <span>{t('bookingModal.alertCheckIn.ok')}</span>
                </Button>
            </Dialog>
            <div className={`booking-container__top-container ${compactView ? 'booking-container__top-container--compact' : ''}`}>
                {
                    !compactView && (
                        <img
                            src={booking.businessArea.photo}
                            alt="business"
                            className="booking-container__top-container__business-image"
                        />
                    )
                }
                
                <div className="booking-container__top-container__text">
                    <span className="booking-container__top-container__text__title">
                        {booking.businessArea.business.name}
                    </span>
                    <span className="booking-container__top-container__text__description">
                        <span className="bold">{booking.bollardDesignation}</span>
                        {` - ${booking.businessArea.name}`}
                    </span>
                </div>
                {renderButtonAccordingToStatus(promptCheckout, promptCheckInOutOfReservationPeriod, t, booking, squareIcons)}
            </div>
            <div className="booking-container__bottom-container">
                <div className="booking-container__bottom-container__left-container">
                    <div className="booking-container__bottom-container__left-container__element">
                        <CalendarIcon /> {booking.day}
                    </div>
                    <div className="booking-container__bottom-container__left-container__element">
                        <TimeIcon /> {`${formatHourTime(booking.startTime)} - ${formatHourTime(booking.endTime)}`}
                    </div>
                    {
                        showPrice && (
                            <div className="booking-container__bottom-container__left-container__element">
                                <MoneyIcon /> {numberToCurrency(booking.price, language)}
                            </div>
                        )
                    }
                </div>
                <div className="booking-container__bottom-container__right-container">
                    {renderInformation()}
                    {
                        cancelBookingCallback && (
                            <ConfirmationDialog
                                open={confirmCancellation}
                                close={() => setConfirmCancellation(false)}
                                onAccept={() => {
                                    cancelBookingCallback();
                                    setConfirmCancellation(false);
                                }}
                                header="cancellation.cancel"
                                icon={checkout}
                                title="cancellation.title"
                                actionLabel="cancellation.cancel"
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}));

export default BookingListEntry;
