/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Language } from '../types/preferences';

export enum PreferencesAction {
    LANGUAGE_SET = 'LANGUAGE_SET',
}

export interface LanguageSetActionType {
    type: typeof PreferencesAction.LANGUAGE_SET;
    payload: Language;
}

export type PreferencesActionTypes = LanguageSetActionType;
