/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { ChangeEvent, Component, ReactNode } from 'react';
import {
    FormControlLabel, Checkbox, FormControl, FormHelperText,
} from '@material-ui/core';

import { KeyedObject } from '../../types/general';
import { FormValidatorError, getErrorsForField, hasAnyErrors } from '../../utils/validations';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    name: string;
    label?: string;
    checked?: boolean;
    onChange?: Function;
    required?: boolean;
    disabled?: boolean;
    errors: KeyedObject | null;
}

interface OwnState {}

const initialState: OwnState = {};

class FormCheckbox extends Component<OwnProps, OwnState> {
    state = initialState;

    onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(event.currentTarget.name, event.currentTarget.checked);
        }
    };

    renderErrors(fieldErrors: FormValidatorError[]): ReactNode {
        const { name } = this.props;

        return <ErrorMessage errors={fieldErrors} field={name} />;
    }

    render() {
        const {
            name, label, checked, disabled, required, errors,
        } = this.props;

        const fieldErrors = getErrorsForField(name, errors);
        const hasErrors = hasAnyErrors(fieldErrors);

        return (
            <FormControl
                fullWidth
                error={hasErrors}
                required={required}
                disabled={disabled}
                className={hasErrors ? 'Mui-error' : ''}
            >
                {label ? (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={checked}
                                onChange={this.onChange}
                                name={name}
                                color="primary"
                                disabled={disabled}
                            />
                          )}
                        label={label || ''}
                    />
                ) : (
                    <Checkbox
                        checked={checked}
                        onChange={this.onChange}
                        name={name}
                        color="primary"
                        disabled={disabled}
                    />
                )}
                <FormHelperText>{this.renderErrors(fieldErrors)}</FormHelperText>
            </FormControl>
        );
    }
}

export default FormCheckbox;
