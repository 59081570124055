/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const countriesURL = (): string => {
    return `${API_URL}/countries`;
};
