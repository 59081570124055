/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios, { AxiosResponse } from 'axios';

import moment from 'moment';
import { chunk, flatMapDeep } from 'lodash';
import { BookingsContextProvider } from './BookingsContext';
import { AppState, QRCodeBooking } from '../../reducers/types';
import {
    customerBookingsURL, bookingURL, cancelURL, checkinURL, checkoutURL,
} from '../../services/bookings';
import { KeyedObject, ListResponse } from '../../types/general';
import { validateForm } from '../../utils/validations';
import { ApiError } from '../../types/errors';
import {
    bookingFinishedRequestActionCreator, bookingRequestActionCreator, requestCreateBooking, setQRCodeBooking,
} from '../../actions/bookings';
import { validations } from '../../types/validations';
import { businessURL, scheduleURL } from '../../services/businesses';
import {
    BollardSchedule, Booking, BookingRequestPayload, BookingStatus, DaySchedulesResponsePayload,
} from '../../types/bookings';
import { qrCode } from '../../services/bollards';
import { getDateFromDayAndTime } from '../../utils/dates';
import { Bollard } from '../../types/areas';
import { delay, delayedAsync } from '../../utils/misc';

interface StateProps {
    bookingFetching: boolean;
    createBookingErrors: ApiError | null;
    qrCodeBooking: QRCodeBooking | null;
}

interface OwnProps {
    children: any;
}

type Props = OwnProps & StateProps & ReturnType<typeof mapDispatchToProps>;

export class BookingsController extends Component<Props> {
    // Requests

    getBooking = async (bookingId: number): Promise<Booking | null> => {
        try {
            const { data } = await axios.get(bookingURL(bookingId));
            return data;
        } catch {
            return null;
        }
    };

    getBookings = async (filters?: KeyedObject): Promise<ListResponse<Booking> | null> => {
        try {
            const { data, headers } = await axios.get(customerBookingsURL(filters));

            return { data, total: headers['x-total-count'] ? Number(headers['x-total-count']) : 0 };
        } catch {
            return null;
        }
    };

    getSchedules = async (businessId: string, areaId: string, days: string[]): Promise<DaySchedulesResponsePayload[]> => {
        const { dispatchRequest, dispatchFinishRequest } = this.props;

        const requestFactory = async (daysChunk: string[]) => {
            try {
                const responses: AxiosResponse<BollardSchedule[]>[] = await Promise.all(
                    daysChunk.map(day => axios.get(scheduleURL(businessId, areaId, day))),
                );

                return responses.map((res, index) => ({
                    schedules: res.data,
                    day: days[index],
                }));
            } catch {
                return [];
            }
        };

        dispatchRequest();
        const schedules = await Promise.all(chunk(days, 5).map(async (daysChunk, i) => {
            await delay(500 * i);
            return requestFactory(daysChunk);
        }));
        dispatchFinishRequest();
        return flatMapDeep(schedules);
    };

    getBusinessAndArea = async (qrCodeId: string): Promise<Partial<QRCodeBooking> | null> => {
        try {
            const { data } = await axios.get(qrCode(qrCodeId));
            return {
                business: (await axios.get(businessURL(data.businessId))).data,
                areaId: data.businessAreaId,
                bollardId: data.id,
            };
        } catch {
            return null;
        }
    }

    getBollardByQrCodeId = async (qrCodeId: string): Promise<Bollard | null> => {
        try {
            const { data } = await axios.get(qrCode(qrCodeId));
            return data;
        } catch {
            return null;
        }
    }

    // Create booking

    validateNewBooking = (fields: BookingRequestPayload): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.bookingCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitNewBooking = (payload: BookingRequestPayload, onSuccess: (newBookings: Booking[]) => void, onFailure: () => void): void => {
        const { dispatchRequestCreateBooking } = this.props;
        dispatchRequestCreateBooking(payload, onSuccess, onFailure);
    };

    cancelBooking = async (booking: Booking, loading = true): Promise<number> => {
        const { dispatchRequest, dispatchFinishRequest } = this.props;

        if (loading) dispatchRequest();
        try {
            await axios.put(cancelURL(booking.id));
            dispatchFinishRequest();
            return booking.id;
        } catch {
            dispatchFinishRequest();
            return -1;
        }
    }

    // Cancel Booking
    cancelBookings = async (bookings: Booking[], bookingIdsToCancel?: number[]): Promise<Booking[]> => {
        const { dispatchRequest, dispatchFinishRequest } = this.props;

        dispatchRequest();
        const bookingsToCancel = bookingIdsToCancel ? bookings.filter(booking => bookingIdsToCancel.includes(booking.id)) : bookings;

        const successfullyDeletedBookingIds = await delayedAsync(b => this.cancelBooking(b, false), bookingsToCancel, 200);

        dispatchFinishRequest();
        return bookings.map(booking => ({
            ...booking,
            status: successfullyDeletedBookingIds.includes(booking.id) ? BookingStatus.Canceled : booking.status,
        }));
    }

    // CheckIn

    submitCheckIn = async (qrCodeId: string): Promise<Booking | null> => {
        try {
            const { data } = await axios.get(checkinURL(qrCodeId));

            return data;
        } catch {
            return null;
        }
    };

    // CheckOut

    submitCheckOut = async (reservationId: string, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.get(checkoutURL(reservationId));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    // QRcode Check in

    setQrCodeForCheckIn = (payload: Partial<QRCodeBooking> | null): void => {
        const { dispatchSetQRCodeBooking } = this.props;

        dispatchSetQRCodeBooking(payload);
    }

    // Booking Utils
    isBookingInPast = (booking: Booking): boolean => {
        const canceledOrPassedStatus = [
            BookingStatus.CheckedOut, BookingStatus.Canceled, BookingStatus.NotPaid, BookingStatus.MerchantReservationConcluded,
            BookingStatus.CanceledByMerchant, BookingStatus.CanceledByCustomer, BookingStatus.Absence,
        ];

        const dateEnd = getDateFromDayAndTime(booking.day, booking.endTime);
        return moment().isSameOrAfter(dateEnd) || canceledOrPassedStatus.includes(booking.status);
    }

    render(): React.ReactNode {
        const {
            children, createBookingErrors, bookingFetching, qrCodeBooking,
        } = this.props;

        return (
            <BookingsContextProvider
                value={{
                    bookingFetching,
                    qrCodeBooking,
                    createBookingErrors,
                    getBooking: this.getBooking,
                    getBookings: this.getBookings,
                    getSchedules: this.getSchedules,
                    validateNewBooking: this.validateNewBooking,
                    submitNewBooking: this.submitNewBooking,
                    submitCheckIn: this.submitCheckIn,
                    submitCheckOut: this.submitCheckOut,
                    getBusinessAndArea: this.getBusinessAndArea,
                    getBollardByQrCodeId: this.getBollardByQrCodeId,
                    setQrCodeForCheckIn: this.setQrCodeForCheckIn,
                    isBookingInPast: this.isBookingInPast,
                    cancelBookings: this.cancelBookings,
                    cancelBooking: this.cancelBooking,
                }}
            >
                {children}
            </BookingsContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        bookingFetching: state.bookings.createBookingRequest.isFetching,
        createBookingErrors: state.bookings.createBookingRequest.errors,
        qrCodeBooking: state.bookings.qrCodeBooking,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    dispatchRequest: () => dispatch(bookingRequestActionCreator()),
    dispatchFinishRequest: () => dispatch(bookingFinishedRequestActionCreator()),
    dispatchRequestCreateBooking: (payload: BookingRequestPayload, onSuccess: (newBookings: Booking[]) => void, onFailure: () => void) => dispatch(requestCreateBooking(payload, onSuccess, onFailure)),
    dispatchSetQRCodeBooking: (payload: Partial<QRCodeBooking> | null) => dispatch(setQRCodeBooking(payload)),
});

export const ConnectedBookingsController = connect(mapStateToProps, mapDispatchToProps)(BookingsController);
