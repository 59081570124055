/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PinIcon from '@material-ui/icons/PlaceOutlined';
import {
    Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import moment from 'moment';
import noStores from '../../assets/images/no-stores.png';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import Transition from '../elements/Transition';
import { Booking, BookingStatus } from '../../types/bookings';
import checkout from '../../assets/images/checkout_white.svg';
import { displayNotification, NotificationType } from '../../utils/notifications';
import CheckoutModal from './CheckoutModal';
import IconMegaphone from '../assets/IconMegaphone';
import { disableOrders } from '../../utils/constants';
import { FastMPContext, withFastMPContext } from '../controllers/FastMPContext';
import { getArea } from '../../services/businesses';
import { fastMPWebRedirectURL } from '../../services/fastMP';
import { encodeStickerId } from '../../utils/fastSticker';
import { formatHourTime, numberToCurrency } from '../../utils/misc';

import arrowLeft from '../../assets/images/arrow-left.svg';
import { FastMPStore } from '../../types/fastMP';
import FormTextField from '../elements/FormTextField';

interface OwnProps extends TranslationContext, BookingsContext, FastMPContext {
    bookingId?: number;
    close: () => void;
}

interface OwnState {
    booking: Booking | null;
    checking: boolean;
    ordersExpanded: boolean;
    fastMPStores: FastMPStore[];
    fastMPStickerId: string;
}

const initialState: OwnState = {
    booking: null,
    checking: false,
    ordersExpanded: false,
    fastMPStores: [],
    fastMPStickerId: '',
};

class BookingDetailsModal extends Component<OwnProps, OwnState> {
    state = initialState;

    async componentDidUpdate(oldProps: OwnProps) {
        const { bookingId, getBooking, getFastMPServices } = this.props;
        if (bookingId !== oldProps.bookingId && !!bookingId) {
            const booking = await getBooking(bookingId);

            if (booking !== null) {
                this.setState({ booking });
                const area = await getArea(booking.businessArea.id);
                const bollard = area?.bollards.find(bol => bol.id === booking.bollardId);
                if (bollard && bollard.fastMPStickerId && booking.status === BookingStatus.CheckedIn) {
                    const apps = await getFastMPServices(bollard.fastMPStickerId);
                    this.setState({ fastMPStores: apps, fastMPStickerId: encodeStickerId(bollard.fastMPStickerId) });
                }
            }
        }
    }

    handleCheckoutSuccess = () => {
        const { close, t } = this.props;

        displayNotification({
            message: t('checkout.success'),
            type: NotificationType.Success,
        });
        close();
    };

    handleCheckoutFailure = () => {
        const { t } = this.props;
        displayNotification({
            message: t('checkout.error'),
            type: NotificationType.Danger,
        });
    };

    submitCheckout = () => {
        const { booking } = this.state;
        const { submitCheckOut } = this.props;

        submitCheckOut(String(booking?.id), this.handleCheckoutSuccess, this.handleCheckoutSuccess);
    };

    renderNoStoresAvailable = () => {
        const { t } = this.props;
        return (
            <div className="details-dialog__store-container__no-store-available">
                <span className="details-dialog__store-container__no-store-available__title">
                    {t('bookingDetailsModal.store.noStoreAvailable')}
                </span>
                <span className="details-dialog__store-container__no-store-available__message">
                    {t('bookingDetailsModal.store.comeBackLater')}
                </span>
                <img
                    className="details-dialog__store-container__no-store-available__image"
                    src={noStores}
                    alt="no stores available"
                />
            </div>
        );
    }

    renderFastMPStores = () => {
        const { fastMPStores, fastMPStickerId } = this.state;
        if (fastMPStores.length === 0) return this.renderNoStoresAvailable();

        return (
            <React.Fragment>
                {
                    fastMPStores.map(store => (
                        <a
                            key={store.id}
                            href={fastMPWebRedirectURL(fastMPStickerId, store.id)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="details-dialog__store-container__container__title">{store.name}</span>
                            <img
                                className="details-dialog__store-container__container__image"
                                src={store.logo}
                                alt="store logo"
                            />
                        </a>
                    ))
                }
            </React.Fragment>
        );
    }

    renderMiddleSection = () => {
        const { booking } = this.state;

        if (booking) {
            switch (booking.status) {
                case BookingStatus.MBWayRequestCreated:
                    return this.renderMBWayPayment();
                case BookingStatus.ATMReferenceCreated:
                    return this.renderAtmInformation();
                case BookingStatus.CheckedIn:
                    return this.renderServices();
                default:
            }
        }
    }

    renderMBWayPayment = () => {
        const { t, language } = this.props;
        const { booking } = this.state;

        return (
            <div className="details-dialog__store-container">
                <div className="details-dialog__store-container__header">
                    <span>{t('bookingDetailsModal.paymentDetails.title')}</span>
                </div>
                <div className="details-dialog__store-container__payment-details">
                    <div className="details-dialog__store-container__payment-details__inner">
                        <h4>{t('bookingDetailsModal.paymentDetails.mbWay')}</h4>
                        <div>
                            <span className="bold">{t('bookingDetailsModal.paymentDetails.phoneNumber')}&nbsp;</span>
                            <span>{booking?.mbWayAlias ? booking?.mbWayAlias.split('#')[1] : ' - '}</span>
                        </div>
                        <div>
                            <span className="bold">{t('bookingDetailsModal.paymentDetails.value')}&nbsp;</span>
                            <span>{numberToCurrency(booking?.price || 0, language)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAtmInformation = () => {
        const { t, language } = this.props;
        const { booking } = this.state;

        return (
            <div className="details-dialog__store-container">
                <div className="details-dialog__store-container__header">
                    <span>{t('bookingDetailsModal.paymentDetails.title')}</span>
                </div>
                <div className="details-dialog__store-container__payment-details">
                    <div className="details-dialog__store-container__payment-details__inner">
                        <h4>{t('bookingDetailsModal.paymentDetails.mbReference')}</h4>
                        <div>
                            <span className="bold">{t('bookingDetailsModal.paymentDetails.entity')}&nbsp;</span>
                            <span>{booking?.atmEntity}</span>
                        </div>
                        <div>
                            <span className="bold">{t('bookingDetailsModal.paymentDetails.reference')}&nbsp;</span>
                            <span>{booking?.reference}</span>
                        </div>
                        <div>
                            <span className="bold">{t('bookingDetailsModal.paymentDetails.value')}&nbsp;</span>
                            <span>{numberToCurrency(booking?.price || 0, language)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderServices = () => {
        const { t } = this.props;
        const { ordersExpanded } = this.state;

        return (
            <React.Fragment>
                <div className="details-dialog__store-container">
                    <div className="details-dialog__store-container__header">
                        <span>{t('bookingDetailsModal.store.header')}</span>
                    </div>
                    <div className="details-dialog__store-container__container">
                        {this.renderFastMPStores()}
                    </div>
                </div>
                {!disableOrders && (
                    <div className="details-dialog__order-container">
                        <div
                            className={`details-dialog__order-container__orders ${
                                ordersExpanded ? 'details-dialog__order-container__orders--expanded' : ''
                            }`}
                            onClick={() => this.setState({ ordersExpanded: !ordersExpanded })}
                        >
                            <IconMegaphone />
                            {[1, 2, 3, 4, 5].map(el => (
                                <div
                                    key={el}
                                    className={`details-dialog__order-container__orders__element ${
                                        ordersExpanded
                                            ? 'details-dialog__order-container__orders__element--shown'
                                            : ''
                                    }`}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }

    render() {
        const { t, close, bookingId } = this.props;
        const { booking, checking } = this.state;

        const dateMoment = moment.utc(booking?.day);
        const dayLabel = t(`daysAbrev.${dateMoment.day()}`);
        const hoursLabel = dateMoment.format('DD/MM/yyyy');
        const countryLabel = t(`countries.${booking?.businessArea.business.address.country}`);

        return (
            <>
                <CheckoutModal
                    booking={checking && booking ? booking : undefined}
                    onAccept={this.submitCheckout}
                    close={() => this.setState({ checking: false })}
                />
                <Dialog
                    open={!!bookingId}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="details-dialog fullscreen-modal"
                    TransitionComponent={Transition}
                >
                    <DialogTitle className="fullscreen-modal__header" itemType="div" disableTypography>
                        <BackIcon
                            className="fullscreen-modal__header-action"
                            onClick={close}
                            data-testid="modal-close"
                        />
                        <Typography component="h2" variant="h6">{t('bookingDetailsModal.title')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div
                            className="details-dialog__spot-picture"
                            style={{
                                backgroundImage: `linear-gradient(to bottom, transparent, transparent, #ffffff), url(${booking?.businessArea.photo})`,
                            }}
                        />
                        <div className="booking-container">
                            <div className="booking-container__top-container">
                                <div className="booking-container__top-container__text">
                                    <span className="booking-container__top-container__text__title">
                                        {booking?.businessArea.business.name}
                                    </span>
                                    <span className="booking-container__top-container__text__description">
                                        <span className="bold">{booking?.bollardDesignation}</span>
                                        {` - ${booking?.businessArea.name}`}
                                    </span>
                                </div>
                                <div className="booking-container__top-container__right-container">
                                    {booking?.status === BookingStatus.Canceled || booking?.status === BookingStatus.CanceledByMerchant ? (
                                        <span className="canceled transformed">{t('bookingModal.canceled')}</span>
                                    ) : (
                                        <span
                                            className={
                                                booking?.price === null || booking?.price === 0 ? 'transformed' : ''
                                            }
                                        >
                                            {booking?.price === null || booking?.price === 0
                                                ? t('bookingModal.free')
                                                : `${booking?.price}€`}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="booking-container__bottom-container">
                                <span className="booking-container__bottom-container__location booking-container__bottom-container__left-container__element">
                                    <PinIcon />
                                    {`${booking?.businessArea.business.address.street}, ${
                                        booking?.businessArea.business.address.city
                                    }, ${countryLabel}`}
                                </span>
                                <div className="booking-container__bottom-container__left-container">
                                    <div className="booking-container__bottom-container__left-container__element">
                                        <CalendarIcon />{' '}
                                        {`${dayLabel}, ${hoursLabel}`}
                                    </div>
                                    <div className="booking-container__bottom-container__left-container__element">
                                        <TimeIcon /> {`${formatHourTime(booking?.startTime || '')} - ${formatHourTime(booking?.endTime || '')}`}
                                    </div>
                                </div>
                                {booking?.observations && (
                                    <div className="booking-container__bottom-container__entire-row">
                                        <div className="booking-container__bottom-container__entire-row__label">
                                            <Create />
                                            <span>{t('reservationModal.observations')}</span>
                                        </div>
                                        <FormTextField
                                            name="observations"
                                            className="black-text"
                                            value={booking.observations}
                                            multiline
                                            errors={[]}
                                            rows={4}
                                            disabled
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.renderMiddleSection()}
                    </DialogContent>
                    <DialogActions className="details-dialog__actions">
                        {booking && booking.status === BookingStatus.CheckedIn ? (
                            <Button
                                className="details-dialog__actions__checkout-btn"
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => this.setState({ checking: true })}
                                data-testid="details-checkout-button"
                            >
                                <img src={checkout} alt="checkout" />
                                <span>Check-Out</span>
                            </Button>
                        ) : (
                            <Button
                                className="details-dialog__actions__close-btn"
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => close()}
                                data-testid="details-close-button"
                            >
                                <img src={arrowLeft} alt="next step icon" />
                                <span>{t('general.close')}</span>
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withFastMPContext(withTranslationContext(withBookingsContext(BookingDetailsModal)));
