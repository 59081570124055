/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import LoginForm from '../views/LoginForm';
import logo from '../../assets/images/logo.svg';
import newAccount from '../../assets/images/new-account.svg';
import RegisterForm from '../views/RegisterForm';
import ResendEmailForm from '../views/ResendEmailForm';
import LanguageSelect from '../elements/LanguageSelect';
import { AppRoute } from '../../types/routes';

type OwnProps = TranslationContext;

const WelcomeScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t } = props;
    const location = useLocation<{ from?: string }>();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const scrollElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (location.state && location.state.from === AppRoute.Map) {
            setTimeout(() => {
                if (scrollElementRef.current) scrollElementRef.current.scrollIntoView();
            }, 500);
            history.replace({ ...location, state: {} });
        }
    }, [location, history]);

    return (
        <div className="auth-screen" id="scrollable-container">
            <LanguageSelect mobile />
            <div className="auth-screen__login-container">
                <img src={logo} alt="Boser" className="auth-screen__logo" />
                <div className="auth-screen__greeting">
                    <span className="auth-screen__greeting__title">{t('welcomeScreen.hello')}</span>
                    <span className="auth-screen__greeting__text">{t('welcomeScreen.login')}</span>
                </div>
                <LoginForm setEmail={setEmail} />
            </div>
            <img src={newAccount} alt="Boser" className="auth-screen__new-account" />
            <div className="auth-screen__register-container">
                <div className="auth-screen__greeting">
                    <span className="auth-screen__greeting__title">{t('welcomeScreen.notRegistered')}</span>
                    <span className="auth-screen__greeting__text">{t('welcomeScreen.createAccount')}</span>
                </div>
                <RegisterForm setEmail={setEmail} />
            </div>
            <div className="auth-screen__activate-container" ref={scrollElementRef}>
                <ResendEmailForm email={email} />
            </div>
        </div>
    );
};

export default withTranslationContext(WelcomeScreen);
