/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'authentication';

export const loginURL = (): string => {
    return `${API_URL}/${api}/login`;
};

export const resendEmailURL = (email: string): string => {
    return `${API_URL}/${api}/resend-validation/${email}`;
};

export const updateEmailURL = (): string => {
    return `${API_URL}/${api}/update-email`;
};

export const emailConfirmationURL = (token: string): string => {
    return `${API_URL}/${api}/email-confirmation/${token}`;
};

export const activateURL = (token: string | undefined): string => {
    return `${API_URL}/${api}/activate/${token}`;
};

export const forgotPassURL = (): string => {
    return `${API_URL}/${api}/recover-password`;
};

export const resetPassURL = (): string => {
    return `${API_URL}/${api}/reset-password`;
};

export const logoutURL = (): string => {
    return `${API_URL}/${api}/logout`;
};
