/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';
import { AnyAction } from 'redux';

import { InvitationsState } from './types';
import { GeneralAction } from '../actions/general_types';
import { InvitationsAction } from '../actions/invitations_types';

export const invitationsInitialState: InvitationsState = {
    inviteToken: null,
};

export default function (state = invitationsInitialState, action: AnyAction): InvitationsState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.invitations) {
                return {
                    ...action.payload.invitations,
                };
            }
            return state;
        case InvitationsAction.SET_INVITE_TOKEN:
            return {
                ...state,
                inviteToken: action.payload,
            };
        case GeneralAction.STORE_RESET:
            return invitationsInitialState;
        default:
            return state;
    }
}
