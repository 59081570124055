/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { FastMPStore } from '../../types/fastMP';

import { ConnectedFastMPController } from './FastMPController';

export interface FastMPContext {
    getFastMPServices(fastMPStickerId: string, decode?: boolean): Promise<FastMPStore[]>;
}

export const fastMPContextDefaultValue = {
    getFastMPServices: async (): Promise<FastMPStore[]> => [],
};

const FastMPContext = createContext<FastMPContext | null>(fastMPContextDefaultValue);

export const FastMPContextProvider = FastMPContext.Provider;
export const FastMPContextConsumer = FastMPContext.Consumer;

export const withFastMPContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof FastMPContext>> => props => (
    <ConnectedFastMPController>
        <FastMPContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</FastMPContextConsumer>
    </ConnectedFastMPController>
    );
