/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { throttle } from 'lodash';
import { KeyedObject, SelectOption, Time } from '../types/general';
import { Language } from '../types/preferences';
import { AppRoute } from '../types/routes';

export const generateId = (prefix = ''): string => {
    return `${prefix}${Math.random().toString(36).slice(2)}`;
};

export const getOptionLabelByValue = (value: string, options: SelectOption[]) => options.find(option => option.value === value)?.label;

export const arrayToParams = (paramName: string, arrayValues: any[], useQ = true): string => {
    let params = '';

    if (Array.isArray(arrayValues)) {
        arrayValues.forEach(value => {
            const includeAnd = params.length > 0 ? '&' : '';
            params += `${includeAnd}${paramName}=${value}`;
        });
    }
    
    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const objectToParams = (obj?: KeyedObject, useQ = true): string => {
    let params = '';

    if (obj === undefined) return params;

    Object.keys(obj).forEach(key => {
        if (obj[key] !== undefined) {
            const val = Array.isArray(obj[key]) ? obj[key] : `${obj[key]}`;
            if (val && val.length > 0) {
                if (params.length > 0) {
                    if (Array.isArray(val)) {
                        params = `${params}&${arrayToParams(key, val, false)}`;
                    } else {
                        params = `${params}&${key}=${val}`;
                    }
                } else {
                    if (Array.isArray(val)) {
                        params = `${arrayToParams(key, val, false)}`;
                    } else {
                        params = `${key}=${obj[key]}`;
                    }
                }
            }
        }
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const buildRoute = (route: AppRoute, params: KeyedObject): string => {
    let finalRoute: string = route;

    Object.keys(params).forEach(key => {
        finalRoute = finalRoute.replace(`:${key}`, `${params[key]}`);
    });

    return finalRoute;
};

export const mod97 = (string: string) => {
    let checksum = string.slice(0, 2);
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = (parseInt(fragment) % 97).toString();
    }
    return checksum;
};

export const validateIBAN = (input: string) => {
    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== ibanCodeLengths[code[1]]) {
        return false;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
        return (letter.charCodeAt(0) - 55).toString();
    });

    return mod97(digits) === '1';
};

const ibanCodeLengths: { [key: string]: number } = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
};

export const asyncThrottle = <F extends (...args: any[]) => Promise<unknown>>(func: F, wait?: number) => {
    const throttled = throttle((resolve, reject, args: Parameters<F>) => {
        func(...args).then(resolve).catch(reject);
    }, wait);
    return (...args: Parameters<F>): ReturnType<F> => new Promise((resolve, reject) => {
        throttled(resolve, reject, args);
    }) as ReturnType<F>;
};

export const numberToCurrency = (number: number | string, language: Language) => {
    let locale = 'pt-PT';
    switch (language) {
        case Language.EN:
            locale = 'en-US';
            break;
        default:
            break;
    }

    try {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        });
        return formatter.format(Number(number));
    } catch (e) {
        return number;
    }
};

export const qrCodeIdFromPath = (path: string): string | null => {
    const splitPath = path.split('/');
    const lastSegment = splitPath.pop();
    return lastSegment?.includes('-') ? lastSegment : null;
};

export const delay = (ms = 1000): Promise<void> => new Promise(res => setTimeout(res, ms));

export const timer = (ms: number, abortController: AbortSignal, callback: (time: number) => void): void => {
    const start = document.timeline?.currentTime || performance.now();

    const scheduleFrame = (time: number, nextTick: number) => {
        const frame = (newTime: number) => {
            if (abortController.aborted) return;
            callback(nextTick);
            scheduleFrame(newTime, nextTick + 1);
        };
        
        const elapsed = time - start;
        const roundedElapsed = Math.round(elapsed / ms) * ms;
        const targetNext = start + roundedElapsed + ms;
        const newDelay = targetNext - performance.now();
        setTimeout(() => requestAnimationFrame(frame), newDelay);
    };
  
    scheduleFrame(start, 0);
};

export const delayedAsync = <T, U>(fn: (item: U) => Promise<T>, data: U[], time: number): Promise<T[]> => {
    return Promise.all(data.map(async (u, i) => {
        await delay(time * (1 + i));
        return fn(u);
    }));
};

export const formatHourTime = (hourString: string): string => {
    const time = hourString.includes('T') ? getTimeObjectFromDateTimeString(hourString) : getTimeObjectFromHourString(hourString);

    const numberFormatter = (n: number) => n.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
    });

    return `${numberFormatter(time.hour)}:${numberFormatter(time.minutes)}`;
};

export const getTimeObjectFromHourString = (hourString: string): Time => {
    const parts: Array<string> = hourString.split(':');

    return {
        hour: parts[0] ? Number(parts[0]) : 0,
        minutes: parts[1] ? Number(parts[1]) : 0,
    };
};

export const getTimeObjectFromDateTimeString = (dateTime: string): Time => {
    const dateTimeSplits = dateTime.split('T');

    if (dateTimeSplits[1]) {
        return getTimeObjectFromHourString(dateTimeSplits[1]);
    }
    return {
        hour: 0,
        minutes: 0,
    };
};