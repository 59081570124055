/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../settings';
import { googleMap } from '../../utils/maps';

interface OwnProps {
    zoom: number;
    center: google.maps.LatLngLiteral;
    height: string;
    onMapMounted: Function;
    onClick?: (evt: any) => void;
}

interface OwnState {
    mapIsReady: boolean;
}

const initialState: OwnState = {
    mapIsReady: false,
};

export default class Map extends Component<OwnProps, OwnState> {
    map: google.maps.Map | null = null;
    id: string = '';

    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.id = Math.random().toString().substr(2, 8);
    }

    componentDidMount() {
        if (window.google === undefined) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=drawing,places&v=3.exp`;
            script.async = true;
            script.defer = true;
            script.addEventListener('load', () => {
                this.setState({ mapIsReady: true });
            });
            document.body.appendChild(script);
        } else {
            this.componentDidUpdate();
        }
    }

    componentDidUpdate() {
        const { onMapMounted, center, zoom, onClick } = this.props;
        if (this.map === null && window.google !== undefined) {
            this.map = googleMap(center, zoom, this.id);
            if (onClick !== undefined) {
                this.map.addListener('click', onClick);
            }
            onMapMounted(this.map);
        }
    }

    render() {
        const { height } = this.props;
        return <div id={this.id} style={{ height: height, width: '100%', flexShrink: 0 }} />;
    }
}
