/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect } from 'react';
import {
    Route, Switch, Redirect, useHistory, useLocation
} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import WelcomeScreen from './screens/WelcomeScreen';
import LandingScreen from './screens/LandingScreen';
import RecoverScreen from './screens/RecoverScreen';
import MapScreen from './screens/MapScreen';
import ResetScreen from './screens/ResetScreen';
import BookingsScreen from './screens/BookingsScreen';
import QrCodeScreen from './screens/QrCodeScreen';
import ConfirmRegistrationScreen from './screens/ConfirmRegistrationScreen';
import { AppRoute } from '../types/routes';
import AuthenticatedRoute from './containers/AuthenticatedRoute';
import Drawer from './views/Drawer';
import NavBar from './views/NavBar';
import BottomBar from './views/BottomBar';
import { AuthenticationContext, withAuthenticationContext } from './controllers/AuthenticationContext';
import { buildRoute, qrCodeIdFromPath } from '../utils/misc';
import { BookingsContext, withBookingsContext } from './controllers/BookingsContext';
import AcceptInviteScreen from './screens/AcceptInviteScreen';
import { InvitationsContext, withInvitationsContext } from './controllers/InvitationsContext';
import ReactGA from 'react-ga4';
import { GOOGLE_ANAlYTICS_TRACKING_ID } from '../settings';

type Props = AuthenticationContext & BookingsContext & InvitationsContext;

const AppRouter: FunctionComponent<Props> = (props: Props) => {
    const { token, getBollardByQrCodeId, setQrCodeForCheckIn } = props;

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(history.location.search);

    let defaultRoute: string | undefined;

    // check if fastMP id is present
    const fastMPStickerId = searchParams.get('i');
    if (fastMPStickerId) {
        defaultRoute = buildRoute(AppRoute.Landing, {
            qrCodeId: qrCodeIdFromPath(history.location.pathname),
            fastMPStickerId,
        });
    }

    // check if path contains a qrCodeId to redirect to reservation modal from that business on a specif area
    const qrCodeId = qrCodeIdFromPath(history.location.pathname);
    if (qrCodeId) {
        getBollardByQrCodeId(qrCodeId).then(bollard => {
            if (!bollard) return;
            setQrCodeForCheckIn({ qrCodeId, areaId: bollard.businessAreaId, bollardId: bollard.id });
        });
    }

    useEffect(() => {
        if (token) {
            switch (history.location.pathname) {
                case AppRoute.Welcome:
                case AppRoute.Recover:
                case AppRoute.Reset:
                    history.push(AppRoute.Map);
                    break;
                default:
                    break;
            }
        }
    }, [token, history]);

    useEffect(() => {
        ReactGA.initialize(String(GOOGLE_ANAlYTICS_TRACKING_ID));
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname, title: window.location.href + location.search });
    }, [location]);

    return (
        <div className="app-router">
            <Drawer />
            <ReactNotification />
            <div className="app-container">
                <NavBar />
                <Switch>
                    <Route exact path={AppRoute.Welcome} component={WelcomeScreen} />
                    <Route exact path={AppRoute.Landing} component={LandingScreen} />
                    <Route exact path={AppRoute.Recover} component={RecoverScreen} />
                    <Route exact path={AppRoute.Reset} component={ResetScreen} />
                    <Route exact path={AppRoute.AcceptInvite} component={AcceptInviteScreen} />
                    <Route
                        exact
                        path={[AppRoute.ConfirmAccount, AppRoute.ConfirmEmail]}
                        component={ConfirmRegistrationScreen}
                    />
                    <Route exact path={AppRoute.Map} component={MapScreen} />
                    <Switch>
                        <AuthenticatedRoute exact path={AppRoute.Reserve} component={MapScreen} />
                        <AuthenticatedRoute exact path={AppRoute.Bookings} component={BookingsScreen} />
                        <AuthenticatedRoute exact path={AppRoute.BookingDetails} component={BookingsScreen} />
                        <AuthenticatedRoute exact path={AppRoute.QrCode} component={QrCodeScreen} />
                        <Redirect to={defaultRoute || AppRoute.Map} />
                    </Switch>
                    <Redirect to={defaultRoute || AppRoute.Welcome} />
                </Switch>
                <BottomBar />
            </div>
        </div>
    );
};

export default withBookingsContext(withAuthenticationContext(withInvitationsContext(AppRouter)));
