/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import logo from '../../assets/images/logo-green.svg';
import { AppRoute } from '../../types/routes';

interface OwnProps extends AuthenticationContext, TranslationContext, RouteComponentProps {}

interface OwnState {}

const initialState: OwnState = {};

class NavBar extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const { t, isAuthenticated, history } = this.props;
        return (
            <>
                {isAuthenticated ? (
                    <div className="navbar">
                        {history.location.pathname === AppRoute.Bookings ? (
                            <span>{t('navbar.bookings')}</span>
                        ) : history.location.pathname === AppRoute.QrCode ? (
                            <span>{t('navbar.checkin')}</span>
                        ) : (
                            <img src={logo} alt="Boser" data-testid="nav-logo" />
                        )}
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(NavBar)));
