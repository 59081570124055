/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import moment from 'moment';
import { Area, Bollard } from './areas';
import { GeoPoint, PaymentMethods, QueryParams } from './general';
import { User } from './users';

export interface Booking {
    id: number;
    status: BookingStatus;
    createdDate: Date;
    lastModifiedDate: Date;
    bollardId: number;
    bollardDesignation: string;
    businessArea: Area;
    customer: User;
    day: string;
    endTime: string;
    location: GeoPoint;
    merchant: User;
    startTime: string;
    startDateTime: Date;
    price: number;
    atmEntity: string;
    reference: string;
    mbWayAlias: string | null;
    multipleReservationId: string | null;
    observations: string | null;
}

export interface BookingParams extends QueryParams {
    _q: string;
    businessId: number | undefined;
    businessAreaId: number | undefined;
    day: moment.Moment | null;
    status?: BookingStatus;
}

export enum BookingStatus {
    All = '',
    CheckedIn = 'CHECKED_IN',
    CheckedOut = 'CHECKED_OUT',
    Canceled = 'CANCELED',
    Reserved = 'RESERVED',
    CanceledByMerchant = 'CANCELED_BY_MERCHANT',
    CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
    ATMReferenceCreated = 'ATM_REFERENCE_CREATED',
    MBWayRequestCreated = 'MB_WAY_REQUEST_CREATED',
    CreditCardPaymentCreated = 'CREDIT_CARD_PAYMENT_CREATED',
    MerchantReservationConcluded = 'MERCHANT_RESERVATION_CONCLUDED',
    Absence = 'ABSENCE',
    NotPaid = 'NOT_PAID',
}

export interface ScheduleEntry {
    startTime: string;
    endTime: string;
    reserved: boolean;
    reservationPeriodId: string;
    price: string | null;
}

export interface ConvertedEntry {
    flex: number;
    entry: ScheduleEntry | null;
    bollard: Bollard;
    tooltip: string;
}

export interface BollardSchedule {
    bollard: Bollard;
    scheduleEntries: ScheduleEntry[];
}

export interface DaySchedulesResponsePayload {
    day: string;
    schedules: BollardSchedule[];
}

export interface BookingRequestPayload {
    areaId: string;
    bollardId: number;
    reservationPeriodId: React.Key;
    days: string[];
    numberOfPeople: string;
    cardNumber: string | null;
    ccv: string | null;
    validity: string | null;
    clientPhoneNumber: string | null;
    paymentType: PaymentMethods;
    observations: string;
}
