/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const mapCenter = { lat: 38.736946, lng: -9.142685 };
export const disableOrders = true;

export const SHOW_PAYMENT_NIF = false;
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'yyyy-MM-DD';


export const BUSINESS_REGISTER_ROUTE = 'register';
