/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { InvitationsContextProvider } from './InvitationsContext';
import { acceptInviteURL, validateInviteTokenURL } from '../../services/invitations';
import { InvitationUserStatus } from '../../types/invitations';
import { AppState } from '../../reducers/types';
import { setInviteTokenActionCreator } from '../../actions/invitations';

interface OwnProps {
    children: React.ReactNode;
}

interface StateProps {
    inviteToken: string | null;
}

interface DispatchProps {
    dispatchSetInviteToken(token: string | null): void;
}

type Props = DispatchProps & OwnProps & StateProps;

export class InvitationsController extends Component<Props> {
    validateInviteToken = async (token: string, onSuccess: (invitationStatus: InvitationUserStatus) => void, onFailure: (errorCode: number) => void): Promise<void> => {
        try {
            const { data } = await axios.get(validateInviteTokenURL(token));
            onSuccess(data);
        } catch (e) {
            let errorCode = 0;
            if (axios.isAxiosError(e) && e.response?.data && e.response?.data.errors && e.response?.data.errors[0].errorCode) {
                ({ errorCode } = e.response?.data.errors[0]);
            }
            onFailure(errorCode);
        }
    };

    acceptInvite = async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
        const { inviteToken } = this.props;

        if (!inviteToken) return;

        try {
            await axios.put(acceptInviteURL(inviteToken));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    setInviteToken = (token: string | null): void => {
        const { dispatchSetInviteToken } = this.props;

        dispatchSetInviteToken(token);
    }

    render() {
        const {
            children,
            inviteToken,
        } = this.props;

        return (
            <InvitationsContextProvider
                value={{
                    inviteToken,
                    validateInviteToken: this.validateInviteToken,
                    acceptInvite: this.acceptInvite,
                    setInviteToken: this.setInviteToken,
                }}
            >
                {children}
            </InvitationsContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        inviteToken: state.invitations.inviteToken,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    dispatchSetInviteToken: (token: string | null) => dispatch(setInviteTokenActionCreator(token)),
});

export const ConnectedInvitationsController = connect(mapStateToProps, mapDispatchToProps)(InvitationsController);
