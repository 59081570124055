/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { API_URL } from '../settings';
import { Area } from '../types/areas';
import { Business } from '../types/businesses';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'businesses';

export const getBusinesses = async (filters?: KeyedObject): Promise<{businesses: Business[], total: number} | null> => {
    try {
        const { data, headers } = await axios.get(businessesURL(filters));
        return { businesses: data, total: headers['x-total-count'] };
    } catch {
        return null;
    }
};

export const getBusiness = async (id: string): Promise<Business | null> => {
    try {
        const { data } = await axios.get(businessURL(id));
        return data;
    } catch {
        return null;
    }
};

export const getArea = async (areaId: string | number): Promise<Area | null> => {
    try {
        const { data } = await axios.get(areaURL(areaId));
        return data;
    } catch {
        return null;
    }
};

export const getAreas = async (filters?: KeyedObject) => {
    try {
        const { data, headers } = await axios.get(areasURL(filters));
        return { areas: data, total: headers['x-total-count'] };
    } catch {
        return null;
    }
};

export const businessesURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const businessURL = (id: string): string => {
    return `${API_URL}/${api}/${id}`;
};

export const areasURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/areas${params}`;
};

export const areaURL = (areaId: string | number): string => {
    return `${API_URL}/${api}/areas/${areaId}`;
};

export const scheduleURL = (businessId: string, areaId: string, day: string): string => {
    return `${API_URL}/${api}/${businessId}/areas/${areaId}/schedule/${day}`;
};
