/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FC } from 'react';

import { Dialog } from '@material-ui/core';
import { Booking } from '../../types/bookings';
import BookingListEntry from './BookingListEntry';

interface OwnProps {
    open: boolean;
    bookings: Booking[];
    fetchBookings: () => void;
    cancelBooking: (booking: Booking) => void;
    openBookingDetails: (booking: Booking) => void;
    close: () => void;
}

type Props = OwnProps;

export const GroupReservationsModal: FC<OwnProps> = (props: Props) => {
    const {
        bookings, fetchBookings, open, openBookingDetails, close, cancelBooking,
    } = props;

    return (
        <Dialog open={open} className="reservation-group" onClose={close}>
            {
                bookings.map(booking => (
                    <button
                        type="button"
                        key={booking.id}
                        className="reservation-group__booking-entry"
                        onClick={() => openBookingDetails(booking)}
                    >
                        <BookingListEntry
                            booking={booking}
                            fetchBookings={fetchBookings}
                            squareIcons
                            compactView
                            cancelBookingCallback={() => cancelBooking(booking)}
                        />
                    </button>
                ))
            }
        </Dialog>
    );
};
