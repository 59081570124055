/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedInvitationsController } from './InvitationsController';
import { InvitationUserStatus } from '../../types/invitations';

export interface InvitationsContext {
    inviteToken: string | null;
    validateInviteToken(token: string, onSuccess: (invitationStatus: InvitationUserStatus) => void, onFailure: (errorCode: number) => void): Promise<void>;
    acceptInvite(onSuccess: () => void, onFailure: () => void): Promise<void>;
    setInviteToken(token: string | null): void;
}

export const invitationsContextDefaultValue: InvitationsContext = {
    inviteToken: null,
    validateInviteToken: async () => {},
    acceptInvite: async () => {},
    setInviteToken: () => {},
};

const InvitationsContext = createContext<InvitationsContext | null>(invitationsContextDefaultValue);

export const InvitationsContextProvider = InvitationsContext.Provider;
export const InvitationsContextConsumer = InvitationsContext.Consumer;

export const withInvitationsContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof InvitationsContext>> => props => (
    <ConnectedInvitationsController>
        <InvitationsContextConsumer>
            {ctx => <Component {...(props as P)} {...ctx} />}
        </InvitationsContextConsumer>
    </ConnectedInvitationsController>
);
